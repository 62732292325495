import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils, JsonHttpHeaders } from '../util/http-util';
import { CsvResponse } from '../model/project/Responses';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CsvService {

  constructor(private http: HttpClient,
              private translate : TranslateService) {}

  public getCsv(projectId: string): Observable<CsvResponse> {
    const options: JsonHttpHeaders = HttpUtils.getJsonContentTypeHeader();
    let params: HttpParams = new HttpParams();

    params = params.set('lang', this.translate.currentLang);
    options['params'] = params;

    return this.http.get<CsvResponse>(`${environment.api_url}/v1/web/project/report/${projectId}`, options);
  }
}
