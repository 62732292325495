import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Project, ProjectStats } from '../../../model/project/project';
import { ProjectDataService } from '../../../services/project-data.service';
import { ProjectService } from '../../../services/project.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { ProjectStatsResponse } from '../../../model/project/Responses';
import { TimeUtils } from '../../../util/time-util';
import { DefaultBox } from 'src/app/model/project/default-box';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
})
export class ViewReportComponent implements OnInit {

  protected project: Project;
  public currentProject: Project;

  public projectStats: ProjectStats;
  public projectStatsByProjectIdHttpSubscriber: Subscription;
  public isProjectStats: boolean = true;

  public totalScoreData: DefaultBox;
  public interviewLengthData: DefaultBox;
  public qualityData: DefaultBox;
  public quotaData: DefaultBox;
  public securityData: DefaultBox;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              private projectDataService: ProjectDataService,
              private projectService: ProjectService,
              private fileUploadService: FileUploadService,
  ) {
    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }

    this.projectStatsByProjectIdHttpSubscriber =
      this.projectService.getProjectStatsByProjectId(this.project._id).subscribe((statistics: ProjectStatsResponse) => {
        if (statistics.payload
          && statistics.payload.statistics
          && Object.keys(statistics.payload.statistics).length) {
          return this.translate.get(['PROJECT_VIEW_REPORT.BOX']).subscribe((translations) => {
            this.setReportData(statistics.payload.statistics, translations['PROJECT_VIEW_REPORT.BOX']);
          });
        }

        this.isProjectStats = false;
      });
  }

  ngOnInit() {
    this.currentProject = { ...this.project };
    this.getLogoImageUrl(this.currentProject.logoKey);
  }

  ngOnDestroy() {
    this.projectDataService.resetProjectData();

    if (this.projectStatsByProjectIdHttpSubscriber) {
      this.projectStatsByProjectIdHttpSubscriber.unsubscribe();
    }
  }

  private setReportData(statistics: ProjectStats, TRANSLATIONS: any) {
    this.projectStats = statistics;

    if (statistics.hasOwnProperty('averageInterviewLength')) {
      const time = TimeUtils.convertMiliseconds(statistics.averageInterviewLength);
      let currentAbrLabelKey = TRANSLATIONS.MIN;
      let currentTimeValue = time.minutes ? time.minutes : `${(time.seconds < 10 ? '0' : '')}${time.seconds}`;

      if (!time.minutes && time.seconds) {
        currentAbrLabelKey = TRANSLATIONS.SEC;
      }

      if (!time.minutes && !time.seconds || time.minutes < 0 || time.seconds < 0) {
        currentTimeValue = TRANSLATIONS.NA;
      }

      this.interviewLengthData = {
        title: TRANSLATIONS.INTERVIEW_LENGTH.TITLE,
        iconName: 'query_builder',
        list: [
          {
            title: TRANSLATIONS.SET,
            value: statistics.surveyDuration,
            abvr: TRANSLATIONS.MIN,
          },
          {
            title: TRANSLATIONS.CURRENT,
            value: currentTimeValue,
            abvr: currentAbrLabelKey,
          },
        ],
      };
    }

    if (statistics.hasOwnProperty('qualityFails')) {
      this.qualityData = {
        title: TRANSLATIONS.QUALITY.TITLE,
        iconName: 'wb_sunny',
        list: [
          {
            title: TRANSLATIONS.ATTEMPTS,
            value: statistics.total,
            abvr: TRANSLATIONS.PPL,
          },
          {
            title: TRANSLATIONS.FAILS,
            value: statistics.qualityFails,
            abvr: TRANSLATIONS.PPL,
          },
        ],
      };
    }

    if (statistics.hasOwnProperty('quotaFails')) {
      this.quotaData = {
        title: TRANSLATIONS.OVER_QUOTA.TITLE,
        iconName: 'pie_chart',
        list: [
          {
            title: TRANSLATIONS.ATTEMPTS,
            value: statistics.total,
            abvr: TRANSLATIONS.PPL,
          },
          {
            title: TRANSLATIONS.FAILS,
            value: statistics.quotaFails,
            abvr: TRANSLATIONS.PPL,
          },
        ],
      };
    }

    if (statistics.hasOwnProperty('securityFails')) {
      this.securityData = {
        title: TRANSLATIONS.SECURITY.TITLE,
        iconName: 'security',
        list: [
          {
            title: TRANSLATIONS.ATTEMPTS,
            value: statistics.total,
            abvr: TRANSLATIONS.PPL,
          },
          {
            title: TRANSLATIONS.FAILS,
            value: statistics.securityFails,
            abvr: TRANSLATIONS.PPL,
          },
        ],
      };
    }
  }

  private getLogoImageUrl(logoKey) {
    const logoUrl: string|null = this.projectService.getCurrentProjectLogoUrl(logoKey);

    if (logoKey !== '' && !logoUrl) {
      return this.fileUploadService.getImageUrl(logoKey).subscribe(
        (res) => {
          this.currentProject.logoKey = res.payload.url;
        },
      );
    }
  }
}
