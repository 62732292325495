/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-warning-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./status-warning-dialog.component";
import * as i12 from "../../../services/project.service";
var styles_StatusWarningDialogComponent = [i0.styles];
var RenderType_StatusWarningDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusWarningDialogComponent, data: {} });
export { RenderType_StatusWarningDialogComponent as RenderType_StatusWarningDialogComponent };
function View_StatusWarningDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("STATUS_WARNING_DIALOG.DESCRIPTION_OPTION")); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusWarningDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("STATUS_WARNING_DIALOG.DESCRIPTION_OPTIONS")); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusWarningDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_StatusWarningDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "dialog-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "mat-icon", [["class", "close-dialog mat-icon notranslate"], ["mat-dialog-close", ""], ["role", "img"], ["type", "button"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), i1.ɵdid(7, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(9, 0, null, null, 9, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusWarningDialogComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusWarningDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "languages-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusWarningDialogComponent_3)), i1.ɵdid(18, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 6, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(20, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 4, "button", [["class", "button dark-blue mt-10 width-150"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dialogRef.close(i1.ɵnov(_v, 23).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(23, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(24, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 6, 0); var currVal_5 = ""; _ck(_v, 7, 0, currVal_5); var currVal_6 = (_co.languagesLabel.length === 1); _ck(_v, 12, 0, currVal_6); var currVal_7 = (_co.languagesLabel.length >= 2); _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.languagesLabel; _ck(_v, 18, 0, currVal_8); var currVal_12 = true; _ck(_v, 23, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("STATUS_WARNING_DIALOG.HEADING")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).inline; var currVal_3 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); var currVal_4 = (i1.ɵnov(_v, 7).ariaLabel || null); _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4); var currVal_9 = (i1.ɵnov(_v, 22).disabled || null); var currVal_10 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); var currVal_11 = (i1.ɵnov(_v, 23).ariaLabel || null); _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11); var currVal_13 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("STATUS_WARNING_DIALOG.CLOSE_BUTTON")); _ck(_v, 24, 0, currVal_13); }); }
export function View_StatusWarningDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-warning-dialog", [], null, null, null, View_StatusWarningDialogComponent_0, RenderType_StatusWarningDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.StatusWarningDialogComponent, [i12.ProjectService, i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusWarningDialogComponentNgFactory = i1.ɵccf("app-status-warning-dialog", i11.StatusWarningDialogComponent, View_StatusWarningDialogComponent_Host_0, {}, {}, []);
export { StatusWarningDialogComponentNgFactory as StatusWarningDialogComponentNgFactory };
