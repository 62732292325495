import { SupplierSources } from './enums';

export enum RouteId {
  NO_ID,
  DASHBOARD,
  PROJECTS,
  BILLING,
  PROFILE,
  NOTIFICATIONS,
}

export interface GenericError {
  code: number;
  message: string;
}

export interface GenericResponse {
  status: number;
  payload?: any;
  errors?: GenericError[];
}

export interface ProjectManager {
  fullName: string;
}

export interface Supplier {
  _id?: string;
  // name is the 'name' from a supplier in the suppliers table
  name: string;
  // configured when the user fills out the form
  source: SupplierSources;
  // supplierId is the '_id' from a supplier in the suppliers table
  supplierId: string;
}

export interface Languages {
  _id?: string;
  label?: string;
  langCode: string;
  surveyUrl: string;
  finalUrl: string;
  completeUrl: string;
}

export interface SurveyStatus {
  name: string;
  code: number;
  link: boolean;
}
