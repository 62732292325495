import { HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FileUploadService = /** @class */ (function () {
    function FileUploadService(http) {
        this.http = http;
        this.baseUrl = environment.api_url + "/v1/web";
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-auth-token': "" + localStorage.getItem('accessToken'),
            }),
        };
    }
    // Get presinged url from the backend before uploadin an image
    FileUploadService.prototype.getPresignedUrls = function (fileName, fileContentType) {
        this.httpOptions['params'] = new HttpParams({
            fromString: "file_name=" + fileName + "&content_type=" + fileContentType,
        });
        return this.http.get(this.baseUrl + "/upload/presigned_url", this.httpOptions);
    };
    FileUploadService.prototype.uploadFileToServer = function (fileuploadurl, uploadContentType, file) {
        var httpHeader = {
            headers: new HttpHeaders({
                'Content-Type': uploadContentType,
            }),
        };
        var req = new HttpRequest('PUT', fileuploadurl, file, httpHeader);
        return this.http.request(req);
    };
    // Get file/image signed url after uploading the file
    FileUploadService.prototype.getImageUrl = function (key) {
        this.httpOptions['params'] = new HttpParams({
            fromString: "key=" + key,
        });
        return this.http.get(this.baseUrl + "/upload/signed_image_path", this.httpOptions);
    };
    FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.ɵɵinject(i1.HttpClient)); }, token: FileUploadService, providedIn: "root" });
    return FileUploadService;
}());
export { FileUploadService };
