<div class="form-group" [formGroup]="parentForm" >
  <mat-form-field class="width-100">
    <mat-pass-toggle-visibility #togglePassword matSuffix></mat-pass-toggle-visibility>
    <input matInput [type]="togglePassword.type" formControlName="password" (change)="onPasswordChanged()"
           placeholder="{{ 'PASSWORD_CONFIRM_WIDGET.LABELS.PASSWORD' | translate }}" autocomplete="new-password">
    <mat-error *ngIf="parentForm && parentForm.get('password').errors">
      {{ getPasswordErrorString() }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="width-100">
    <mat-pass-toggle-visibility #toggleConfirm matSuffix></mat-pass-toggle-visibility>
    <input matInput [type]="toggleConfirm.type" formControlName="confirmPassword"
            placeholder="{{ 'PASSWORD_CONFIRM_WIDGET.LABELS.CONFIRM_PASSWORD' | translate }}" autocomplete="new-password">
    <mat-error *ngIf="parentForm && parentForm.get('confirmPassword').errors?.required">
      {{ 'PASSWORD_CONFIRM_WIDGET.MESSAGES.CONFIRM_PASSWORD_REQUIRED' | translate }}
    </mat-error>
    <mat-error class="check-check" *ngIf="parentForm && parentForm.get('confirmPassword').errors?.passwordsDoNotMatch">
      {{ 'PASSWORD_CONFIRM_WIDGET.MESSAGES.PASSWORDS_DO_NOT_MATCH' | translate }}
    </mat-error>
  </mat-form-field>
</div>
