<div class="forgot-password-wrapper mt-100" fxLayout="row" fxLayoutAlign="space-evenly">
  <img fxHide.sm fxHide.xs fxFlex="45" src="assets/img/authentication.png" alt="authentication-image" class="mt-50 height-500">

  <mat-card  class="box auth-form-card" fxFlex="30" fxFlex.sm="70" fxFlex.xs="90">
    <mat-card-header>
      <mat-card-title>{{ 'FORGOT_PASSWORD.TITLE' | translate }}</mat-card-title>
    </mat-card-header>

    <form class="form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <mat-card-content>{{ 'FORGOT_PASSWORD.DESCRIPTION' | translate }}
        <mat-form-field class="width-100">
          <input matInput formControlName="email" placeholder="{{ 'FORGOT_PASSWORD.LABELS.EMAIL' | translate }}" autocomplete="username">
          <mat-error *ngIf="forgotPasswordForm.get('email').errors?.required">
            {{ 'FORGOT_PASSWORD.MESSAGES.EMAIL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').errors?.email">
            {{ 'FORGOT_PASSWORD.MESSAGES.EMAIL_INVALID' | translate }}
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit" [disabled]="!this.forgotPasswordForm.valid">{{ 'FORGOT_PASSWORD.LABELS.SUBMIT_BUTTON' | translate }}</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
