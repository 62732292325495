import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirtyFormService {
  receivedFilter: EventEmitter<boolean>;

  constructor() {
    this.receivedFilter = new EventEmitter<boolean>();
  }

  isDirty(): void {
    this.receivedFilter.emit(true);
  }

  isValid(): void {
    this.receivedFilter.emit(false);
  }

  reset(): void {
    this.receivedFilter.emit(false);
  }
}
