import * as tslib_1 from "tslib";
/**
 * Preloader
 *
 * @param {Subscriber | Boolean} loader
 * A subscriber on which to bind the loader. Any time a new Subscriber is added the preloader will
 * keep track of the notification (error, complete) that an observable send and control the loading state.
 * Or pass a boolean on which to bind the loader to changed the visibility.
 * @param {Boolean} isFullScreen set to "true" if the preloader should fullscreen overlay the page.
 *
 * @example
 * <pre>
 *  // Example
 *  <app-preloader [loader]="(Subscriber<any>)">the content</app-preloader>
 *  <app-preloader [loader]="!(Observable[boolean] | async)">
 *  <app-preloader [loader]="!(Observable[object] | async)?.length">
 *  <app-preloader [loader]="!(Observable[string] | async)?.length">
 *  <app-preloader [loader]="(Boolean)">
 *
 *  // Use this example when you have at least one more preloader component inside your fullscreen preloader component.
 *  // You need to add this data attribute only on the fullscreen preloader component.
 *  <app-preloader [loader]="(Subscriber<any>)" [isFullScreen]="true">the content</app-preloader>
 * </pre>
 */
import { ElementRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscriber } from 'rxjs';
import { isFunction } from 'util';
var PreloaderComponent = /** @class */ (function () {
    function PreloaderComponent(elRef, eventManager) {
        var _this = this;
        this.elRef = elRef;
        this.eventManager = eventManager;
        this.loadingStackCount = 0;
        this.hidePreloaderClass = 'preload-hide';
        this.hideSpinnerClass = 'spinner-hide';
        this.setIsLoading = function (isLoading) {
            _this.loadingStackCount += isLoading ? 1 : -1;
            if (_this.loadingStackCount > 0) {
                _this.elRef.nativeElement.classList.remove(_this.hidePreloaderClass);
            }
            else {
                _this.elRef.nativeElement.classList.add(_this.hidePreloaderClass);
            }
            // If this loader is fullscreen and showing spinner, notify the other loader to start showing
            if (!_this.getIsLoading()) {
                if (_this.isFullScreen && !_this.elRef.nativeElement.classList.contains(_this.hideSpinnerClass)) {
                    _this.eventManager.broadcast('preloaderFullScreenDone');
                }
                _this.elRef.nativeElement.classList.remove(_this.hideSpinnerClass);
            }
        };
        this.getIsLoading = function () { return _this.loadingStackCount > 0; };
        this.showSpinner = function () {
            _this.elRef.nativeElement.classList.remove(_this.hideSpinnerClass);
        };
        this.hideSpinnerIfFullScreenPresent = function () {
            var e_1, _a;
            var fullscreenPreloaderEl = document.querySelectorAll('app-preloader.full-screen');
            try {
                for (var fullscreenPreloaderEl_1 = tslib_1.__values(fullscreenPreloaderEl), fullscreenPreloaderEl_1_1 = fullscreenPreloaderEl_1.next(); !fullscreenPreloaderEl_1_1.done; fullscreenPreloaderEl_1_1 = fullscreenPreloaderEl_1.next()) {
                    var preloaderEl = fullscreenPreloaderEl_1_1.value;
                    // If a fullscreen preloader is still shown, hide the spinner of this element
                    if (!_this.isFullScreen && !preloaderEl.classList.contains(_this.hidePreloaderClass)) {
                        _this.elRef.nativeElement.classList.add(_this.hideSpinnerClass);
                        _this.eventManager.on('preloaderFullScreenDone', _this.showSpinner);
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (fullscreenPreloaderEl_1_1 && !fullscreenPreloaderEl_1_1.done && (_a = fullscreenPreloaderEl_1.return)) _a.call(fullscreenPreloaderEl_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        };
        this.hideSpinner = function () {
            _this.setIsLoading(false);
        };
        this.elRef.nativeElement.classList.add(this.hidePreloaderClass);
    }
    PreloaderComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.loader &&
            ((changes.loader.currentValue && changes.loader.currentValue instanceof Subscriber) ||
                typeof changes.loader.currentValue === 'boolean')) {
            if (changes.loader.currentValue instanceof Subscriber) {
                this.setIsLoading(true);
                changes.loader.currentValue.complete = function () {
                    var completeParentCallback = changes.loader.currentValue.destination._complete;
                    _this.hideSpinner();
                    if (completeParentCallback && isFunction(completeParentCallback)) {
                        completeParentCallback();
                    }
                };
                changes.loader.currentValue.error = function (err) {
                    var errorParentCallback = changes.loader.currentValue.destination._error;
                    _this.hideSpinner();
                    if (errorParentCallback && isFunction(errorParentCallback)) {
                        errorParentCallback(err);
                    }
                };
            }
            if (typeof changes.loader.currentValue === 'boolean') {
                if (changes.loader.currentValue) {
                    this.setIsLoading(true);
                }
                else {
                    this.hideSpinner();
                }
            }
        }
        this.hideSpinnerIfFullScreenPresent();
        if (this.isFullScreen) {
            this.elRef.nativeElement.classList.add('full-screen');
        }
        else {
            this.elRef.nativeElement.classList.remove('full-screen');
        }
    };
    PreloaderComponent.prototype.ngOnDestroy = function () {
        this.eventManager.off('preloaderFullScreenDone', this.showSpinner);
    };
    return PreloaderComponent;
}());
export { PreloaderComponent };
