import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  @ViewChild('fileInput', { static: false }) fileInput: any;
  @Input() parentForm: FormGroup;
  @Input() imageFormKey: string;
  @Input() imageSrc: string;
  @Input() translationKey: string;
  @Output() uploadSuccessCb: EventEmitter<string> = new EventEmitter();
  @Output() uploadErrorCb: EventEmitter<object> = new EventEmitter();
  @Output() removeSuccessCb: EventEmitter<void> = new EventEmitter();

  constructor(private fileUploadService: FileUploadService,
              private snackBarService: SnackBarService) {
  }

  onFileSelected(fileList) {
    const file = fileList[0];
    this.fileUploadService.getPresignedUrls(file.name, file.type).subscribe(
      (result) => {
        this.uploadToServer(result.payload, file);
      },
      (error) => {
        this.handleError(error);
      });
  }

  private uploadToServer(payload, file) {
    this.fileUploadService.uploadFileToServer(payload.url, file.type, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          this.getImageUrl(payload.key);
          // Make form dirty when user uploads an image
          this.parentForm.markAsDirty();
        }
      },
      (error) => {
        this.handleError(error);
      });
  }

  private getImageUrl(key) {
    this.fileUploadService.getImageUrl(key).subscribe(
      (res) => {
        this.imageSrc = res.payload.url;
        this.parentForm.controls[this.imageFormKey].setValue(key);
        if (this.uploadSuccessCb) {
          this.uploadSuccessCb.emit(this.imageSrc);
        }
      },
      (error) => {
        this.handleError(error);
      },
    );
  }

  removeImage() {
    this.imageSrc = '';
    this.parentForm.controls[this.imageFormKey].setValue('');
    this.fileInput.nativeElement.value = null;

    // Make form dirty when user deletes an image
    this.parentForm.markAsDirty();

    if (this.removeSuccessCb) {
      this.removeSuccessCb.emit();
    }
  }

  private handleError(error: any) {
    this.snackBarService.errorWithClose(`${this.translationKey}.REQUEST_FAILED`);
    if (this.uploadErrorCb) {
      this.uploadErrorCb.emit(error);
    }
  }
}
