<div [formGroup]="parentForm" class="mat-input-select">
  <ng-select [items]="items"
             #itemsSelect
             required
             formControlName="{{formControlItemName}}"
             placeholder="{{ this.translationParentKey + '.LABELS.SELECT_PLACEHOLDER' | translate }} *"
             [ngClass]="{'required-field': parentForm.get(formControlItemName).errors?.required && formSubmitted }"
             [searchFn]="searchItemsFn">
    <ng-template ng-option-tmp let-item="item">
      {{item}}
    </ng-template>
    <ng-template ng-footer-tmp>
      <button mat-flat-button type="button" (click)="itemsSelect.close();openAddItemDialog()" class="btn btn-sm btn-secondary">{{ this.translationParentKey + '.LABELS.ADD_ITEM' | translate }}</button>
    </ng-template>
  </ng-select>

  <mat-error class="custom-error" *ngIf="parentForm.get(formControlItemName).errors?.required">
    <span *ngIf="formSubmitted || parentForm.get(formControlItemName).touched">{{ this.translationParentKey + '.ERRORS.REQUIRED' | translate }}</span>
  </mat-error>
</div>