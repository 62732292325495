import { SecurityOption } from './options';
import { Supplier, Languages } from '../Common';

export interface ProjectStats {
  projectId: string;
  total: number;
  averageInterviewLength: number;
  qualityFails: number;
  quotaFails: number;
  securityFails: number;
  surveyDuration: number;
}

export interface StoreLogoData {
  key: string;
  url: string;
}

export const EDITION_MODE = {
  NEW: 'new',
  EDIT: 'edit',
};

export enum Outcome {
  Accept = 'accept',
  Reject = 'reject',
}

export enum QuestionType {
  Radio = 'radio',
}

export enum QuestionSource {
  Detect = 'detect',
  User = 'user',
}

export enum Audience {
  Consumer = 'consumer',
  Business = 'business',
}

export interface Answer {
  _id?: string;
  text: I18nText[];
  outcome: Outcome;
  quota: number;
}

export interface I18nText {
  _id?: string;
  langCode: string;
  text: string;
}

export interface RadioQuestion extends Question {
  type: QuestionType.Radio;
}

export interface Question {
  _id?: string;
  text: I18nText[];
  source: QuestionSource;
  type: QuestionType;
  answers: Answer[];
}

export interface Project {
  _id?: string;
  title: string;
  client: string;
  status: string;
  projectManager: string;
  endDate: string;
  duration: string;
  maxBudget: number;
  setRoom: number;
  type: string;
  surveyDuration: string;
  bidTarget: number;
  piiCompliance: boolean;
  languages: Languages[];
  logoKey: string;
  suppliers: Supplier[];
  securityOptions: SecurityOption[];
  questions: Question[];
  expectedIR: number;
  expectedDR: number;
  expectedOQ: number;
  audience: Audience;
  numberOfPanelistsRequired: number;
  projectIncidenceRate: number;
  expectedProjectCost: number;
  actualEndDate: string;
}

export interface ProjectUpdate {
  _id: string;
  title?: string;
  client?: string;
  status?: string;
  projectManager?: string;
  endDate?: string;
  duration?: string;
  maxBudget?: number;
  setRoom?: number;
  type?: string;
  surveyDuration?: string;
  bidTarget?: number;
  piiCompliance?: boolean;
  languages?: Languages[];
  logoKey?: string;
  suppliers?: Supplier[];
  securityOptions?: SecurityOption[];
  questions?: Question[];
  expectedIR?: number;
  expectedDR?: number;
  expectedOQ?: number;
  audience?: Audience;
  expectedProjectCost?: number;
}
