import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GenericDialogPageComponent } from './widgets/generic-dialog-page/generic-dialog-page.component';
import { EmailSentComponent } from './components/email-sent/email-sent.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { BillingComponent } from './components/billing/billing.component';
import { AnonymousGuardService } from './services/anonymous-guard.service';
import { RouteId } from './model/Common';
import { LogoutComponent } from './components/logout/logout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '404', component: PageNotFoundComponent },
  { path: 'login', component: LoginComponent, canActivate: [AnonymousGuardService] },
  {
    path: 'logout', component: LogoutComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'] },
  },
  { path: 'register', component: RegisterComponent, canActivate: [AnonymousGuardService] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AnonymousGuardService] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AnonymousGuardService] },
  { path: 'password-updated', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
    data: {
      imagePath: 'assets/img/authentication.png',
      title: 'PASSWORD_UPDATED.TITLE',
      text: 'PASSWORD_UPDATED.DESCRIPTION',
      showButton: true,
      buttonLabel: 'PASSWORD_UPDATED.LABELS.LOGIN',
      buttonRoute: '/login',
    },
  },
  { path: 'email-sent/:email', component: EmailSentComponent, canActivate: [AnonymousGuardService] },
  { path: 'confirm-account', component: ConfirmAccountComponent, canActivate: [AnonymousGuardService] },
  { path: 'account-confirmed', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
    data: {
      imagePath: 'assets/img/authentication.png',
      title: 'ACCOUNT_CONFIRMED.TITLE',
      text: 'ACCOUNT_CONFIRMED.DESCRIPTION',
      showButton: true,
      buttonLabel: 'ACCOUNT_CONFIRMED.LABELS.GET_STARTED',
      buttonRoute: '/login',
    },
  },
  { path: 'account-confirmation-sent', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
    data: {
      imagePath: 'assets/img/authentication.png',
      title: 'ACCOUNT_CONFIRMATION_SENT.TITLE',
      text: 'ACCOUNT_CONFIRMATION_SENT.DESCRIPTION',
      showButton: false,
    },
  },
  {
    path: 'profile', component: ProfileComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'], routeId: RouteId.PROFILE },
  },
  { path: 'notifications', component: NotificationsComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'], routeId: RouteId.NOTIFICATIONS },
  },
  { path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'], routeId: RouteId.DASHBOARD },
  },
  { path: 'billing', component: BillingComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'], routeId: RouteId.BILLING },
  },
  { path: '**', component: PageNotFoundComponent },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
