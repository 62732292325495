import { Routes } from '@angular/router';
import { CreateProjectResolver } from './resolvers/create-project.resolver';
import { ProjectResolver } from './resolvers/project.resolver';
import { ProjectProfileComponent } from './components/project/project-profile/project-profile.component';
import { SuppliersComponent } from './components/project/suppliers/suppliers.component';
import { SecurityComponent } from './components/project/security/security.component';
import { QualityComponent } from './components/project/quality/quality.component';
import { ProjectGuardService } from './services/project-guard.service';
import { CreateProjectComponent } from './components/project/create-project/create-project.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GenerateComponent } from './components/project/generate/generate.component';
import { ViewReportComponent } from './components/project/view-report/view-report.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { RouteId } from './model/Common';
var ɵ0 = { routeId: RouteId.PROJECTS }, ɵ1 = { routeId: RouteId.PROJECTS }, ɵ2 = { routeId: RouteId.PROJECTS }, ɵ3 = { routeId: RouteId.PROJECTS }, ɵ4 = { routeId: RouteId.PROJECTS };
var childrenProjectRoutes = [
    { path: '',
        pathMatch: 'full',
        redirectTo: 'profile',
    },
    { path: 'profile', component: ProjectProfileComponent,
        canDeactivate: [ProjectGuardService],
        data: ɵ0,
        resolve: { project: ProjectResolver },
    },
    { path: 'suppliers', component: SuppliersComponent,
        canDeactivate: [ProjectGuardService],
        data: ɵ1,
        resolve: { project: ProjectResolver },
    },
    { path: 'security', component: SecurityComponent,
        canDeactivate: [ProjectGuardService],
        data: ɵ2,
        resolve: { project: ProjectResolver },
    },
    { path: 'quality', component: QualityComponent,
        canDeactivate: [ProjectGuardService],
        data: ɵ3,
        resolve: { project: ProjectResolver },
    },
    { path: 'generate', component: GenerateComponent,
        canDeactivate: [ProjectGuardService],
        data: ɵ4,
        resolve: { project: ProjectResolver },
    },
];
var ɵ5 = { roles: ['User'], routeId: RouteId.PROJECTS, breadcrumbLabelKey: 'NAVIGATION.LABELS.PROJECTS' }, ɵ6 = { roles: ['User'], routeId: RouteId.PROJECTS, breadcrumbLabelKey: 'NAVIGATION.LABELS.PROJECTS' }, ɵ7 = { roles: ['User'], routeId: RouteId.PROJECTS }, ɵ8 = { roles: ['User'], mode: 'new' }, ɵ9 = { roles: ['User'], mode: 'edit' };
var projectRoutes = [
    {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [AuthGuardService],
        data: ɵ5,
        children: [
            {
                path: '',
                component: ProjectsListComponent,
                canActivate: [AuthGuardService],
                data: ɵ6,
            },
            {
                path: 'view-report',
                component: ViewReportComponent,
                canActivate: [AuthGuardService],
                data: ɵ7,
                resolve: { project: ProjectResolver },
            },
            {
                path: 'new',
                component: CreateProjectComponent,
                canActivate: [AuthGuardService],
                data: ɵ8,
                resolve: { project: CreateProjectResolver },
                children: childrenProjectRoutes,
            },
            {
                path: 'edit',
                component: CreateProjectComponent,
                canActivate: [AuthGuardService],
                data: ɵ9,
                resolve: { project: CreateProjectResolver },
                children: childrenProjectRoutes,
            },
        ],
    },
];
var ProjectRoutingModule = /** @class */ (function () {
    function ProjectRoutingModule() {
    }
    return ProjectRoutingModule;
}());
export { ProjectRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
