// tslint:disable-next-line:variable-name
export const ErrorUtils = {

  containsErrorCode(jsonError: any, code: number): boolean {
    if (!jsonError || !jsonError.errors) return false;
    if (!Array.isArray(jsonError.errors)) return false;

    return !! jsonError.errors.find(error => error.code === code);
  },
};

export const enum ErrorCode {
  DEFAULT_ERROR = 1000,
  INVALID_PASSWORD_STRENGTH,
  INVALID_EMAIL_EXISTS,
  NO_ASK_RESET_PASSWORD,
  ENUM_FIELD,
  REQUIRED_FIELD,
  RESET_PASSWORD_TOKEN_EXPIRED,
  ACCOUNT_NOT_CONFIRMED,
  ACCOUNT_ALREADY_CONFIRMED,
  ACCESS_TOKEN_EXPIRED,
  ACCOUNT_MARKED_FOR_DELETION,
}
