import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'input-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss'],
})
export class PasswordConfirmComponent implements OnInit {

  @Input() parentForm: FormGroup;

  private passwordErrors: any;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.parentForm.controls['password'].setValidators([Validators.required,
      Validators.minLength(8), PasswordConfirmComponent.passwordRuleValidators]);
    this.parentForm.controls['password'].updateValueAndValidity({ onlySelf: true });

    this.parentForm.controls['confirmPassword'].setValidators([Validators.required, PasswordConfirmComponent.confirmPasswordValidator]);
    this.parentForm.controls['confirmPassword'].updateValueAndValidity({ onlySelf: true });

    this.translate.get(['PASSWORD_CONFIRM_WIDGET.MESSAGES']).subscribe((translations) => {
      this.passwordErrors = translations['PASSWORD_CONFIRM_WIDGET.MESSAGES'];
    });
  }

  static passwordRuleValidators(control: AbstractControl) {
    const password = control.value;

    const hasNumber = /\d/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    return (hasNumber && hasUpperCase && hasLowerCase) ?
      null :
     { noNumber: !hasNumber, noUpperCase: !hasUpperCase, noLowerCase: !hasLowerCase };
  }

  static confirmPasswordValidator(control: AbstractControl) {
    return control.parent.controls['password'].value === control.value ?
      null : { passwordsDoNotMatch: true };
  }

  getPasswordErrorString(): string {
    if (this.passwordErrors) {
      const validationErrors: ValidationErrors = this.parentForm.get('password').errors;

      if (validationErrors.required) {
        return this.passwordErrors['PASSWORD_REQUIRED'];
      }

      return this.passwordErrors['PASSWORD_INVALID'];
    }
  }

  onPasswordChanged() {
    this.parentForm.controls['confirmPassword'].updateValueAndValidity();
  }
}
