import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurveyStatusListResponse } from '../model/project/Responses';
import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SurveyStatusService {

  constructor(private http: HttpClient) {}

  public getStatus() {
    return this.http.get<SurveyStatusListResponse>(`${environment.api_url}/v1/web/survey_status`,
                                                   HttpUtils.getJsonContentTypeHeader());
  }
}
