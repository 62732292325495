import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'QuestDetect';
  private routerSubscription: Subscription;

  constructor(private translate : TranslateService,
              private router: Router,
              private route: ActivatedRoute) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
    /**
     * Scroll to top of the page on every route change pages except the project sections.
     */
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe(() => {
      if (this.route.firstChild &&
        this.route.firstChild.component['name'] !== 'CreateProjectComponent') {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
