<div class="view-report-wrapper">
  <div class="page-header default-padding" fxLayout="row">
    <app-breadcrumb [lastRoutePartLabel]="currentProject.title"></app-breadcrumb>
  </div>
  <div class="summary-wrapper default-padding">
    <app-project-summary-box *ngIf="currentProject"
                             [project]="currentProject"
                             [isExportReportBtn]="true"
    ></app-project-summary-box>
  </div>

  <div class="report-wrapper default-padding">
    <h2 class="title">{{ 'Project Details' }}</h2>
    <app-preloader [loader]="projectStatsByProjectIdHttpSubscriber">
      <div class="reports"
           fxLayoutAlign="start"
           fxLayout.gt-sm="row"
           fxLayout.lt-sm="column"
           *ngIf="projectStats"
      >
        <app-default-box
          *ngIf="totalScoreData"
          [data]="totalScoreData"
          fxFlex.gt-xs="100"
          fxFlex.gt-sm="66"
          fxFlex.gt-md="50"></app-default-box>
        <app-default-box
          *ngIf="interviewLengthData"
          [data]="interviewLengthData"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="33"
          fxFlex.gt-md="25"></app-default-box>
        <app-default-box
          *ngIf="qualityData"
          [data]="qualityData"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="33"
          fxFlex.gt-md="25"></app-default-box>
        <app-default-box
          *ngIf="quotaData"
          [data]="quotaData"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="33"
          fxFlex.gt-md="25"></app-default-box>
        <app-default-box
          *ngIf="securityData"
          [data]="securityData"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="33"
          fxFlex.gt-md="25"></app-default-box>
      </div>
      <div *ngIf="!projectStats && !isProjectStats">
        <p>{{ 'PROJECT_VIEW_REPORT.MESSAGE.REQUEST_SUCCESS_EMPTY' | translate}}</p>
      </div>
    </app-preloader>
  </div>
</div>
