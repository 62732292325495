<mat-card class="project-card"
          fxLayout.gt-sm="row" fxLayout.lt-sm="column"
          [ngClass]="{'box-hover': isBoxHover, 'box-border': isBoxBorder}"
>
  <div fxFlex.gt-sm="70" fxFlex.gt-md="80" fxLayout="column">
    <div fxLayout.gt-xs="row" fxLayout.lt-xs="column">
      <div fxFlex.gt-xs="30" fxFlex.gt-sm="15" fxLayout="column">
        <img [src]="project.logoKey"
             *ngIf="project.logoKey else defaultLogo"
             class="project-image"/>
        <ng-template #defaultLogo let-user>
          <img src="assets/img/account_circle.svg" class="project-image project-image-default"/>
        </ng-template>
      </div>
      <div fxFlex.gt-xs="70" fxFlex.gt-sm="75" fxFlex.gt-md="80">
        <div class="cell">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.CLIENT' | translate }}</div>
          <div class="value">{{project.client}}</div>
        </div>
        <div class="cell">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.PROJECT' | translate }}</div>
          <div class="value title">{{project.title}}</div>
        </div>
        <div class="cell">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.STATUS' | translate }}</div>
          <div class="value">{{project.status}}</div>
        </div>
        <div class="cell">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.MANAGER' | translate }}</div>
          <div class="value">{{project.projectManager}}</div>
        </div>
        <div class="cell" *ngIf="isProjectHealth">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.HEALTH' | translate }}</div>
          <div class="value">{{ 'PROJECT_LIST.PROJECT.HEALTH_GOOD' | translate }}</div>
        </div>
        <div class="cell">
          <div class="label lighter-font">{{ 'PROJECT_LIST.PROJECT.ENDING' | translate }}</div>
          <div class="value">{{project.endDate | date}}</div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex.gt-sm="30" fxFlex.gt-md="20" fxLayout="column">
    <div fxLayout.gt-xs="row" fxLayout.lt-xs="column" fxLayoutAlign.gt-xs="end center">
      <div fxFlex.gt-xs="70" fxFlex.gt-sm="100" fxLayout="column">
        <div class="cell-btn">
          <button *ngIf="isViewReportBtn"
                  mat-raised-button
                  color="secondary"
                  class="width-200"
                  routerLink="/projects/view-report/"
                  [queryParams]="{ id: project._id }"
          >
            {{ 'PROJECT_LIST.PROJECT.BUTTONS.VIEW' | translate }}
          </button>
          <button *ngIf="isExportReportBtn"
                  mat-raised-button
                  color="secondary"
                  class="width-200"
                  appExportCsv
                  [projectId]="project._id"
                  [translationKey]="'EXPORT_CSV_PROJECT'"
          >
            {{ 'PROJECT_VIEW_REPORT.EXPORT' | translate }}
          </button>
        </div>
        <div class="cell-btn">
          <button mat-raised-button
                  color="secondary"
                  class="width-200"
                  routerLink="/projects/edit/profile"
                  [queryParams]="{ id: project._id }"
                  id="{{project._id}}"
          >
            {{ 'PROJECT_LIST.PROJECT.BUTTONS.MANAGE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
