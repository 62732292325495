<div class="add-new-supplier dialog-content-wrapper">

  <div class="header">
    <div class="header-title">{{ 'SUPPLIERS.CREATE.TITLE' | translate }}</div>
    <div class="header-save">
      <button class="button dark-blue width-150" mat-raised-button [disabled]="!supplier.valid" [mat-dialog-close]="supplier" id="save-supplier">
        {{ 'QUALITY.LABELS.SAVE_BUTTON' | translate }}
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <form [formGroup]="supplier" class="supplier-params">
    <mat-form-field class="name-param">
      <mat-label>{{ 'SUPPLIERS.CREATE.SUPPLIER_NAME' | translate }}</mat-label>
      <input matInput formControlName="supplierName" placeholder="{{ 'SUPPLIERS.CREATE.SUPPLIER_NAME' | translate }}"   id="supplier-name">
      <mat-error *ngIf="supplier.get('supplierName').errors?.required">
        {{ 'SUPPLIERS.CREATE.SUPPLIER_NAME_REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="supplier.get('supplierName').errors?.supplierNameExists">{{ 'SUPPLIERS.CREATE.SUPPLIER_NAME_EXISTED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="panelist-id-param">
      <mat-label>{{ 'SUPPLIERS.CREATE.PANELIST_ID_PARAMETER' | translate }}</mat-label>
      <input matInput formControlName="panelistId" placeholder="{{ 'SUPPLIERS.CREATE.PANELIST_ID_PARAMETER' | translate }}"  id="supplier-panelist-id-parameter">
      <mat-error *ngIf="supplier.get('panelistId').errors?.required">
        {{ 'SUPPLIERS.CREATE.PANELIST_ID_PARAMETER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </form>

  <mat-divider></mat-divider>

  <div class="redirection-urls">
    <div class="title">{{ 'SUPPLIERS.CREATE.REDIRECTION_URL_TITLE' | translate }}</div>
    <div class="text">{{ 'SUPPLIERS.CREATE.REDIRECTION_URL_TEXT_1' | translate }}</div>
    <div class="text">{{ 'SUPPLIERS.CREATE.REDIRECTION_URL_TEXT_2' | translate }}</div>
    <div class="urls">
      <form [formGroup]="supplier" class="supplier-params">
        <mat-form-field>
          <mat-label>{{ 'SUPPLIERS.CREATE.COMPLETE' | translate }}</mat-label>
          <input matInput formControlName="completeUrl" placeholder="Complete" id="supplier-complete-url">
          <mat-error *ngIf="supplier.get('completeUrl').errors?.required">
            {{ 'SUPPLIERS.CREATE.COMPLETE_URL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="supplier.get('completeUrl').errors?.pattern">{{ 'SUPPLIERS.CREATE.COMPLETE_URL_ERROR' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'SUPPLIERS.CREATE.TERMINATE' | translate }}</mat-label>
          <input matInput formControlName="terminateUrl" placeholder="Terminate" id="supplier-terminate-url">
          <mat-error *ngIf="supplier.get('terminateUrl').errors?.required">
            {{ 'SUPPLIERS.CREATE.TERMINATE_URL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="supplier.get('terminateUrl').errors?.pattern">{{ 'SUPPLIERS.CREATE.TERMINATE_URL_ERROR' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'SUPPLIERS.CREATE.OVER_QUOTA' | translate }}</mat-label>
          <input matInput formControlName="overQuotaUrl" placeholder="Over Quota" id="supplier-over-quota-url">
          <mat-error *ngIf="supplier.get('overQuotaUrl').errors?.required">
            {{ 'SUPPLIERS.CREATE.OVER_QUOTA_URL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="supplier.get('overQuotaUrl').errors?.pattern">{{ 'SUPPLIERS.CREATE.OVER_QUOTA_URL_ERROR' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'SUPPLIERS.CREATE.SECURITY_TERMINATE' | translate }}</mat-label>
          <input matInput formControlName="securityTerminateUrl" placeholder="Security Terminate" id="supplier-security-terminate-url">
          <mat-error *ngIf="supplier.get('securityTerminateUrl').errors?.required">
            {{ 'SUPPLIERS.CREATE.SECURITY_TERMINATE_URL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="supplier.get('securityTerminateUrl').errors?.pattern">{{ 'SUPPLIERS.CREATE.SECURITY_TERMINATE_URL_ERROR' | translate }}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>

</div>
