<div class="login-wrapper mt-100" fxLayout="row" fxLayoutAlign="space-evenly">
  <img fxHide.sm fxHide.xs fxFlex="45" src="assets/img/authentication.png" alt="authentication-image" class="mt-50 height-500">

  <div fxFlex="30" fxFlex.sm="70" fxFlex.xs="90" class="box">
    <mat-card class="box auth-form-card">
      <mat-card-header>
        <mat-card-title>{{ 'LOGIN.TITLE' | translate }}</mat-card-title>
      </mat-card-header>

      <form class="form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-card-content>
          <mat-form-field class="width-100">
            <input matInput formControlName="email" placeholder="{{ 'LOGIN.LABELS.USERNAME' | translate }}" autocomplete="username" id="username">
            <mat-error *ngIf="loginForm.get('email').errors?.required">
              {{ 'LOGIN.MESSAGES.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="loginForm.get('email').errors?.email">
              {{ 'LOGIN.MESSAGES.EMAIL_INVALID' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="width-100">
            <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
            <input matInput [type]="toggle.type" formControlName="password" placeholder="{{ 'LOGIN.LABELS.PASSWORD' | translate }}" autocomplete="current-password" id="password">
            <mat-error *ngIf="loginForm.get('password').errors">
              {{ 'LOGIN.MESSAGES.PASSWORD_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-checkbox color="primary">{{ 'LOGIN.LABELS.REMEMBER_ME' | translate }}</mat-checkbox>

          <ngx-recaptcha2 #captchaElem
                          formControlName="recaptcha" *ngIf="showCaptcha"
                          [siteKey]="recaptchaSettings.siteKey"
                          (reset)="handleCaptchaReset()"
                          (expire)="handleCaptchaExpire()"
                          (load)="handleCaptchaLoad()"
                          (success)="handleCaptchaSuccess($event)"
                          [useGlobalDomain]="false"
                          [hl]="getCurrentLang()">
          </ngx-recaptcha2>


        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" id="login" type="submit" [disabled]="!this.loginForm.valid">{{ 'LOGIN.LABELS.LOGIN' | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card>
    <div class="register-container">
      <a routerLink="/register">{{ 'LOGIN.LABELS.NEED_ACCOUNT' | translate }}</a>
    </div>
    <div class="forgot-password-container">
      <a routerLink="/forgot-password">{{ 'LOGIN.LABELS.FORGOT_PASSWORD' | translate }}</a>
    </div>
  </div>
</div>
