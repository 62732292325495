import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-generic-dialog-page',
  templateUrl: './generic-dialog-page.component.html',
  styleUrls: ['./generic-dialog-page.component.scss'],
})
export class GenericDialogPageComponent implements OnInit {

  @Input() imagePath: string;
  @Input() title: string;
  @Input() text: string;
  @Input() showButton: boolean;
  @Input() buttonLabel: string;
  @Input() buttonRoute: string;

  constructor(private router: Router,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      if (data['imagePath']) this.imagePath = data['imagePath'];
      if (data['title']) this.title = data['title'];
      if (data['text']) this.text = data['text'];
      if (data['showButton']) this.showButton = data['showButton'];
      if (data['buttonLabel']) this.buttonLabel = data['buttonLabel'];
      if (data['buttonRoute']) this.buttonRoute = data['buttonRoute'];
      this.cd.detectChanges();
    });
  }

}
