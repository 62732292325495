import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ClientsService } from '../../services/clients.service';
import * as ProjectsAction from '../actionstypes/projects/projects.actiontype';
import * as ClientsAction from '../actionstypes/clients/clients.actiontype';
import { RequestStatusAction } from '../actionstypes/clients/request-status.actiontype';
import * as RequestStatus from '../actionstypes/request-status/request-status.actiontype';
import { Clients } from '../../model/client/client';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()

export class ClientsEffect {

  constructor(private actions$: Actions,
              private clients: ClientsService) {
  }

  @Effect()
  loadClients$ = this.actions$.pipe(
    ofType(ProjectsAction.ProjectsAction.Load),
    exhaustMap((action: ClientsAction.Load) => this.clients.getClients().pipe(
      map((clients: Clients) => (new ClientsAction.Get(clients))),
      catchError(() => of(new RequestStatus.RequestStatus((RequestStatusAction.Error)))),
    )),
  );

  @Effect()
  requestClients$ = this.actions$.pipe(
    ofType(ClientsAction.ClientsAction.Get),
    mergeMap(() => of(new RequestStatus.RequestStatus(RequestStatusAction.Success))),
  );

}
