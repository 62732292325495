import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';

interface DialogData {
  langCodes: string[];
}

@Component({
  selector: 'app-languages-warning-dialog',
  templateUrl: './languages-warning-dialog.component.html',
  styleUrls: ['./languages-warning-dialog.component.scss'],
})
export class LanguagesWarningDialogComponent implements OnInit {

  public languagesLabel: string[];

  constructor(private projectService: ProjectService,
              private dialogRef: MatDialogRef<LanguagesWarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    this.languagesLabel = this.projectService.sortLangCodesByLabel(this.data.langCodes);
  }

  cancelAction() {
    this.dialogRef.close();
  }
}
