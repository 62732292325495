import { ConfirmDialogComponent } from '../widgets/confirm-dialog/confirm-dialog.component';
import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
var ProjectGuardService = /** @class */ (function () {
    function ProjectGuardService(dialog, translate) {
        this.dialog = dialog;
        this.translate = translate;
    }
    ProjectGuardService.prototype.canDeactivate = function (component) {
        var _this = this;
        if (component.hasUnsavedChanges()) {
            return new Observable(function (requestObserver) {
                _this.translate.get('UNSAVED_DIALOG_DATA').subscribe(function (translations) {
                    var dialogRef = _this.dialog.open(ConfirmDialogComponent, {
                        width: '500px',
                        data: {
                            title: translations['TITLE'],
                            description: translations['DESCRIPTION'],
                            cancel_button: translations['CANCEL_BUTTON'],
                            confirm_button: translations['CONFIRM_BUTTON'],
                        },
                    });
                    dialogRef.afterClosed().subscribe(function (result) {
                        if (result) {
                            return requestObserver.next(true);
                        }
                        if (document.activeElement) {
                            document.activeElement.blur();
                        }
                        return requestObserver.next(false);
                    });
                });
            });
        }
        return of(true);
    };
    ProjectGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectGuardService_Factory() { return new ProjectGuardService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.TranslateService)); }, token: ProjectGuardService, providedIn: "root" });
    return ProjectGuardService;
}());
export { ProjectGuardService };
