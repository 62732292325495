<div class="profile-wrapper mt-50">
  <p class="pl-100 pr-100" style="font-size: 30px;" >Account Settings</p>
  <div ngClass.xs="pl-10 pr-10"  ngClass.md="pl-20 pr-20" class="profile-settings mt-50 pt-30 pb-30 pl-100 pr-100" fxLayout="row" fxLayoutAlign="flex-end">
    <mat-card class="box regualr-card mr-80" ngClass.xs="mr-20" ngClass.sm="mr-20" fxFlex="40"m fxFlex.sm="90" fxFlex.xs="90" >
      <mat-card-title>{{ 'PROFILE.ACCOUNT_INFORMATION.TITLE' | translate }}</mat-card-title>

      <mat-card-content>
        {{ 'PROFILE.ACCOUNT_INFORMATION.DESCRIPTION' | translate }}
      </mat-card-content>
      <form class="form mt-20" [formGroup]="updateProfileForm" (ngSubmit)='updateInfo()'>
        <mat-card-content>
          <app-image-uploader [parentForm]="updateProfileForm" [imageSrc]="imageSrc" [imageFormKey]="imageFormKey" [translationKey]="'IMAGE_UPLOADER_PROFILE'"></app-image-uploader>
          <mat-form-field class="width-100 mt-20">
            <input matInput formControlName="email" placeholder="{{ 'PROFILE.ACCOUNT_INFORMATION.LABELS.EMAIL' | translate }} ">
            <mat-error *ngIf="updateProfileForm.get('email').errors?.required">
              {{ 'PROFILE.ACCOUNT_INFORMATION.MESSAGES.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="updateProfileForm.get('email').errors?.email">
              {{ 'PROFILE.ACCOUNT_INFORMATION.MESSAGES.EMAIL_INVALID' | translate }}
            </mat-error>

          </mat-form-field>

          <mat-form-field class="width-100">
            <input  matInput type="text" formControlName="companyName" placeholder="{{ 'PROFILE.ACCOUNT_INFORMATION.LABELS.COMPANY' | translate }}"
                    autocomplete="company-name">
            <mat-error *ngIf="updateProfileForm.get('companyName').errors?.required">
              {{ 'PROFILE.ACCOUNT_INFORMATION.MESSAGES.COMPANY_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="updateProfileForm.get('companyName').errors?.maxlength">
              {{ 'PROFILE.ACCOUNT_INFORMATION.MESSAGES.COMPANY_MAX_LENGTH' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button class="width-200 light-blue" type="submit" >{{ 'PROFILE.UPDATE_BUTTON' | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card>

    <mat-card ngClass.xs="mr-20" ngClass.sm="mr-20" class="box regualr-card mr-80" fxFlex="40" fxFlex.sm="90" fxFlex.xs="90">
      <mat-card-title>{{ 'PROFILE.UPDATE_PASSWORD.TITLE' | translate }}</mat-card-title>
      <form class="form" [formGroup]="updatePasswordForm" #formDirective="ngForm" (ngSubmit)='updatePassword(formDirective)'>
        <mat-card-content>
          <span>{{ 'PROFILE.UPDATE_PASSWORD.DESCRIPTION' | translate }}</span>
          <br/>
          <br/>
          <input-password-confirm [parentForm]="updatePasswordForm"></input-password-confirm>
          <br/>
          <mat-form-field class="width-100">
            <mat-pass-toggle-visibility #toggleConfrimPassword matSuffix></mat-pass-toggle-visibility>
            <input matInput [type]="toggleConfrimPassword.type" formControlName="currentPassword"
                   placeholder="{{ 'PROFILE.UPDATE_PASSWORD.LABELS.CURRENT_PASSWORD' | translate }}" autocomplete="current-password">
            <mat-error *ngIf="updatePasswordForm.get('currentPassword').errors?.required">
              {{ 'PROFILE.UPDATE_PASSWORD.MESSAGES.CURRENT_PASSWORD_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button class="width-200 light-blue" type="submit" [disabled]="!this.updatePasswordForm.valid" >{{ 'PROFILE.UPDATE_BUTTON' | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card>


    <mat-card ngClass.xs="mr-20" ngClass.sm="mr-20"class="box regualr-card mr-80" fxFlex="40" fxFlex.sm="90" fxFlex.xs="90">
      <mat-card-title>{{ 'PROFILE.DELETE_ACCOUNT.TITLE' | translate }}</mat-card-title>
      <mat-card-content>
        {{ 'PROFILE.DELETE_ACCOUNT.DESCRIPTION' | translate }}
      </mat-card-content>
      <button mat-raised-button class="mt-20 red-text width-200" (click)="openConfirmDialog()">{{ 'PROFILE.DELETE_BUTTON' | translate }}</button>

    </mat-card>

  </div>
</div>
