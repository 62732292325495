import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SupplierService = /** @class */ (function () {
    function SupplierService(http) {
        this.http = http;
    }
    SupplierService.prototype.getSuppliers = function () {
        return this.http.get(environment.api_url + "/v1/web/suppliers", HttpUtils.getJsonContentTypeHeader());
    };
    SupplierService.prototype.createSupplier = function (request) {
        // tslint:disable-next-line: max-line-length
        return this.http.post(environment.api_url + "/v1/web/user/supplier", request, HttpUtils.getJsonContentTypeHeader());
    };
    SupplierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupplierService_Factory() { return new SupplierService(i0.ɵɵinject(i1.HttpClient)); }, token: SupplierService, providedIn: "root" });
    return SupplierService;
}());
export { SupplierService };
