<app-preloader [loader]="updateProjectHttpSubscriber" [isFullScreen]="true">
<div class="suppliers-wrapper">
  <div class="step-header">
    <li> 2 <span class='spacer'>{{ 'SIDEBAR.LABELS.SUPPLIERS' | translate }}</span> </li>
    <app-project-pricing-dropdown></app-project-pricing-dropdown>
  </div>
  <div class="suppliers-form">
    <div class="importance-notice">
      <p class="bold">{{ 'SUPPLIERS.HEADINGS.HOW_SUPPLIERS_WORK' | translate }}</p>
      <p class="italic">{{ 'SUPPLIERS.DESCRIPTIONS.HOW_SUPPLIERS_WORK' | translate }}</p>
      <p>{{ 'SUPPLIERS.DESCRIPTIONS.ADDED_BY_USER' | translate }}</p>
    </div>

    <div class="suppliers-lists">
      <div class="default-list">
        <mat-list>
          <h3 mat-subheader>{{ 'SUPPLIERS.LABELS.LIST' | translate}}</h3>
          <div class="select-button-container" fxLayout="row" fxLayoutAlign="space-evenly center">
            <button mat-button (click)="addSelectedSuppliers()" class="select-button" [disabled]="!hasSuppliersWithProperties(false, true)">
              <span class="font-awesome f-18"></span>
            </button>
          </div>
          <div class="filter-field-container">
            <mat-form-field class="filter-field">
              <mat-label>{{ 'SUPPLIERS.LABELS.SEARCH_PLACEHOLDER' | translate }}</mat-label>
              <input matInput type="text" [(ngModel)]="searchText"
                     (ngModelChange)="enableSearch()" (click)="enableSearch()" id="suppliers-search-input">
              <button mat-button *ngIf="!isSearch" matSuffix mat-icon-button>
                <mat-icon>search</mat-icon>
              </button>
              <button mat-button *ngIf="isSearch" matSuffix mat-icon-button aria-label="Clear" (click)="closeSearch()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="mat-list-item-container">
            <mat-list-item *ngFor="let supplier of getSuppliers(false) | filter : searchText:'name'">
              <mat-checkbox [checked]="supplier.checked" (change)="supplier.checked = $event.checked" mat-line color="primary">{{supplier.label}}</mat-checkbox>
            </mat-list-item>
          </div>
          <a href="#" id="add-new-supplier" class="add-new-supplier" *ngIf="isSearch" (click)="openAddSupplierDialog($event)">{{ 'SUPPLIERS.LABELS.ADD_NEW_SUPPLIER' | translate}}</a>
        </mat-list>
      </div>
      <div class="selected-list">
        <mat-list>
          <h3 mat-subheader>{{  'SUPPLIERS.LABELS.SELECTED' | translate}}</h3>
          <div class="select-button-container" fxLayout="row" fxLayoutAlign="space-evenly center">
            <button mat-button (click)="removeSelectedSuppliers()" class="select-button" [disabled]="!hasSuppliersWithProperties(true, true)">
              <span class="font-awesome f-18"></span>
            </button>
          </div>
          <div class="mat-selected-item-container">
            <mat-list-item *ngFor="let supplier of getSuppliers(true)">
              <mat-checkbox [checked]="supplier.checked" (change)="supplier.checked = $event.checked" mat-line color="primary">{{supplier.label}}</mat-checkbox>
            </mat-list-item>
          </div>
          </mat-list>
      </div>
    </div>
    <button mat-raised-button class="width-200 light-blue mt-40" (click)="saveSuppliers()" [disabled]="!hasSelectedSuppliers()">
      {{ 'PROJECT_PROFILE.LABELS.SUBMIT_BUTTON' | translate }}
    </button>
  </div>
</div>
</app-preloader>
