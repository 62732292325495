<div class="dialog-content-wrapper">
  <h2 mat-dialog-title>{{ 'LANGUAGES_WARNING_DIALOG.HEADING' | translate }}</h2>
  <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
  <mat-dialog-content class="mat-typography">
    <p *ngIf="languagesLabel.length === 1">{{ 'LANGUAGES_WARNING_DIALOG.DESCRIPTION_OPTION' | translate }}</p>
    <p *ngIf="languagesLabel.length >= 2">{{ 'LANGUAGES_WARNING_DIALOG.DESCRIPTION_OPTIONS' | translate }}</p>
    <div class="languages-list">
      <ul>
        <li *ngFor="let label of languagesLabel">{{ label }}</li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="button red mt-10 width-150"
            mat-button
            (click)="cancelAction()"
    >
      {{ 'LANGUAGES_WARNING_DIALOG.NO_BUTTON' | translate }}
    </button>
    <button class="button dark-blue mt-10 width-150"
            mat-button
            [mat-dialog-close]="true"
    >
      {{ 'LANGUAGES_WARNING_DIALOG.YES_BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
</div>
