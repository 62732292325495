import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ProjectStepComponent } from '../project-step-component';
import { FormArray, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Outcome, QuestionSource, QuestionType, } from '../../../model/project/project';
import { AskDialogComponent } from '../../../widgets/ask-dialog/ask-dialog.component';
import { Observable } from 'rxjs';
import { STATUS_OPTIONS } from '../../../model/project/options';
import { DEFAULT_LANG_CODE } from '../../../model/project/languages';
var QualityComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QualityComponent, _super);
    function QualityComponent(snackBarService, route, projectService, projectDataService, router, translate, dialog, dirtyFormService) {
        var _this = _super.call(this) || this;
        _this.snackBarService = snackBarService;
        _this.route = route;
        _this.projectService = projectService;
        _this.projectDataService = projectDataService;
        _this.router = router;
        _this.translate = translate;
        _this.dialog = dialog;
        _this.dirtyFormService = dirtyFormService;
        _this.QUESTION_COMPONENT_OFFSET = 140;
        // TODO Pricing: remove readonly and get price from BE
        _this.questionPriceTranslateParam = { questionPrice: '$0.02' };
        _this.selectedIndex = -1;
        _this.isEditModeDisabled = false;
        _this.questionLengthValidator = function (questionsFormArray) {
            return questionsFormArray.length > 0 ? null : { noQuestionDefined: true };
        };
        _this.radioAnswerOutcomesValidator = function (answersFormArray) {
            return answersFormArray.length > 1 && answersFormArray.controls.some(function (answerFormGroup) {
                return answerFormGroup.controls['outcome'].value === Outcome.Accept;
            }) ? null : { noAcceptOutcome: true };
        };
        if (_this.route.snapshot.data.project instanceof Observable) {
            _this.route.snapshot.data.project.subscribe(function (project) {
                _this.project = project;
            });
        }
        // Activate the current question.
        _this.route.fragment.subscribe(function (fragments) {
            if (fragments) {
                var match = fragments.match(/\d+/);
                if (match.length) {
                    var result = match[0];
                    var availableQuestion = _this.project.questions[result];
                    if (availableQuestion) {
                        _this.onQuestionSelected(Number(result));
                    }
                }
            }
        });
        _this.isEditModeDisabled = _this.project.status !== STATUS_OPTIONS.DEFAULT;
        return _this;
    }
    QualityComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        /**
         * If the `id` of the project is available, that's means that the project is
         * created and accessible in the application by navigating between the different pages.
         * Conditions/validations are done in the ProjectResolver.
         */
        if (this.project._id) {
            this.setQualityFormData();
        }
        // Shallow copy of the array to avoid changing the main data.
        var currentProjectLanguages = tslib_1.__spread(this.project.languages);
        try {
            for (var currentProjectLanguages_1 = tslib_1.__values(currentProjectLanguages), currentProjectLanguages_1_1 = currentProjectLanguages_1.next(); !currentProjectLanguages_1_1.done; currentProjectLanguages_1_1 = currentProjectLanguages_1.next()) {
                var language = currentProjectLanguages_1_1.value;
                language.label = this.translate.instant("LANGUAGES." + language.langCode);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (currentProjectLanguages_1_1 && !currentProjectLanguages_1_1.done && (_a = currentProjectLanguages_1.return)) _a.call(currentProjectLanguages_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.currentSortProjectLanguages = currentProjectLanguages.sort(function (a, b) { return a.label > b.label ? 1 : -1; });
        // At page load, get/set the first selected language.
        var englishLanguage = this.currentSortProjectLanguages.filter(function (language) {
            return language.langCode === DEFAULT_LANG_CODE;
        });
        this.selectedLangCode = englishLanguage.length ? englishLanguage[0].langCode.toLowerCase()
            : this.currentSortProjectLanguages[0].langCode.toLowerCase();
    };
    QualityComponent.prototype.ngOnDestroy = function () {
        var langCodesWithEmptyText = this.getLangCodesWithEmptyText();
        if (langCodesWithEmptyText.length) {
            this.project.questions = this.projectService.deleteQuestions(this.project.questions, langCodesWithEmptyText);
        }
        if (this.updateProjectHttpSubscriber) {
            this.updateProjectHttpSubscriber.unsubscribe();
        }
    };
    QualityComponent.prototype.getLangCodesWithEmptyText = function () {
        var e_2, _a;
        var langCodes = [];
        if (this.project.questions
            && this.project.questions.length
            && this.project.questions[0].text
            && this.project.questions[0].text.length) {
            var _loop_1 = function (text) {
                if (text.text === '') {
                    var questionLangCode_1 = text.langCode.toLowerCase();
                    var questionLanguageAvailable = this_1.project.languages.some(function (language) {
                        return language.langCode.toLowerCase() === questionLangCode_1;
                    });
                    if (questionLanguageAvailable) {
                        langCodes.push(questionLangCode_1);
                    }
                }
            };
            var this_1 = this;
            try {
                for (var _b = tslib_1.__values(this.project.questions[0].text), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var text = _c.value;
                    _loop_1(text);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return langCodes;
    };
    Object.defineProperty(QualityComponent.prototype, "questionsFormArray", {
        get: function () {
            return this.questionsForm.get('questions');
        },
        enumerable: true,
        configurable: true
    });
    QualityComponent.prototype.setQualityFormData = function () {
        var _this = this;
        var questions = this.project.questions.length > 0 ?
            this.project.questions : [this.getEmptyQuestion()];
        this.questionsForm = new FormGroup({
            questions: new FormArray(questions.map(function (question) {
                return _this.createQuestionFormGroup(question);
            }), { validators: this.questionLengthValidator })
        });
        this.currentStepForm.form = this.questionsForm;
        if (this.project.questions.length === 0) {
            this.selectedIndex = 0;
        }
        this.dirtyFormService.reset();
        this.questionsForm.valueChanges.subscribe(function () { _this.dirtyFormService.isDirty(); });
    };
    QualityComponent.prototype.getQuestionForm = function (index) {
        return this.questionsFormArray.at(index);
    };
    QualityComponent.prototype.addQuestion = function () {
        this.questionsFormArray.push(this.createQuestionFormGroup(this.getEmptyQuestion()));
        this.selectedIndex = this.questionsFormArray.length - 1;
    };
    QualityComponent.prototype.focus = function () {
        this.router.navigate([], { fragment: "question-" + this.selectedIndex, queryParamsHandling: 'preserve' });
    };
    QualityComponent.prototype.onQuestionSelected = function (questionIndex) {
        this.selectedIndex = questionIndex;
        this.focus();
    };
    QualityComponent.prototype.onQuestionRemove = function (questionIndex) {
        var _this = this;
        this.translate.get('QUESTION.DELETE_DIALOG_LABELS').subscribe(function (translations) {
            var dialogRef = _this.dialog.open(AskDialogComponent, {
                width: '480px',
                data: {
                    title: translations['TITLE'],
                    description: translations['DESCRIPTION'],
                    cancelButton: translations['CANCEL_BUTTON'],
                    confirmButton: translations['CONFIRM_BUTTON'],
                },
                autoFocus: false,
                restoreFocus: false,
            });
            dialogRef.afterClosed().subscribe(function (confirm) {
                if (confirm) {
                    _this.questionsFormArray.markAsDirty();
                    _this.questionsFormArray.removeAt(questionIndex);
                    if (_this.questionsFormArray.length) {
                        _this.onQuestionSelected(0);
                    }
                    else {
                        _this.selectedIndex = -1;
                    }
                }
            });
        });
    };
    QualityComponent.prototype.getQuestionAnswersOffset = function () {
        if (this.selectedIndex <= 0)
            return 0;
        return this.selectedIndex * this.QUESTION_COMPONENT_OFFSET;
    };
    QualityComponent.prototype.getIdExtendObject = function (mainId, textData) {
        var e_3, _a;
        var extend = {};
        var formattedTextData = [];
        var currentMainId = mainId !== '' ? mainId : null;
        if (currentMainId) {
            extend['_id'] = currentMainId;
        }
        try {
            for (var textData_1 = tslib_1.__values(textData), textData_1_1 = textData_1.next(); !textData_1_1.done; textData_1_1 = textData_1.next()) {
                var text = textData_1_1.value;
                var currentTextId = text._id !== '' ? text._id : null;
                var currentText = { langCode: text.langCode, text: text.text };
                formattedTextData.push(currentTextId ? tslib_1.__assign({ _id: currentTextId }, currentText) : currentText);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (textData_1_1 && !textData_1_1.done && (_a = textData_1.return)) _a.call(textData_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        extend['text'] = formattedTextData;
        return extend;
    };
    QualityComponent.prototype.save = function () {
        var _this = this;
        if (this.questionsForm.valid && this.project._id) {
            this.dirtyFormService.isValid();
            // TODO handle different types and sources in the future
            var questions = this.questionsFormArray.value.map(function (questionAnswers) {
                var extend = _this.getIdExtendObject(questionAnswers._id, questionAnswers.text);
                return tslib_1.__assign({}, extend, { type: QuestionType.Radio, source: QuestionSource.User, answers: questionAnswers.answers.map(function (answer) {
                        var answersExtend = _this.getIdExtendObject(answer._id, answer.text);
                        return tslib_1.__assign({}, answersExtend, { outcome: answer.outcome, quota: answer.quota === 0 ? null : answer.quota });
                    }) });
            });
            var projectData = {
                questions: questions,
                _id: this.project._id,
            };
            this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData).subscribe(function (projectUpdated) {
                _this.projectDataService.projectData = projectUpdated.payload.project;
                _this.snackBarService.successWithClose('QUALITY.MESSAGES.QUESTIONS_SAVED');
                _this.currentStepForm.submitted = true;
                _this.router.navigate(["/projects/" + _this.projectService.editionMode + "/generate"], { queryParamsHandling: 'preserve' });
            }, function (error) {
                _this.snackBarService.errorWithClose('QUALITY.MESSAGES.SAVE_FAILED');
            });
        }
    };
    QualityComponent.prototype.createQuestionTextFormGroup = function (i18nText) {
        return new FormGroup({
            _id: new FormControl(i18nText._id || ''),
            langCode: new FormControl(i18nText.langCode.toLowerCase()),
            text: new FormControl({
                value: this.getTextInLang([i18nText], i18nText.langCode),
                disabled: this.isEditModeDisabled,
            }, Validators.required),
        });
    };
    QualityComponent.prototype.createQuestionFormGroup = function (question) {
        var e_4, _a;
        var _this = this;
        switch (question.type) {
            case QuestionType.Radio: {
                var radioQuestion = question;
                if (radioQuestion.text.length !== this.project.languages.length) {
                    var _loop_2 = function (language) {
                        var e_5, _a;
                        var langCode = language.langCode.toLowerCase();
                        var isTextAlreadyAvailable = radioQuestion.text.some(function (text) {
                            return text.langCode.toLowerCase() === langCode;
                        });
                        // Add new question text on language available
                        if (!isTextAlreadyAvailable) {
                            radioQuestion.text.push({ langCode: langCode, text: '' });
                        }
                        try {
                            for (var _b = (e_5 = void 0, tslib_1.__values(radioQuestion.answers)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var answer = _c.value;
                                var isAnswerTextAlreadyAvailable = answer.text.some(function (text) {
                                    return text.langCode.toLowerCase() === langCode;
                                });
                                // Add new answer text on language available
                                if (!isAnswerTextAlreadyAvailable) {
                                    answer.text.push({ langCode: langCode, text: '' });
                                }
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                    };
                    try {
                        for (var _b = tslib_1.__values(this.project.languages), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var language = _c.value;
                            _loop_2(language);
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
                return new FormGroup({
                    _id: new FormControl(radioQuestion._id || ''),
                    text: new FormArray(radioQuestion.text.map(function (i18nText) {
                        return _this.createQuestionTextFormGroup(i18nText);
                    })),
                    answers: new FormArray(radioQuestion.answers.map(function (answer) {
                        return _this.createAnswerFormGroup(answer);
                    }), { validators: this.radioAnswerOutcomesValidator }),
                });
            }
            // TODO add new question type here
            default:
                return null;
        }
    };
    QualityComponent.prototype.createAnswerTextFormGroup = function (i18nText) {
        return new FormGroup({
            _id: new FormControl(i18nText._id || ''),
            langCode: new FormControl(i18nText.langCode),
            text: new FormControl({
                value: this.getTextInLang([i18nText], i18nText.langCode),
                disabled: this.isEditModeDisabled,
            }, Validators.required),
        });
    };
    QualityComponent.prototype.createAnswerFormGroup = function (answer) {
        var _this = this;
        return new FormGroup({
            _id: new FormControl(answer._id || ''),
            text: new FormArray(answer.text.map(function (i18nText) {
                return _this.createAnswerTextFormGroup(i18nText);
            })),
            outcome: new FormControl(answer.outcome, Validators.required),
            quota: new FormControl(answer.quota),
        });
    };
    QualityComponent.prototype.getTextInLang = function (textArray, lang) {
        var item = textArray.find(function (text) { return text.langCode === lang; });
        return item ? item.text : '';
    };
    QualityComponent.prototype.getEmptyQuestion = function () {
        var e_6, _a, e_7, _b;
        var textList = [];
        var answerList = [];
        answerList.push({ text: [], outcome: Outcome.Accept });
        answerList.push({ text: [], outcome: Outcome.Reject });
        try {
            for (var _c = tslib_1.__values(this.project.languages), _d = _c.next(); !_d.done; _d = _c.next()) {
                var language = _d.value;
                var langCode = language.langCode.toLowerCase();
                textList.push({ langCode: langCode, text: '' });
                try {
                    for (var answerList_1 = (e_7 = void 0, tslib_1.__values(answerList)), answerList_1_1 = answerList_1.next(); !answerList_1_1.done; answerList_1_1 = answerList_1.next()) {
                        var answer = answerList_1_1.value;
                        answer.text.push({ langCode: langCode, text: '' });
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (answerList_1_1 && !answerList_1_1.done && (_b = answerList_1.return)) _b.call(answerList_1);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_6) throw e_6.error; }
        }
        return {
            type: QuestionType.Radio,
            source: QuestionSource.User,
            text: textList,
            answers: answerList
        };
    };
    return QualityComponent;
}(ProjectStepComponent));
export { QualityComponent };
