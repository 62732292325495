import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventManagerService {

  private listeners: Object = {};
  private eventsSubject: Subject<any> = new Subject();
  private events: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.events.subscribe(
      ({ name, args }: any) => {
        if (this.listeners[name]) {
          for (const listener of this.listeners[name]) {
            listener(...args);
          }
        }
      },
    );
  }

  on(name: string, listener: Function): void {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  off(name: string, listener: Function): void {
    if (this.listeners[name] && this.listeners[name].indexOf(listener) > -1) {
      this.listeners[name] = this.listeners[name].filter(x => x !== listener);
    }
  }

  broadcast(name: string, ...args): void {
    this.eventsSubject.next({
      name,
      args,
    });
  }
}
