/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../widgets/generic-dialog-page/generic-dialog-page.component.ngfactory";
import * as i2 from "../../widgets/generic-dialog-page/generic-dialog-page.component";
import * as i3 from "@angular/router";
import * as i4 from "./email-sent.component";
import * as i5 from "@ngx-translate/core";
var styles_EmailSentComponent = [];
var RenderType_EmailSentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailSentComponent, data: {} });
export { RenderType_EmailSentComponent as RenderType_EmailSentComponent };
export function View_EmailSentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-dialog-page", [], null, null, null, i1.View_GenericDialogPageComponent_0, i1.RenderType_GenericDialogPageComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericDialogPageComponent, [i3.Router, i0.ChangeDetectorRef, i3.ActivatedRoute], { imagePath: [0, "imagePath"], title: [1, "title"], text: [2, "text"], showButton: [3, "showButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/img/authentication.png"; var currVal_1 = "EMAIL_SENT.TITLE"; var currVal_2 = _co.description; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_EmailSentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-email-sent", [], null, null, null, View_EmailSentComponent_0, RenderType_EmailSentComponent)), i0.ɵdid(1, 114688, null, 0, i4.EmailSentComponent, [i3.ActivatedRoute, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailSentComponentNgFactory = i0.ɵccf("app-email-sent", i4.EmailSentComponent, View_EmailSentComponent_Host_0, {}, {}, []);
export { EmailSentComponentNgFactory as EmailSentComponentNgFactory };
