import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../services/snack-bar.service';

/**
 * @example
 * <input type="text"
 *  matInput
 *  value="http://www.url.com/?id={id}"
 *  placeholder="Links"
 *  readonly
 *  iconName="file_copy"
 *  iconAltKey="COPY_CLIPBOARD.ALT"
 *  successMsgKey="COPY_CLIPBOARD.MESSAGES.SUCCESS"
 *  appCopyClipboard>
 */
@Directive({
  selector: '[appCopyClipboard]',
})
export class CopyClipboardDirective implements OnInit, OnDestroy {

  @Input() iconName: string = 'file_copy';
  @Input() iconAltKey: string = 'COPY_CLIPBOARD.ALT';
  @Input() successMsgKey: string = 'COPY_CLIPBOARD.MESSAGES.SUCCESS';

  private componentFactory: ComponentFactory<MatIcon>;
  private componentRef: ComponentRef<MatIcon>;
  private unListenerClick: () => void;

  @HostListener('click') onClick() {
    this.copyFromInputElement();
  }

  constructor(private appRef: ApplicationRef,
              private el: ElementRef,
              private factoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private renderer: Renderer2,
              private snackBarService: SnackBarService,
              private translate: TranslateService,
              ) {
    this.renderer.setAttribute(this.el.nativeElement, 'title', this.translate.instant(this.iconAltKey));
  }

  private copyText(): boolean {
    return document.execCommand('copy');
  }

  public copyFromInputElement(): boolean {
    try {
      this.el.nativeElement.select();
      this.el.nativeElement.setSelectionRange(0, 99999);
      const copy = this.copyText();
      this.el.nativeElement.setSelectionRange(-1, -1);

      this.snackBarService.successWithClose(this.successMsgKey);

      return copy;
    } catch (err) {
      return false;
    }
  }

  createComponent() {
    // create factory for icon component
    this.componentFactory = this.factoryResolver.resolveComponentFactory(MatIcon);

    // create icon component
    this.componentRef = this.componentFactory.create(this.injector);
  }

  addComponentData() {
    // assign icon value to component icon
    this.renderer.setProperty(this.componentRef.instance._elementRef.nativeElement, 'innerText', this.iconName);
    this.renderer.setAttribute(this.componentRef.instance._elementRef.nativeElement, 'title', this.translate.instant(this.iconAltKey));

    // add click event action on icon instance
    this.unListenerClick = this.renderer.listen(this.componentRef.instance._elementRef.nativeElement, 'click', () => {
      this.copyFromInputElement();
    });
  }

  addDynamicComponent() {
    this.appRef.attachView(this.componentRef.hostView);

    const domElm = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    this.renderer.appendChild(this.el.nativeElement.parentNode, domElm);
  }

  ngOnInit(): void {
    this.createComponent();
    this.addComponentData();
    this.addDynamicComponent();
  }

  ngOnDestroy(): void {
    if (this.unListenerClick) {
      this.unListenerClick();
    }
    if (this.appRef) {
      this.appRef.detachView(this.componentRef.hostView);
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
