import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateProjectResolver } from './resolvers/create-project.resolver';
import { ProjectResolver } from './resolvers/project.resolver';
import { ProjectProfileComponent } from './components/project/project-profile/project-profile.component';
import { SuppliersComponent } from './components/project/suppliers/suppliers.component';
import { SecurityComponent } from './components/project/security/security.component';
import { QualityComponent } from './components/project/quality/quality.component';
import { ProjectGuardService } from './services/project-guard.service';
import { CreateProjectComponent } from './components/project/create-project/create-project.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GenerateComponent } from './components/project/generate/generate.component';
import { ViewReportComponent } from './components/project/view-report/view-report.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { RouteId } from './model/Common';

const childrenProjectRoutes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'profile',
  },
  { path: 'profile', component: ProjectProfileComponent,
    canDeactivate: [ProjectGuardService],
    data: { routeId: RouteId.PROJECTS },
    resolve: { project: ProjectResolver },
  },
  { path: 'suppliers', component: SuppliersComponent,
    canDeactivate: [ProjectGuardService],
    data: { routeId: RouteId.PROJECTS },
    resolve: { project: ProjectResolver },
  },
  { path: 'security', component: SecurityComponent,
    canDeactivate: [ProjectGuardService],
    data: { routeId: RouteId.PROJECTS },
    resolve: { project: ProjectResolver },
  },
  { path: 'quality', component: QualityComponent,
    canDeactivate: [ProjectGuardService],
    data: { routeId: RouteId.PROJECTS },
    resolve: { project: ProjectResolver },
  },
  { path: 'generate', component: GenerateComponent,
    canDeactivate: [ProjectGuardService],
    data: { routeId: RouteId.PROJECTS },
    resolve: { project: ProjectResolver },
  },
];

const projectRoutes: Routes = [
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['User'], routeId: RouteId.PROJECTS, breadcrumbLabelKey: 'NAVIGATION.LABELS.PROJECTS' },
    children: [
      {
        path: '',
        component: ProjectsListComponent,
        canActivate: [AuthGuardService],
        data: { roles: ['User'], routeId: RouteId.PROJECTS, breadcrumbLabelKey: 'NAVIGATION.LABELS.PROJECTS' },
      },
      {
        path: 'view-report',
        component: ViewReportComponent,
        canActivate: [AuthGuardService],
        data: { roles: ['User'], routeId: RouteId.PROJECTS },
        resolve: { project: ProjectResolver },
      },
      {
        path: 'new',
        component: CreateProjectComponent,
        canActivate: [AuthGuardService],
        data: { roles: ['User'], mode: 'new' },
        resolve: { project: CreateProjectResolver },
        children: childrenProjectRoutes,
      },
      {
        path: 'edit',
        component: CreateProjectComponent,
        canActivate: [AuthGuardService],
        data: { roles: ['User'], mode: 'edit' },
        resolve: { project: CreateProjectResolver },
        children: childrenProjectRoutes,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(projectRoutes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule { }
