import { OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
var AddSupplierDialogComponent = /** @class */ (function () {
    function AddSupplierDialogComponent(data) {
        this.data = data;
        this.validUrlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    }
    AddSupplierDialogComponent.prototype.ngOnInit = function () {
        this.supplier = new FormGroup({
            supplierName: new FormControl('', [Validators.required, this.supplierNameValidator.bind(this)]),
            panelistId: new FormControl('', Validators.required),
            completeUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
            terminateUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
            overQuotaUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
            securityTerminateUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
        });
    };
    AddSupplierDialogComponent.prototype.supplierNameValidator = function (_a) {
        var value = _a.value;
        var isSupplier = this.data.suppliers.filter(function (supplier) {
            return (supplier.name === value);
        });
        if (isSupplier.length !== 0) {
            return {
                supplierNameExists: true,
            };
        }
        return null;
    };
    return AddSupplierDialogComponent;
}());
export { AddSupplierDialogComponent };
