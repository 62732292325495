import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, router, location) {
        this.authService = authService;
        this.router = router;
        this.location = location;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        var acceptedRoles = route.data.roles || [];
        var isErrorPage = route.component['name'] === 'PageNotFoundComponent';
        if (!this.authService.isLoggedIn()) {
            if (localStorage.getItem("refresh_token" /* refreshToken */) === null) {
                this.authService.clearAuthInfo();
                if (isErrorPage) {
                    this.router.navigate(['/404'], { skipLocationChange: true });
                    // Keep the wrong url in browser url.
                    this.location.replaceState(state.url);
                }
                else {
                    this.router.navigate(['/login']);
                }
                return of(false);
            }
            return new Observable(function (requestObserver) {
                _this.authService.refreshToken().subscribe(function (result) {
                    return requestObserver.next(_this.checkRole(acceptedRoles));
                }, function (error) {
                    _this.authService.clearAuthInfo();
                    _this.router.navigate(['/login']);
                    return requestObserver.next(false);
                });
            });
        }
        return of(this.checkRole(acceptedRoles));
    };
    AuthGuardService.prototype.checkRole = function (acceptedRoles) {
        var role = localStorage.getItem("auth_role" /* role */);
        if (!role || !acceptedRoles.includes(role)) {
            this.router.navigate(['/dashboard']);
            return false;
        }
        return true;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
