import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Outcome } from '../../../../model/project/project';
var QuestionAnswersComponent = /** @class */ (function () {
    function QuestionAnswersComponent() {
        this.projectQuestionRemove = new EventEmitter();
    }
    Object.defineProperty(QuestionAnswersComponent.prototype, "text", {
        get: function () { return this.formGroup.get('text'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionAnswersComponent.prototype, "answersFormArray", {
        get: function () {
            return this.formGroup.get('answers');
        },
        enumerable: true,
        configurable: true
    });
    QuestionAnswersComponent.prototype.getAnswerForm = function (index) {
        return this.answersFormArray.at(index);
    };
    QuestionAnswersComponent.prototype.isAnswerFormValid = function () {
        return this.answersFormArray.length > 1 &&
            !this.answersFormArray.controls.some(function (answerFormGroup) {
                var outcomeCtrl = answerFormGroup.controls['outcome'];
                var textCtrl = answerFormGroup.controls['text'];
                if (textCtrl.touched && (!textCtrl.valid || !outcomeCtrl.valid && !outcomeCtrl.touched)) {
                    return true;
                }
                if (outcomeCtrl.touched && (!outcomeCtrl.valid || !textCtrl.valid && !textCtrl.touched)) {
                    return true;
                }
                return false;
            }) &&
            this.answersFormArray.controls.some(function (answerFormGroup) {
                return answerFormGroup.controls['outcome'].value === Outcome.Accept;
            });
    };
    QuestionAnswersComponent.prototype.createAnswerTextFormGroup = function (i18nText) {
        return new FormGroup({
            _id: new FormControl(''),
            langCode: new FormControl(i18nText.langCode),
            text: new FormControl('', Validators.required),
        });
    };
    QuestionAnswersComponent.prototype.addAnswer = function () {
        var e_1, _a;
        var _this = this;
        var textList = [];
        try {
            for (var _b = tslib_1.__values(this.projectLanguages), _c = _b.next(); !_c.done; _c = _b.next()) {
                var language = _c.value;
                textList.push({ langCode: language.langCode.toLowerCase(), text: '' });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.answersFormArray.push(new FormGroup({
            _id: new FormControl(''),
            text: new FormArray(textList.map(function (i18nText) {
                return _this.createAnswerTextFormGroup(i18nText);
            })),
            outcome: new FormControl('', Validators.required),
            quota: new FormControl('', Validators.required),
        }));
    };
    QuestionAnswersComponent.prototype.removeQuestion = function () {
        this.projectQuestionRemove.emit(this.index);
    };
    QuestionAnswersComponent.prototype.onAnswerRemove = function (answerIndex) {
        this.answersFormArray.removeAt(answerIndex);
    };
    return QuestionAnswersComponent;
}());
export { QuestionAnswersComponent };
