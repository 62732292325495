import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/model/project/project';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { SecurityOptionsService } from 'src/app/services/security-options.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityOption } from 'src/app/model/project/options';
import { CurrencyTranslation } from 'src/app/model/project/project-pricing-dropdown';

@Component({
  selector: 'app-project-pricing-dropdown',
  templateUrl: './project-pricing-dropdown.component.html',
  styleUrls: ['./project-pricing-dropdown.component.scss'],
})
export class ProjectPricingDropdownComponent implements OnInit {
  protected project: Project;

  public numberOfPanelistsRequired: number;

  public total: CurrencyTranslation;
  public baseCost: CurrencyTranslation;

  public securityOptionsDetails: SecurityOption[];
  public securityOptions: SecurityOption[];
  public defaultBaseRate: number;

  constructor(private projectDataService: ProjectDataService,
              private securityOptionsService: SecurityOptionsService,
              private translate: TranslateService) {

    this.defaultBaseRate = 2;
    this.project = this.projectDataService.projectData;
    this.total = { value: (this.project.expectedProjectCost / 100) || 0 };
    this.baseCost = { value: this.project.numberOfPanelistsRequired * this.defaultBaseRate / 100 };

    this.numberOfPanelistsRequired = this.project.numberOfPanelistsRequired;

    this.projectDataService.receivedProject.subscribe((project: Project) => {
      this.project = project;
      this.total = { value: this.project.expectedProjectCost / 100 };
      this.baseCost = { value: this.project.numberOfPanelistsRequired * this.defaultBaseRate / 100 };

      this.numberOfPanelistsRequired = this.project.numberOfPanelistsRequired;
      this.securityOptions = this.formatSecurityOptions(this.project.securityOptions, this.securityOptionsDetails);
    });
  }

  ngOnInit() {
    if (!this.securityOptionsDetails) {
      this.securityOptionsService.getAll().subscribe((options) => {
        this.securityOptionsDetails = options;
        this.securityOptions = this.formatSecurityOptions(this.project.securityOptions, options);
      });
    }
  }

  formatSecurityOptions(options, details) {
    return options.map((option, idx) => {
      const rate =  details[idx].rate ? details[idx].rate : 0;
      return {
        ...option,
        rate,
        name: this.translate.instant(`SECURITY_OPTIONS.${details[idx].name}.name`),
        value: rate * this.numberOfPanelistsRequired / 100,
      };
    });
  }
}
