<div class="project-pricing-dropdown">
  <button mat-button [matMenuTriggerFor]="menu" #ref="matMenuTrigger">
    {{ 'PROJECT_PRICING.TOTAL' | translate }}: {{ 'CURRENCY' | translate:total }}
    <mat-icon *ngIf="!ref.menuOpen" aria-hidden="false">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="ref.menuOpen" aria-hidden="false">keyboard_arrow_up</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before" class="menu">
    <div class="pricing-menu">
      <div class="pricing-section" *ngIf="numberOfPanelistsRequired">
        <div class="pricing-item">
          <span>
            {{ 'PROJECT_PRICING.BASE_COST' | translate }}
          </span>
          <span>
            {{ 'CURRENCY' | translate:baseCost}}
          </span>
        </div>
        <ng-container *ngIf="securityOptions">
          <ng-container *ngFor="let option of securityOptions">
            <div *ngIf="option.enabled && option.value" class="pricing-item">
              <span>
                {{option.name}}
              </span>
              <span>
                {{ 'CURRENCY' | translate:option}}
              </span>
            </div>
          </ng-container>
        </ng-container>
        <mat-divider></mat-divider>
      </div>

      <div class="pricing-section">
        <div class="pricing-item">
          <span>
            <b>
              {{ 'PROJECT_PRICING.CURRENT_TOTAL' | translate }}:
            </b>
          </span>
          <span>
            <b>
              {{ 'CURRENCY' | translate:total }}
            </b>
          </span>
        </div>
        <div class="pricing-item pricing-item--description">
          {{ 'PROJECT_PRICING.PRICING_DESCRIPTION' | translate }}
        </div>
      </div>
    </div>
  </mat-menu>
</div>
