import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BillingService = /** @class */ (function () {
    function BillingService(http) {
        this.http = http;
    }
    BillingService.prototype.getBillingInfo = function () {
        var _this = this;
        return this.http.get(environment.api_url + "/v1/web/billing", HttpUtils.getJsonContentTypeHeader())
            .pipe(map(function (response) {
            _this.groupedBillingInfo = response.payload.billingInfo;
            return response.payload.billingInfo;
        }), catchError(function (error) { return throwError(error); }));
    };
    BillingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BillingService_Factory() { return new BillingService(i0.ɵɵinject(i1.HttpClient)); }, token: BillingService, providedIn: "root" });
    return BillingService;
}());
export { BillingService };
