import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var DirtyFormService = /** @class */ (function () {
    function DirtyFormService() {
        this.receivedFilter = new EventEmitter();
    }
    DirtyFormService.prototype.isDirty = function () {
        this.receivedFilter.emit(true);
    };
    DirtyFormService.prototype.isValid = function () {
        this.receivedFilter.emit(false);
    };
    DirtyFormService.prototype.reset = function () {
        this.receivedFilter.emit(false);
    };
    DirtyFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DirtyFormService_Factory() { return new DirtyFormService(); }, token: DirtyFormService, providedIn: "root" });
    return DirtyFormService;
}());
export { DirtyFormService };
