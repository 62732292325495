import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface JsonHttpHeaders {
  headers: HttpHeaders;
  params?: HttpParams;
}

// tslint:disable-next-line:variable-name
export const HttpUtils = {
  getJsonContentTypeHeader(): JsonHttpHeaders {
    const headers = new HttpHeaders();

    headers.set('Content-Type', 'application/json; charset=utf-8');

    return { headers };
  },
};
