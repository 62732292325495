import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

interface DialogData {
  allLanguages: { langCode: string, label: string }[];
  projectForm: FormGroup;
  surveyUrlFormatValidator: ValidatorFn;
  surveyUrlParamsValidator: ValidatorFn;
}

@Component({
  selector: 'app-languages-dialog',
  templateUrl: './languages-dialog.component.html',
  styleUrls: ['./languages-dialog.component.scss'],
})
export class LanguagesDialogComponent implements OnInit {

  languages: { label: string, langCode: string, selected: boolean }[];

  constructor(private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    // Shallow copy of the array to avoid changing the main data.
    const currentAllLanguages = [...this.data.allLanguages];

    for (const language of currentAllLanguages) {
      language.label = this.translate.instant(`LANGUAGES.${language.langCode}`);
    }

    currentAllLanguages.sort((a, b) => a.label > b.label ? 1 : -1);

    this.languages = currentAllLanguages.map((lang) => {
      return {
        langCode: lang.langCode,
        selected: this.checkIfExists(lang.langCode),
        label: lang.label,
      };
    });
  }

  get languagesArray() {
    return this.data.projectForm.get('languages') as FormArray;
  }

  updateFormControls() {
    this.languages.forEach((language) => {
      const idx = this.languagesArray.value.findIndex(lang => lang.langCode === language.langCode);
      if (language.selected) {
        if (idx === -1) {
          // if language is selected and not in the form, add it
          this.languagesArray.push(new FormGroup({
            langCode: new FormControl(language.langCode, Validators.required),
            surveyUrl: new FormControl('', [
              Validators.required,
              this.data.surveyUrlFormatValidator,
              this.data.surveyUrlParamsValidator,
            ]),
            finalUrl: new FormControl(''),
            completeUrl: new FormControl(''),
          }));
        }
      } else {
        // if the language is not selected but is in the form, remove it
        if (idx > -1) this.languagesArray.removeAt(idx);
      }
    });
  }

  // Check the checkbox in dialog if the value language already selected.
  private checkIfExists(langCode: string) {
    return this.languagesArray.value.findIndex(langItem => langItem.langCode === langCode) !== -1;
  }
}
