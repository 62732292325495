import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nText, Outcome } from '../../../../model/project/project';
import { Languages } from '../../../../model/Common';

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.scss'],
})
export class QuestionAnswersComponent {

  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() currentLangCode: string;
  @Input() projectLanguages: Languages[];
  @Input() isEditModeDisabled: boolean;
  @Output() projectQuestionRemove = new EventEmitter<number>();

  constructor() { }

  get text(): FormArray { return this.formGroup.get('text') as FormArray; }

  get answersFormArray() {
    return this.formGroup.get('answers') as FormArray;
  }

  getAnswerForm(index: number) {
    return <FormGroup>this.answersFormArray.at(index);
  }

  isAnswerFormValid(): boolean {
    return this.answersFormArray.length > 1 &&
      !this.answersFormArray.controls.some((answerFormGroup) => {
        const outcomeCtrl = (<FormGroup>answerFormGroup).controls['outcome'];
        const textCtrl = (<FormGroup>answerFormGroup).controls['text'];

        if (textCtrl.touched && (!textCtrl.valid || !outcomeCtrl.valid && !outcomeCtrl.touched)) {
          return true;
        }

        if (outcomeCtrl.touched && (!outcomeCtrl.valid || !textCtrl.valid && !textCtrl.touched)) {
          return true;
        }

        return false;
      }) &&
      this.answersFormArray.controls.some((answerFormGroup) => {
        return (<FormGroup>answerFormGroup).controls['outcome'].value === Outcome.Accept;
      });
  }

  private createAnswerTextFormGroup(i18nText: I18nText) {
    return new FormGroup({
      _id: new FormControl(''),
      langCode: new FormControl(i18nText.langCode),
      text: new FormControl('', Validators.required),
    });
  }

  addAnswer() {
    const textList = [];

    for (const language of this.projectLanguages) {
      textList.push({ langCode: language.langCode.toLowerCase(), text: '' });
    }

    this.answersFormArray.push(new FormGroup({
      _id: new FormControl(''),
      text: new FormArray(
        textList.map<FormGroup>((i18nText) => {
          return this.createAnswerTextFormGroup(i18nText);
        })),
      outcome: new FormControl('', Validators.required),
      quota: new FormControl('', Validators.required),
    }));
  }

  removeQuestion() {
    this.projectQuestionRemove.emit(this.index);
  }

  onAnswerRemove(answerIndex: number) {
    this.answersFormArray.removeAt(answerIndex);
  }
}
