import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import * as ProjectsAction from '../actionstypes/projects/projects.actiontype';
import { RequestStatusAction } from '../actionstypes/projects/request-status.actiontype';
import * as RequestStatus from '../actionstypes/request-status/request-status.actiontype';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
var ProjectsEffect = /** @class */ (function () {
    function ProjectsEffect(actions$, projects) {
        var _this = this;
        this.actions$ = actions$;
        this.projects = projects;
        this.loadProjects$ = this.actions$.pipe(ofType(ProjectsAction.ProjectsAction.Load), exhaustMap(function (action) { return _this.projects.getProjects(action.query).pipe(map(function (projectsPayload) { return (new ProjectsAction.Get(projectsPayload.payload.projects)); }), catchError(function () { return of(new RequestStatus.RequestStatus((RequestStatusAction.Error))); })); }));
        this.requestProjects$ = this.actions$.pipe(ofType(ProjectsAction.ProjectsAction.Get), mergeMap(function () { return of(new RequestStatus.RequestStatus(RequestStatusAction.Success)); }));
        this.resetProjects$ = this.actions$.pipe(ofType(ProjectsAction.ProjectsAction.Reset), mergeMap(function () { return of(new RequestStatus.RequestStatus(RequestStatusAction.Load)); }));
    }
    tslib_1.__decorate([
        Effect()
    ], ProjectsEffect.prototype, "loadProjects$", void 0);
    tslib_1.__decorate([
        Effect()
    ], ProjectsEffect.prototype, "requestProjects$", void 0);
    tslib_1.__decorate([
        Effect()
    ], ProjectsEffect.prototype, "resetProjects$", void 0);
    return ProjectsEffect;
}());
export { ProjectsEffect };
