import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
var CsvService = /** @class */ (function () {
    function CsvService(http, translate) {
        this.http = http;
        this.translate = translate;
    }
    CsvService.prototype.getCsv = function (projectId) {
        var options = HttpUtils.getJsonContentTypeHeader();
        var params = new HttpParams();
        params = params.set('lang', this.translate.currentLang);
        options['params'] = params;
        return this.http.get(environment.api_url + "/v1/web/project/report/" + projectId, options);
    };
    CsvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsvService_Factory() { return new CsvService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); }, token: CsvService, providedIn: "root" });
    return CsvService;
}());
export { CsvService };
