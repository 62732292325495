import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { LanguagesDialogComponent } from '../languages-dialog/languages-dialog.component';
import { LanguagesWarningDialogComponent } from '../languages-warning-dialog/languages-warning-dialog.component';
import { StatusWarningDialogComponent } from '../status-warning-dialog/status-warning-dialog.component';
import { StatusNotifyDialogComponent } from '../status-notify-dialog/status-notify-dialog.component';
import { ALL_LANGUAGES } from 'src/app/model/project/languages';
import { Audience } from 'src/app/model/project/project';
import { STATUS_OPTIONS, PROJECT_TYPE_OPTIONS, PROJECT_DURATION_OPTIONS, SURVEY_DURATION_OPTIONS, } from 'src/app/model/project/options';
import { ProjectStepComponent } from '../project-step-component';
import { Observable } from 'rxjs';
var ProjectProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProjectProfileComponent, _super);
    function ProjectProfileComponent(snackBarService, route, projectService, projectDataService, router, dialog, fileUploadService, accountService, translate, dirtyFormService) {
        var _this = _super.call(this) || this;
        _this.snackBarService = snackBarService;
        _this.route = route;
        _this.projectService = projectService;
        _this.projectDataService = projectDataService;
        _this.router = router;
        _this.dialog = dialog;
        _this.fileUploadService = fileUploadService;
        _this.accountService = accountService;
        _this.translate = translate;
        _this.dirtyFormService = dirtyFormService;
        _this.imageSrc = '';
        _this.formSubmitted = false;
        _this.PROJECT_TYPE_OPTIONS = PROJECT_TYPE_OPTIONS;
        _this.PROJECT_DURATION_OPTIONS = PROJECT_DURATION_OPTIONS;
        _this.SURVEY_DURATION_OPTIONS = SURVEY_DURATION_OPTIONS;
        _this.AUDIENCE_OPTIONS = Object.values(Audience);
        _this.allLanguages = ALL_LANGUAGES;
        _this.todayDate = new Date();
        _this.unitLabels = null;
        _this.isQuestionDeleted = false;
        _this.isDefaultStatusOption = false;
        _this.defaultDialogWithSize = '550px';
        _this.statusOptions = STATUS_OPTIONS;
        if (_this.route.snapshot.data.project instanceof Observable) {
            _this.route.snapshot.data.project.subscribe(function (project) {
                _this.project = project;
            });
        }
        _this.translate.get('PROJECT_PROFILE.UNIT').subscribe(function (translations) {
            _this.unitLabels = translations;
        });
        return _this;
    }
    ProjectProfileComponent.prototype.ngOnInit = function () {
        /**
         * If the user reload the page, validate if the `id` of the project is
         * in parameter before call the API endpoint to get the current project data.
         * Also, this means that the `this.project._id` data is not available.
         */
        this.setProjectProfileFormData();
    };
    ProjectProfileComponent.prototype.ngOnDestroy = function () {
        if (this.saveProjectHttpSubscriber) {
            this.saveProjectHttpSubscriber.unsubscribe();
        }
    };
    ProjectProfileComponent.prototype.createLanguagesFormGroup = function (lang) {
        return new FormGroup({
            langCode: new FormControl(lang.langCode, Validators.required),
            surveyUrl: new FormControl(lang.surveyUrl, [
                Validators.required,
                this.surveyUrlFormatValidator(),
                this.surveyUrlParamsValidator(),
            ]),
            finalUrl: new FormControl(lang.finalUrl),
            completeUrl: new FormControl(lang.completeUrl),
        });
    };
    ProjectProfileComponent.prototype.setProjectProfileFormData = function () {
        var _this = this;
        var duration = isNaN(parseInt(this.project.duration, 0)) ? null : parseInt(this.project.duration, 0);
        var surveyDuration = isNaN(parseInt(this.project.surveyDuration, 0)) ? null : parseInt(this.project.surveyDuration, 0);
        this.projectProfileForm = new FormGroup({
            title: new FormControl(this.project.title, [Validators.required, Validators.maxLength(128)]),
            client: new FormControl(this.project.client, Validators.required),
            status: new FormControl({ value: this.project.status, disabled: !this.project._id }, Validators.required),
            projectManager: new FormControl(this.project.projectManager, Validators.required),
            endDate: new FormControl(this.project.endDate, Validators.required),
            duration: new FormControl(duration, Validators.required),
            maxBudget: new FormControl(this.project.maxBudget, [Validators.min(1), Validators.required]),
            setRoom: new FormControl(this.project.setRoom, [Validators.min(0), Validators.required]),
            type: new FormControl(this.project.type, Validators.required),
            surveyDuration: new FormControl(surveyDuration, Validators.required),
            bidTarget: new FormControl(this.project.bidTarget, [Validators.min(1), Validators.required]),
            piiCompliance: new FormControl(this.project.piiCompliance),
            expectedIR: new FormControl(this.project.expectedIR, [Validators.min(0), Validators.max(100), Validators.required]),
            expectedDR: new FormControl(this.project.expectedDR, [Validators.min(0), Validators.max(100), Validators.required]),
            expectedOQ: new FormControl(this.project.expectedOQ, [Validators.min(0), Validators.max(100), Validators.required]),
            audience: new FormControl(this.project.audience, Validators.required),
            languages: new FormArray(this.project.languages.map(function (lang) {
                return _this.createLanguagesFormGroup(lang);
            }), [Validators.required]),
            logoKey: new FormControl(this.project.logoKey),
        });
        this.currentStepForm.form = this.projectProfileForm;
        this.getImageUrl();
        this.getProjectManagersList();
        this.getClientsList();
        this.isDefaultStatusOption = this.projectProfileForm.get('status').value === STATUS_OPTIONS.DEFAULT;
        this.previousSelectedStatusOption = this.projectProfileForm.get('status').value;
        this.dirtyFormService.reset();
        this.projectProfileForm.valueChanges.subscribe(function () { _this.dirtyFormService.isDirty(); });
    };
    ProjectProfileComponent.prototype.addProjectManager = function ($event) {
        var _this = this;
        if (this.projectManagersList.indexOf($event) === -1) {
            this.accountService.addProjectManager($event).subscribe(function (res) {
                _this.projectManagersList = tslib_1.__spread(_this.projectManagersList, [$event]);
            }, function (error) {
                _this.handleProjectManagerUpdateError(error);
            });
        }
    };
    ProjectProfileComponent.prototype.addClient = function ($event) {
        var _this = this;
        if (this.clientsList.indexOf($event) === -1) {
            this.clientsList = tslib_1.__spread(this.clientsList, [$event]);
            this.accountService.addClient($event).subscribe(function (res) { }, function (error) {
                _this.handleClientUpdateError(error);
            });
        }
    };
    ProjectProfileComponent.prototype.notifyStatusOptions = function () {
        var value = this.projectProfileForm.get('status').value;
        var isDefaultToNonDefault = this.isDefaultStatusOption && value !== STATUS_OPTIONS.DEFAULT;
        var isNonDefaultToDefault = !this.isDefaultStatusOption && value === STATUS_OPTIONS.DEFAULT;
        var isNonDefaultToInField = !this.isDefaultStatusOption && value === STATUS_OPTIONS.IN_FIELD;
        var isNonDefaultToComplete = !this.isDefaultStatusOption && value === STATUS_OPTIONS.COMPLETE;
        if (isNonDefaultToDefault || isDefaultToNonDefault || isNonDefaultToInField || isNonDefaultToComplete) {
            this.openNotifyStatusOptionsDialog();
        }
    };
    ProjectProfileComponent.prototype.getLangCodeAfterValidateStatusOption = function () {
        var e_1, _a;
        var langCodeWarning = [];
        if (this.projectProfileForm.get('status').value !== STATUS_OPTIONS.DEFAULT) {
            var formProjectLanguages = this.projectProfileForm.get('languages').value;
            var _loop_1 = function (language) {
                var questionLangCode = language.langCode;
                var isQuestionAvailable = false;
                if (this_1.project.questions
                    && this_1.project.questions.length
                    && this_1.project.questions[0].text
                    && this_1.project.questions[0].text.length) {
                    isQuestionAvailable = this_1.project.questions[0].text.some(function (text) {
                        return text.langCode === questionLangCode.toLowerCase() && text.text !== '';
                    });
                }
                if (!isQuestionAvailable) {
                    langCodeWarning.push(questionLangCode);
                }
            };
            var this_1 = this;
            try {
                for (var formProjectLanguages_1 = tslib_1.__values(formProjectLanguages), formProjectLanguages_1_1 = formProjectLanguages_1.next(); !formProjectLanguages_1_1.done; formProjectLanguages_1_1 = formProjectLanguages_1.next()) {
                    var language = formProjectLanguages_1_1.value;
                    _loop_1(language);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (formProjectLanguages_1_1 && !formProjectLanguages_1_1.done && (_a = formProjectLanguages_1.return)) _a.call(formProjectLanguages_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return langCodeWarning;
    };
    ProjectProfileComponent.prototype.validateStatusOption = function () {
        var langCodeWarning = this.getLangCodeAfterValidateStatusOption();
        if (langCodeWarning.length) {
            this.openStatusOptionsWarningDialog(langCodeWarning);
        }
        else {
            this.saveProjectProfile();
        }
    };
    ProjectProfileComponent.prototype.getLangCodeAfterValidateLanguages = function () {
        var e_2, _a;
        var langCodeWarning = [];
        if (this.project.questions
            && this.project.questions.length
            && this.project.questions[0].text
            && this.project.questions[0].text.length) {
            var _loop_2 = function (text) {
                if (text.text !== '') {
                    var questionLangCode_1 = text.langCode.toLowerCase();
                    var formProjectLanguages = this_2.projectProfileForm.get('languages').value;
                    var questionLanguageAvailable = formProjectLanguages.some(function (language) {
                        return language.langCode.toLowerCase() === questionLangCode_1;
                    });
                    if (!questionLanguageAvailable) {
                        langCodeWarning.push(questionLangCode_1);
                    }
                }
            };
            var this_2 = this;
            try {
                for (var _b = tslib_1.__values(this.project.questions[0].text), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var text = _c.value;
                    _loop_2(text);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return langCodeWarning;
    };
    ProjectProfileComponent.prototype.validateProjectProfile = function () {
        this.formSubmitted = true;
        var form = this.projectProfileForm;
        if (form.valid) {
            var langCodeWarning = this.getLangCodeAfterValidateLanguages();
            if (langCodeWarning.length) {
                this.openLanguagesWarningDialog(langCodeWarning);
            }
            else {
                this.validateStatusOption();
            }
        }
    };
    ProjectProfileComponent.prototype.saveProjectProfile = function () {
        this.dirtyFormService.isValid();
        var projectToSave = tslib_1.__assign({}, this.getFormValues(this.projectProfileForm));
        if (this.isQuestionDeleted) {
            projectToSave.questions = this.project.questions;
        }
        if (!projectToSave._id) {
            this.createProjectProfile(projectToSave);
        }
        else {
            this.updateProjectProfile(projectToSave);
        }
    };
    ProjectProfileComponent.prototype.createProjectProfile = function (projectToSave) {
        var _this = this;
        this.saveProjectHttpSubscriber = this.projectService.createProject(projectToSave).subscribe(function (projectCreated) {
            _this.setProjectProfileProperties(_this.projectDataService.projectData, projectCreated.payload.project);
            _this.handleCreateSuccessMessage();
            _this.saveNewLogoUrl();
            _this.currentStepForm.submitted = true;
            _this.router.navigate(["/projects/" + _this.projectService.editionMode + "/suppliers"], {
                queryParams: { id: _this.projectDataService.projectData._id },
                queryParamsHandling: 'merge',
            });
        }, function (error) {
            _this.handleError(error);
        });
    };
    ProjectProfileComponent.prototype.updateProjectProfile = function (projectToSave) {
        var _this = this;
        var projectUpdate = tslib_1.__assign({ _id: projectToSave._id }, projectToSave);
        this.saveProjectHttpSubscriber = this.projectService.updateProject(projectUpdate).subscribe(function (projectUpdated) {
            _this.setProjectProfileProperties(_this.projectDataService.projectData, projectUpdated.payload.project);
            _this.handleUpdateSuccessMessage();
            _this.saveNewLogoUrl();
            _this.currentStepForm.submitted = true;
            _this.router.navigate(["/projects/" + _this.projectService.editionMode + "/suppliers"], {
                queryParams: { id: _this.projectDataService.projectData._id },
                queryParamsHandling: 'merge',
            });
        }, function (error) {
            _this.handleError(error);
        });
    };
    ProjectProfileComponent.prototype.getDescriptionCopy = function (prev, next) {
        if (prev !== STATUS_OPTIONS.DEFAULT && next === STATUS_OPTIONS.DEFAULT) {
            return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_DRAFT');
        }
        if (prev === STATUS_OPTIONS.DEFAULT && next !== STATUS_OPTIONS.DEFAULT) {
            return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_OTHERS');
        }
        if (prev === STATUS_OPTIONS.COMPLETE && next !== STATUS_OPTIONS.COMPLETE) {
            return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_COMPLETE_TO_IN_FIELD');
        }
        return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_COMPLETE');
    };
    ProjectProfileComponent.prototype.openNotifyStatusOptionsDialog = function () {
        var _this = this;
        var value = this.projectProfileForm.get('status').value;
        var isInFieldToDefault = this.previousSelectedStatusOption === STATUS_OPTIONS.COMPLETE && value === STATUS_OPTIONS.IN_FIELD;
        // Disallowed actions are actions that are changing status from Complete to anything else,
        // or from In Field to Default (Draft)
        var isDisallowed = (this.previousSelectedStatusOption === STATUS_OPTIONS.COMPLETE && value !== STATUS_OPTIONS.COMPLETE)
            || (this.previousSelectedStatusOption === STATUS_OPTIONS.IN_FIELD && value === STATUS_OPTIONS.DEFAULT);
        var descriptionCopy = this.getDescriptionCopy(this.previousSelectedStatusOption, value);
        var dialogRef = this.dialog.open(StatusNotifyDialogComponent, {
            width: this.defaultDialogWithSize,
            data: {
                isDisallowed: isDisallowed,
                descriptionCopy: descriptionCopy,
            },
            restoreFocus: true,
        });
        dialogRef.afterClosed().subscribe(function (isConfirm) {
            if (isConfirm) {
                _this.projectProfileForm.patchValue({ status: value });
            }
            else {
                _this.projectProfileForm.patchValue({ status: _this.previousSelectedStatusOption });
            }
        });
    };
    ProjectProfileComponent.prototype.openStatusOptionsWarningDialog = function (langCodes) {
        var _this = this;
        var dialogRef = this.dialog.open(StatusWarningDialogComponent, {
            width: this.defaultDialogWithSize,
            data: {
                langCodes: langCodes,
            },
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe(function () {
            _this.projectProfileForm.patchValue({ status: STATUS_OPTIONS.DEFAULT });
            _this.saveProjectProfile();
        });
    };
    ProjectProfileComponent.prototype.openLanguagesWarningDialog = function (langCodes) {
        var _this = this;
        var dialogRef = this.dialog.open(LanguagesWarningDialogComponent, {
            width: this.defaultDialogWithSize,
            data: {
                langCodes: langCodes,
            },
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe(function (toDelete) {
            var e_3, _a;
            _this.isQuestionDeleted = toDelete;
            if (toDelete) {
                // It's important to keep the deleteQuestions() before the validateStatusOption() because
                // the status warning message should be displayed if an Q&A is not available in all languages.
                _this.project.questions = _this.projectService.deleteQuestions(_this.project.questions, langCodes);
                _this.validateStatusOption();
            }
            else {
                try {
                    for (var _b = tslib_1.__values(_this.project.languages), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var language = _c.value;
                        if (langCodes.indexOf(language.langCode.toLowerCase()) !== -1) {
                            _this.languagesArray.push(_this.createLanguagesFormGroup(language));
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        });
    };
    ProjectProfileComponent.prototype.openLanguagesDialog = function () {
        this.dialog.open(LanguagesDialogComponent, {
            width: this.defaultDialogWithSize,
            data: {
                allLanguages: this.allLanguages,
                projectForm: this.projectProfileForm,
                surveyUrlFormatValidator: this.surveyUrlFormatValidator(),
                surveyUrlParamsValidator: this.surveyUrlParamsValidator(),
            },
            restoreFocus: false,
        });
    };
    // Function executed from the ImageUploaderComponent to store data.
    ProjectProfileComponent.prototype.storeNewLogoUrl = function (logoUrl) {
        this.newLogoUrl = logoUrl;
    };
    // Function executed from the ImageUploaderComponent to delete data.
    ProjectProfileComponent.prototype.deleteNewLogoUrl = function () {
        this.newLogoUrl = null;
    };
    // Function executed to cache the new logo data.
    ProjectProfileComponent.prototype.saveNewLogoUrl = function () {
        var logoKey = this.projectProfileForm.get('logoKey').value;
        if (this.newLogoUrl) {
            this.projectService.storeCurrentProjectLogoUrl(this.projectProfileForm.get('logoKey').value, this.newLogoUrl);
        }
        else if (!logoKey) {
            this.projectService.deleteCurrentProjectLogoUrl();
        }
    };
    ProjectProfileComponent.prototype.getImageUrl = function () {
        var _this = this;
        var key = this.projectProfileForm.get('logoKey').value;
        var logoUrl = this.projectService.getCurrentProjectLogoUrl(key);
        if (key !== '' && !logoUrl) {
            return this.fileUploadService.getImageUrl(key).subscribe(function (res) {
                _this.imageSrc = res.payload.url;
                // Store the logo url to prevent another call to service
                _this.projectService.storeCurrentProjectLogoUrl(key, _this.imageSrc);
            }, function (error) {
                _this.handleError(error);
            });
        }
        this.imageSrc = logoUrl;
    };
    ProjectProfileComponent.prototype.getProjectManagersList = function () {
        var _this = this;
        this.accountService.getProjectManagers().subscribe(function (res) {
            _this.projectManagersList = res.payload.projectManagers
                .map(function (projectManager) { return projectManager.fullName; });
        }, function (error) {
            _this.handleProjectManagersError(error);
        });
    };
    ProjectProfileComponent.prototype.getClientsList = function () {
        var _this = this;
        this.accountService.getClients().subscribe(function (res) {
            _this.clientsList = res.payload.clients.map(function (client) { return client.fullName; });
        }, function (error) {
            _this.handleClientsError(error);
        });
    };
    Object.defineProperty(ProjectProfileComponent.prototype, "languagesArray", {
        get: function () {
            return this.projectProfileForm.get('languages');
        },
        enumerable: true,
        configurable: true
    });
    ProjectProfileComponent.prototype.removeLanguage = function (language) {
        var toRemove = language.value;
        var indexOfFormControls = this.languagesArray.value
            .findIndex(function (langItem) { return langItem.langCode === toRemove.langCode; });
        if (indexOfFormControls !== -1) {
            this.languagesArray.removeAt(indexOfFormControls);
        }
    };
    ProjectProfileComponent.prototype.getFormValues = function (projectForm) {
        return {
            _id: this.project._id,
            title: projectForm.get('title').value,
            client: projectForm.get('client').value,
            status: projectForm.get('status').value,
            projectManager: projectForm.get('projectManager').value,
            endDate: projectForm.get('endDate').value,
            duration: projectForm.get('duration').value,
            maxBudget: projectForm.get('maxBudget').value,
            setRoom: projectForm.get('setRoom').value,
            type: projectForm.get('type').value,
            surveyDuration: projectForm.get('surveyDuration').value,
            bidTarget: projectForm.get('bidTarget').value,
            piiCompliance: projectForm.get('piiCompliance').value,
            languages: projectForm.get('languages').value,
            logoKey: projectForm.get('logoKey').value,
            expectedIR: projectForm.get('expectedIR').value,
            expectedDR: projectForm.get('expectedDR').value,
            expectedOQ: projectForm.get('expectedOQ').value,
            audience: projectForm.get('audience').value,
        };
    };
    ProjectProfileComponent.prototype.setProjectProfileProperties = function (currentProject, savedProject) {
        currentProject._id = savedProject._id;
        currentProject.title = savedProject.title;
        currentProject.client = savedProject.client;
        currentProject.status = savedProject.status;
        currentProject.projectManager = savedProject.projectManager;
        currentProject.endDate = savedProject.endDate;
        currentProject.duration = savedProject.duration;
        currentProject.maxBudget = savedProject.maxBudget;
        currentProject.setRoom = savedProject.setRoom;
        currentProject.type = savedProject.type;
        currentProject.surveyDuration = savedProject.surveyDuration;
        currentProject.bidTarget = savedProject.bidTarget;
        currentProject.piiCompliance = savedProject.piiCompliance;
        currentProject.languages = savedProject.languages;
        currentProject.logoKey = savedProject.logoKey;
        currentProject.expectedIR = savedProject.expectedIR;
        currentProject.expectedDR = savedProject.expectedDR;
        currentProject.expectedOQ = savedProject.expectedOQ;
        currentProject.audience = savedProject.audience;
        currentProject.numberOfPanelistsRequired = savedProject.numberOfPanelistsRequired;
        currentProject.projectIncidenceRate = savedProject.projectIncidenceRate;
        currentProject.securityOptions = savedProject.securityOptions;
        currentProject.expectedProjectCost = savedProject.expectedProjectCost;
    };
    // Success message for project creation
    ProjectProfileComponent.prototype.handleCreateSuccessMessage = function () {
        this.snackBarService.successWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_CREATED');
    };
    // Success message for project creation
    ProjectProfileComponent.prototype.handleUpdateSuccessMessage = function () {
        this.snackBarService.successWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_UPDATED');
    };
    // Generic error handler
    ProjectProfileComponent.prototype.handleError = function (error) {
        this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.REQUEST_FAILED');
    };
    // Generic error handler
    ProjectProfileComponent.prototype.handleProjectManagersError = function (error) {
        this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_MANAGERS_REQUEST_FAILED');
    };
    // Generic error handler
    ProjectProfileComponent.prototype.handleClientsError = function (error) {
        this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.CLIENTS_REQUEST_FAILED');
    };
    // Generic error handler
    ProjectProfileComponent.prototype.handleProjectManagerUpdateError = function (error) {
        this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_MANAGER_UPDATE_FAILED');
    };
    // Generic error handler
    ProjectProfileComponent.prototype.handleClientUpdateError = function (error) {
        this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.CLIENT_UPDATE_FAILED');
    };
    ProjectProfileComponent.prototype.getProjectDurationOptionLabel = function (option) {
        if (!this.unitLabels)
            return option;
        return option > 1 ? option + " " + this.unitLabels['DAYS'] : option + " " + this.unitLabels['DAY'];
    };
    ProjectProfileComponent.prototype.getSurveyDurationOptionLabel = function (option) {
        if (!this.unitLabels)
            return option;
        return option > 1 ? option + " " + this.unitLabels['MINUTES'] : option + " " + this.unitLabels['MINUTE'];
    };
    ProjectProfileComponent.prototype.surveyUrlParamsValidator = function () {
        return function (control) {
            var missingParam = !control.value.toLowerCase().includes('{id}');
            return missingParam ? { urlParams: missingParam } : null;
        };
    };
    ProjectProfileComponent.prototype.surveyUrlFormatValidator = function () {
        return function (control) {
            var URL_REGEXP = /^(http|https):\/\/(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
            var surveyUrl = control.value.toLowerCase().replace('{id}', 'validId');
            var invalidUrlString = surveyUrl.match(URL_REGEXP) === null;
            return invalidUrlString ? { urlFormat: invalidUrlString } : null;
        };
    };
    ProjectProfileComponent.prototype.surveyUrlCheckValidator = function (surveyUrl) {
        var errors = surveyUrl.errors;
        return errors ? !!Object.keys(errors).length && !surveyUrl.untouched : false;
    };
    return ProjectProfileComponent;
}(ProjectStepComponent));
export { ProjectProfileComponent };
