import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent {

  @Input() parentForm: FormGroup;
  @Input() formControlItemName: string;
  @Input() items: string[];
  @Input() translationParentKey;
  @Input() formSubmitted: boolean;

  @Output() itemAdded = new EventEmitter<string>();

  constructor(private translate: TranslateService, private dialog: MatDialog) {
  }

  searchItemsFn(term: string, item: string) {
    return item && item.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  openAddItemDialog(): void {
    this.translate.get(`${this.translationParentKey}.DIALOG_LABELS`).subscribe((translations) => {
      const dialogRef = this.dialog.open(InputDialogComponent, {
        width: '480px',
        data: {
          title: translations['TITLE'],
          description: translations['DESCRIPTION'],
          inputPlaceHolder: translations['INPUT_PLACEHOLDER'],
          cancelButton: translations['CANCEL_BUTTON'],
          confirmButton: translations['CONFIRM_BUTTON'],
        },
      });

      dialogRef.afterClosed().subscribe((newItem) => {
        if (newItem) {
          this.parentForm.controls[this.formControlItemName].setValue(newItem);
          this.itemAdded.emit(newItem);
        }
      });
    });
  }
}
