<app-preloader [loader]="updateProjectHttpSubscriber" [isFullScreen]="true">
<div class="quality-wrapper">
  <div class="step-header">
    <li> 4 <span class='spacer'>{{ 'SIDEBAR.LABELS.QUALITY' | translate }}</span> </li>
    <app-project-pricing-dropdown></app-project-pricing-dropdown>
  </div>
  <div class="quality-form">
    <div class="importance-notice">
      <p class="bold">{{ 'QUALITY.HEADINGS.TITLE' | translate }}</p>
      <p class="italic">{{ 'QUALITY.HEADINGS.DESCRIPTION' | translate }}</p>
    </div>
    <div class="importance-notice warning-notice"
         *ngIf="isEditModeDisabled">
      <p class="bold">{{ 'QUALITY.NOTICE.TITLE' | translate }}</p>
      <p class="italic">{{ 'QUALITY.NOTICE.DESCRIPTION' | translate }}</p>
    </div>
    <div fxLayout="row" class="actions mt-40">
      <mat-form-field>
        <mat-label>{{ 'QUALITY.LABELS.LANGUAGES' | translate }}</mat-label>
        <mat-select *ngIf="currentSortProjectLanguages.length > 1"
                    [(value)]="selectedLangCode"
        >
          <mat-option *ngFor="let language of currentSortProjectLanguages"
                      [value]="language.langCode.toLowerCase()"
          >
            {{ language.label }}
          </mat-option>
        </mat-select>
        <input matInput
               *ngIf="currentSortProjectLanguages.length === 1"
               value="{{ currentSortProjectLanguages[0].label }}"
               readonly
        >
      </mat-form-field>
      <!-- <button mat-raised-button class="width-200">
        {{ 'QUALITY.LABELS.PREVIEW_SURVEY' | translate }}
      </button> -->
      <button mat-raised-button class="width-200 light-blue"
              (click)="save()"
              [disabled]="!questionsForm.valid"
              *ngIf="questionsForm && !isEditModeDisabled"
      >
        {{ 'QUALITY.LABELS.SAVE_BUTTON' | translate }}
      </button>
    </div>
    <div>
      <p class="bold question-label">{{ 'QUALITY.LABELS.QUESTIONS' | translate }}</p>
      <form class="form-container" fxLayout="row"
            [formGroup]="questionsForm"
            *ngIf="questionsForm"
      >
        <div fxFlex="40" class="questions-container" formArrayName="questions">
          <div class="questions" *ngFor="let question of questionsFormArray.controls; let questionIndex=index;">
            <app-question (projectQuestionSelect)="onQuestionSelected($event)"
                          [formGroup]="getQuestionForm(questionIndex)"
                          [index]="questionIndex"
                          [selected]="questionIndex==selectedIndex"
                          [currentLangCode]="selectedLangCode"
                          id="{{ 'question-' + questionIndex }}">
            </app-question>
          </div>
          <button mat-raised-button
                  type="button"
                  (click)="addQuestion(); focus();"
                  class="add-question"
                  *ngIf="!isEditModeDisabled"
          >
            {{ questionsFormArray.length >= 3 ? ('QUESTION.LABELS.ADD_QUESTION_MORE_THAN_3' | translate:questionPriceTranslateParam)
                : ('QUESTION.LABELS.ADD_QUESTION' | translate) }}
          </button>
        </div>
        <div fxFlex class="right-container">
          <div class="question-answers-container" [style.margin-top.px]="getQuestionAnswersOffset()">
            <app-question-answers *ngIf="selectedIndex>-1"
                                  [formGroup]="getQuestionForm(selectedIndex)"
                                  [index]="selectedIndex"
                                  [currentLangCode]="selectedLangCode"
                                  [projectLanguages]="currentSortProjectLanguages"
                                  [isEditModeDisabled]="isEditModeDisabled"
                                  (projectQuestionRemove)="onQuestionRemove($event)">
            </app-question-answers>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</app-preloader>
