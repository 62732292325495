import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Project } from '../model/project/project';
import { ProjectService } from '../services/project.service';
import { ProjectDataService } from '../services/project-data.service';

@Injectable({ providedIn: 'root' })
export class ProjectResolver implements Resolve<Observable<string>> {

  protected currentProjectId: string;
  protected project: Project;

  constructor(private projectService: ProjectService,
              private route: ActivatedRoute,
              private projectDataService: ProjectDataService,
              private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.currentProjectId = route.queryParams['id'];
    this.project = this.projectDataService.projectData;

    /**
     * Be sure to clear previous data when creating a new project and
     * get the project default data.
     */
    if (!this.currentProjectId) {
      this.project = this.projectDataService.resetProjectData();
    }

    /**
     * In edit mode, if there is no `id` parameter in the url, the user will be redirected to projects page.
     */
    if (!this.projectService.isNewModeProject && !this.currentProjectId) {
      this.projectService.setErrorProjectState(true);
      this.router.navigate(['/projects']);

      return of(null);
    }

    /**
     * If the user reload the page, validate if the `id` of the project is
     * in parameter before call the API endpoint to get the current project data.
     * Also, this means that the `this.project._id` data is not available.
     */
    if (this.currentProjectId &&
      !this.project._id) {

      // Get specific project and fill the form data.
      return this.projectService.getProject(this.currentProjectId).pipe(
        map((res) => {
          if (!res['error']) {
            this.projectDataService.projectData = res.payload.project;
            this.project = this.projectDataService.projectData;

            return of(this.project);
          }
        }),
        catchError((error) => {
          this.projectService.setErrorProjectState(true);
          this.router.navigate(['/projects']);

          return of(null);
        }),
      );
    }

    /**
     * Send the empty project from the model data (ProjectDataService).
     *
     * We're sending back an observable to stay constant with the API call `getProject()`.
     */
    const projectObservable = new Observable((requestObserver:any) => {
      this.projectDataService.projectData = this.project;
      this.project = this.projectDataService.projectData;

      return requestObserver.next(this.project);
    });

    return of(projectObservable);
  }
}
