<div [formGroup]="parentForm">
  <div class="form-group mt-20" fxLayout="row" >
    <span class="upload-picture" *ngIf="!imageSrc" (click)="fileInput.click()">
      <mat-icon aria-hidden="false">person_add</mat-icon>
    </span>
    <img [src]="imageSrc" *ngIf="imageSrc" alt="logo" class="upload-picture" (click)="fileInput.click()">
    <span class="upload-button" *ngIf="parentForm && !parentForm.get(imageFormKey).value">
      <p aria-hidden="false" class="bold" (click)="fileInput.click()">
        {{ translationKey + '.UPLOAD' | translate }}</p>
      <p aria-hidden="false" class="f-16" (click)="fileInput.click()">
        {{ translationKey + '.UPLOAD_FROM' | translate }}</p>
    </span>

    <span class="remove-button" *ngIf="parentForm && parentForm.get(imageFormKey).value">
      <p aria-hidden="false" class="bold" (click)="removeImage()">
        {{ translationKey + '.REMOVE' | translate }}</p>
      <p aria-hidden="false" class="f-16" (click)="removeImage()">
        {{ translationKey + '.REMOVE_FROM' | translate }}</p>
    </span>

  </div>
  <input hidden  (change)="onFileSelected(fileInput.files)" accept='image/*' #fileInput type="file" id="file">
  <mat-form-field id="logoKeyField" style="display:none;">
    <input matInput type="text" formControlName="{{imageFormKey}}" />
  </mat-form-field>
</div>
