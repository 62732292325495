<div *ngIf="!isUserConnected(); then notConnectedBlock else connectedBlock"></div>
<ng-template #notConnectedBlock>
  <mat-toolbar color="accent">
    <mat-toolbar-row class="navigation-bar">
      <div fxFlex fxLayout fxLayoutAlign="center">
        <span class="font-awesome f-30">&nbsp;</span>
        <span class="f-28">{{ 'NAVIGATION.TITLE' | translate }} </span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-template>
<ng-template #connectedBlock>
  <mat-toolbar class="shadow-rectangle" color="primary">
    <mat-toolbar-row class="navigation-bar"
                     fxLayout="column"
                     fxLayout.gt-xs="row"
    >
      <div class="logo-wrapper"
           fxFlex.gt-xs="60"
           fxFlex.gt-sm="70"
           fxLayoutAlign="center center"
      >
        <div class="start-spacer" fxHide fxShow.gt-xs></div>
        <span class="font-awesome f-30 title">&nbsp;</span>
        <span class="f-arial f-28 title"> {{ 'NAVIGATION.TITLE' | translate }} </span>
        <span class="main-spacer"></span>
      </div>
      <div class="buttons-wrapper"
           fxFlex.gt-xs="40"
           fxFlex.gt-sm="30"
           fxLayoutAlign="center center"
           fxLayoutAlign.gt-xs="end center"
      >
        <div class="notification-container" fxLayoutAlign="center center" [ngClass]="{'div-selected': isCurrentNavigationItem('NOTIFICATIONS')}">
          <button mat-icon-button (click)="navigateTo('NOTIFICATIONS')">
            <mat-icon class="icon-grey">notifications_none</mat-icon>
          </button>
        </div>
        <div class="user-container" fxLayoutAlign="space-evenly center">
          <button mat-button [matMenuTriggerFor]="userMenu">
            <span *ngIf="!getLogoKey()" class="font-awesome f-16 oval icon-grey"></span>
            <img [src]="imageSrc" *ngIf="getLogoKey()" class="profile-picture">
            <span class="pl-20 f-16 bold f-arial">{{ getCompanyName() | truncate }}</span>
            <span class="font-awesome pl-10 f-16"></span>
          </button>
          <mat-menu #userMenu="matMenu">
            <a routerLink="/profile" mat-menu-item >
              <span>{{ 'NAVIGATION.LABELS.PROFILE' | translate}}</span>
            </a>
            <button mat-menu-item routerLink="/logout">
              <mat-icon class="icon-grey">exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="sub-navigation-bar" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="start start">
      <div class="start-spacer" fxHide fxShow.gt-xs></div>
      <div *ngFor="let item of ['DASHBOARD', 'PROJECTS', 'BILLING']" class="button-container" fxLayout="column" fxLayoutAlign="center center">
        <button mat-button (click)="navigateTo(item)" fxFlex>
          <span id="nav-{{item}}" [ngClass]="{'text-selected': isCurrentNavigationItem(item)}" class="f-16 button-label">{{ 'NAVIGATION.LABELS.' + item | translate }}</span>
        </button>
        <div [ngClass]="{'tab-selected': isCurrentNavigationItem(item)}" fxFlex="5"></div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-template>

