import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormArray, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { LanguagesDialogComponent } from '../languages-dialog/languages-dialog.component';
import { LanguagesWarningDialogComponent } from '../languages-warning-dialog/languages-warning-dialog.component';
import { StatusWarningDialogComponent } from '../status-warning-dialog/status-warning-dialog.component';
import { StatusNotifyDialogComponent } from '../status-notify-dialog/status-notify-dialog.component';
import { ALL_LANGUAGES } from 'src/app/model/project/languages';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { Project, ProjectUpdate, Audience } from 'src/app/model/project/project';
import { DirtyFormService } from 'src/app/services/dirty-form.service';
import {
  STATUS_OPTIONS,
  PROJECT_TYPE_OPTIONS,
  PROJECT_DURATION_OPTIONS,
  SURVEY_DURATION_OPTIONS,
} from 'src/app/model/project/options';
import { AccountService } from 'src/app/services/account.service';
import { ProjectStepComponent } from '../project-step-component';
import { TranslateService } from '@ngx-translate/core';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

type LogoUrl = string | null;

@Component({
  selector: 'app-project-profile',
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss'],
})
export class ProjectProfileComponent extends ProjectStepComponent  implements OnInit, OnDestroy {

  protected project: Project;

  public saveProjectHttpSubscriber: Subscription;
  public projectProfileForm: FormGroup;
  public imageSrc: string = '';
  public formSubmitted: boolean = false;

  public statusOptions: typeof STATUS_OPTIONS;
  public PROJECT_TYPE_OPTIONS: string[] = PROJECT_TYPE_OPTIONS;
  public PROJECT_DURATION_OPTIONS: number[] = PROJECT_DURATION_OPTIONS;
  public SURVEY_DURATION_OPTIONS: number[] = SURVEY_DURATION_OPTIONS;
  public AUDIENCE_OPTIONS: Audience[] = Object.values(Audience);

  public allLanguages: { langCode: string }[] = ALL_LANGUAGES;
  public projectManagersList: string[];
  public clientsList: string[];
  public todayDate: Date = new Date();
  public unitLabels: any = null;
  private previousSelectedStatusOption: string;
  private isQuestionDeleted: boolean = false;
  public isDefaultStatusOption: boolean = false;
  public defaultDialogWithSize: string = '550px';

  public newLogoUrl: LogoUrl;

  constructor(private snackBarService: SnackBarService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private projectDataService: ProjectDataService,
              private router: Router,
              private dialog: MatDialog,
              private fileUploadService: FileUploadService,
              private accountService: AccountService,
              private translate: TranslateService,
              private dirtyFormService: DirtyFormService) {
    super();

    this.statusOptions = STATUS_OPTIONS;
    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }

    this.translate.get('PROJECT_PROFILE.UNIT').subscribe((translations) => {
      this.unitLabels = translations;
    });
  }

  ngOnInit() {
    /**
     * If the user reload the page, validate if the `id` of the project is
     * in parameter before call the API endpoint to get the current project data.
     * Also, this means that the `this.project._id` data is not available.
     */
    this.setProjectProfileFormData();
  }

  ngOnDestroy(): void {
    if (this.saveProjectHttpSubscriber) {
      this.saveProjectHttpSubscriber.unsubscribe();
    }
  }

  private createLanguagesFormGroup(lang) {
    return new FormGroup({
      langCode: new FormControl(lang.langCode, Validators.required),
      surveyUrl: new FormControl(lang.surveyUrl, [
        Validators.required,
        this.surveyUrlFormatValidator(),
        this.surveyUrlParamsValidator(),
      ]),
      finalUrl: new FormControl(lang.finalUrl),
      completeUrl: new FormControl(lang.completeUrl),
    });
  }

  private setProjectProfileFormData() {
    const duration = isNaN(parseInt(this.project.duration, 0)) ? null : parseInt(this.project.duration, 0);
    const surveyDuration = isNaN(parseInt(this.project.surveyDuration, 0)) ? null : parseInt(this.project.surveyDuration, 0);
    this.projectProfileForm = new FormGroup({
      title: new FormControl(this.project.title, [Validators.required, Validators.maxLength(128)]),
      client: new FormControl(this.project.client, Validators.required),
      status: new FormControl({ value: this.project.status, disabled: !this.project._id }, Validators.required),
      projectManager: new FormControl(this.project.projectManager, Validators.required),
      endDate: new FormControl(this.project.endDate, Validators.required),
      duration: new FormControl(duration, Validators.required),
      maxBudget: new FormControl(this.project.maxBudget, [Validators.min(1), Validators.required]),
      setRoom: new FormControl(this.project.setRoom, [Validators.min(0), Validators.required]),
      type: new FormControl(this.project.type, Validators.required),
      surveyDuration: new FormControl(surveyDuration, Validators.required),
      bidTarget: new FormControl(this.project.bidTarget, [Validators.min(1), Validators.required]),
      piiCompliance: new FormControl(this.project.piiCompliance),
      expectedIR: new FormControl(this.project.expectedIR, [Validators.min(0), Validators.max(100), Validators.required]),
      expectedDR: new FormControl(this.project.expectedDR, [Validators.min(0), Validators.max(100), Validators.required]),
      expectedOQ: new FormControl(this.project.expectedOQ, [Validators.min(0), Validators.max(100), Validators.required]),
      audience: new FormControl(this.project.audience, Validators.required),
      languages: new FormArray(
        this.project.languages.map<FormGroup>((lang) => {
          return this.createLanguagesFormGroup(lang);
        }),
        [Validators.required],
      ),
      logoKey: new FormControl(this.project.logoKey),
    });

    this.currentStepForm.form = this.projectProfileForm;

    this.getImageUrl();
    this.getProjectManagersList();
    this.getClientsList();

    this.isDefaultStatusOption = this.projectProfileForm.get('status').value === STATUS_OPTIONS.DEFAULT;
    this.previousSelectedStatusOption = this.projectProfileForm.get('status').value;

    this.dirtyFormService.reset();
    this.projectProfileForm.valueChanges.subscribe(() => { this.dirtyFormService.isDirty(); });
  }

  addProjectManager($event: string) {
    if (this.projectManagersList.indexOf($event) === -1) {
      this.accountService.addProjectManager($event).subscribe(
        (res) => {
          this.projectManagersList = [...this.projectManagersList, $event];
        },
        (error) => {
          this.handleProjectManagerUpdateError(error);
        },
      );
    }
  }

  addClient($event: string) {
    if (this.clientsList.indexOf($event) === -1) {
      this.clientsList = [...this.clientsList, $event];
      this.accountService.addClient($event).subscribe(
        (res) => {},
        (error) => {
          this.handleClientUpdateError(error);
        },
      );
    }
  }

  notifyStatusOptions() {
    const { value } = this.projectProfileForm.get('status');
    const isDefaultToNonDefault = this.isDefaultStatusOption && value !== STATUS_OPTIONS.DEFAULT;
    const isNonDefaultToDefault = !this.isDefaultStatusOption && value === STATUS_OPTIONS.DEFAULT;
    const isNonDefaultToInField = !this.isDefaultStatusOption && value === STATUS_OPTIONS.IN_FIELD;
    const isNonDefaultToComplete = !this.isDefaultStatusOption && value === STATUS_OPTIONS.COMPLETE;

    if (isNonDefaultToDefault || isDefaultToNonDefault || isNonDefaultToInField || isNonDefaultToComplete) {
      this.openNotifyStatusOptionsDialog();
    }
  }

  private getLangCodeAfterValidateStatusOption(): string[] {
    const langCodeWarning: string[] = [];

    if (this.projectProfileForm.get('status').value !== STATUS_OPTIONS.DEFAULT) {
      const formProjectLanguages = this.projectProfileForm.get('languages').value;

      for (const language of formProjectLanguages) {
        const questionLangCode = language.langCode;
        let isQuestionAvailable = false;

        if (this.project.questions
          && this.project.questions.length
          && this.project.questions[0].text
          && this.project.questions[0].text.length) {
          isQuestionAvailable = this.project.questions[0].text.some((text) => {
            return text.langCode === questionLangCode.toLowerCase() && text.text !== '';
          });
        }

        if (!isQuestionAvailable) {
          langCodeWarning.push(questionLangCode);
        }
      }
    }

    return langCodeWarning;
  }

  private validateStatusOption() {
    const langCodeWarning = this.getLangCodeAfterValidateStatusOption();

    if (langCodeWarning.length) {
      this.openStatusOptionsWarningDialog(langCodeWarning);
    } else {
      this.saveProjectProfile();
    }
  }

  private getLangCodeAfterValidateLanguages(): string[] {
    const langCodeWarning: string[] = [];

    if (this.project.questions
      && this.project.questions.length
      && this.project.questions[0].text
      && this.project.questions[0].text.length) {
      for (const text of this.project.questions[0].text) {
        if (text.text !== '') {
          const questionLangCode = text.langCode.toLowerCase();
          const formProjectLanguages = this.projectProfileForm.get('languages').value;
          const questionLanguageAvailable = formProjectLanguages.some((language) => {
            return language.langCode.toLowerCase() === questionLangCode;
          });

          if (!questionLanguageAvailable) {
            langCodeWarning.push(questionLangCode);
          }
        }
      }
    }

    return langCodeWarning;
  }

  validateProjectProfile() {
    this.formSubmitted = true;
    const form = this.projectProfileForm;

    if (form.valid) {
      const langCodeWarning = this.getLangCodeAfterValidateLanguages();

      if (langCodeWarning.length) {
        this.openLanguagesWarningDialog(langCodeWarning);
      } else {
        this.validateStatusOption();
      }
    }
  }

  private saveProjectProfile() {
    this.dirtyFormService.isValid();
    const projectToSave: Project = {
      ...this.getFormValues(this.projectProfileForm),
    };

    if (this.isQuestionDeleted) {
      projectToSave.questions = this.project.questions;
    }

    if (!projectToSave._id) {
      this.createProjectProfile(projectToSave);
    } else {
      this.updateProjectProfile(projectToSave);
    }
  }

  private createProjectProfile(projectToSave: Project) {
    this.saveProjectHttpSubscriber = this.projectService.createProject(projectToSave).subscribe(
      (projectCreated) => {
        this.setProjectProfileProperties(this.projectDataService.projectData, projectCreated.payload.project);
        this.handleCreateSuccessMessage();
        this.saveNewLogoUrl();
        this.currentStepForm.submitted  = true;
        this.router.navigate(
          [`/projects/${this.projectService.editionMode}/suppliers`],
          {
            queryParams: { id: this.projectDataService.projectData._id },
            queryParamsHandling: 'merge',
          });
      },
      (error) => {
        this.handleError(error);
      });
  }

  private updateProjectProfile(projectToSave: Project) {
    const projectUpdate: ProjectUpdate = { _id: projectToSave._id, ...projectToSave };

    this.saveProjectHttpSubscriber = this.projectService.updateProject(projectUpdate).subscribe(
      (projectUpdated) => {
        this.setProjectProfileProperties(this.projectDataService.projectData, projectUpdated.payload.project);
        this.handleUpdateSuccessMessage();
        this.saveNewLogoUrl();
        this.currentStepForm.submitted = true;
        this.router.navigate(
          [`/projects/${this.projectService.editionMode}/suppliers`],
          {
            queryParams: { id: this.projectDataService.projectData._id },
            queryParamsHandling: 'merge',
          });
      },
      (error) => {
        this.handleError(error);
      });
  }

  private getDescriptionCopy(prev: string, next: string): string {
    if (prev !== STATUS_OPTIONS.DEFAULT && next === STATUS_OPTIONS.DEFAULT) {
      return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_DRAFT');
    }
    if (prev === STATUS_OPTIONS.DEFAULT && next !== STATUS_OPTIONS.DEFAULT) {
      return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_OTHERS');
    }
    if (prev === STATUS_OPTIONS.COMPLETE && next !== STATUS_OPTIONS.COMPLETE) {
      return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_COMPLETE_TO_IN_FIELD');
    }
    return this.translate.instant('STATUS_NOTIFY_DIALOG.DESCRIPTION_COMPLETE');
  }

  private openNotifyStatusOptionsDialog(): void {
    const { value } = this.projectProfileForm.get('status');
    const isInFieldToDefault = this.previousSelectedStatusOption === STATUS_OPTIONS.COMPLETE && value === STATUS_OPTIONS.IN_FIELD;

    // Disallowed actions are actions that are changing status from Complete to anything else,
    // or from In Field to Default (Draft)
    const isDisallowed = (this.previousSelectedStatusOption === STATUS_OPTIONS.COMPLETE && value !== STATUS_OPTIONS.COMPLETE)
      || (this.previousSelectedStatusOption === STATUS_OPTIONS.IN_FIELD && value === STATUS_OPTIONS.DEFAULT);

    const descriptionCopy = this.getDescriptionCopy(this.previousSelectedStatusOption, value);

    const dialogRef = this.dialog.open(StatusNotifyDialogComponent, {
      width: this.defaultDialogWithSize,
      data: {
        isDisallowed,
        descriptionCopy,
      },
      restoreFocus: true,
    });

    dialogRef.afterClosed().subscribe((isConfirm) => {
      if (isConfirm) {
        this.projectProfileForm.patchValue({ status: value });
      } else {
        this.projectProfileForm.patchValue({ status: this.previousSelectedStatusOption });
      }
    });
  }

  private openStatusOptionsWarningDialog(langCodes: string[]): void {
    const dialogRef = this.dialog.open(StatusWarningDialogComponent, {
      width: this.defaultDialogWithSize,
      data: {
        langCodes,
      },
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.projectProfileForm.patchValue({ status: STATUS_OPTIONS.DEFAULT });
      this.saveProjectProfile();
    });
  }

  private openLanguagesWarningDialog(langCodes: string[]): void {
    const dialogRef = this.dialog.open(LanguagesWarningDialogComponent, {
      width: this.defaultDialogWithSize,
      data: {
        langCodes,
      },
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((toDelete) => {
      this.isQuestionDeleted = toDelete;

      if (toDelete) {
        // It's important to keep the deleteQuestions() before the validateStatusOption() because
        // the status warning message should be displayed if an Q&A is not available in all languages.
        this.project.questions = this.projectService.deleteQuestions(this.project.questions, langCodes);

        this.validateStatusOption();
      } else {
        for (const language of this.project.languages) {
          if (langCodes.indexOf(language.langCode.toLowerCase()) !== -1) {
            this.languagesArray.push(this.createLanguagesFormGroup(language));
          }
        }
      }
    });
  }

  openLanguagesDialog(): void {
    this.dialog.open(LanguagesDialogComponent, {
      width: this.defaultDialogWithSize,
      data: {
        allLanguages: this.allLanguages,
        projectForm: this.projectProfileForm,
        surveyUrlFormatValidator: this.surveyUrlFormatValidator(),
        surveyUrlParamsValidator: this.surveyUrlParamsValidator(),
      },
      restoreFocus: false,
    });
  }

  // Function executed from the ImageUploaderComponent to store data.
  storeNewLogoUrl(logoUrl) {
    this.newLogoUrl = logoUrl;
  }

  // Function executed from the ImageUploaderComponent to delete data.
  deleteNewLogoUrl() {
    this.newLogoUrl = null;
  }

  // Function executed to cache the new logo data.
  saveNewLogoUrl() {
    const logoKey = this.projectProfileForm.get('logoKey').value;

    if (this.newLogoUrl) {
      this.projectService.storeCurrentProjectLogoUrl(this.projectProfileForm.get('logoKey').value, this.newLogoUrl);
    } else if (!logoKey) {
      this.projectService.deleteCurrentProjectLogoUrl();
    }
  }

  getImageUrl() {
    const key = this.projectProfileForm.get('logoKey').value;
    const logoUrl: string|null = this.projectService.getCurrentProjectLogoUrl(key);

    if (key !== '' && !logoUrl) {
      return this.fileUploadService.getImageUrl(key).subscribe(
        (res) => {
          this.imageSrc = res.payload.url;

          // Store the logo url to prevent another call to service
          this.projectService.storeCurrentProjectLogoUrl(key, this.imageSrc);
        },
        (error) => {
          this.handleError(error);
        },
      );
    }

    this.imageSrc = logoUrl;
  }

  getProjectManagersList() {
    this.accountService.getProjectManagers().subscribe(
      (res) => {
        this.projectManagersList = res.payload.projectManagers
                                              .map(projectManager => projectManager.fullName);
      },
      (error) => {
        this.handleProjectManagersError(error);
      },
    );
  }

  getClientsList() {
    this.accountService.getClients().subscribe(
      (res) => {
        this.clientsList = res.payload.clients.map(client => client.fullName);
      },
      (error) => {
        this.handleClientsError(error);
      },
    );
  }

  get languagesArray() {
    return this.projectProfileForm.get('languages') as FormArray;
  }

  removeLanguage(language) {
    const toRemove = language.value;
    const indexOfFormControls = this.languagesArray.value
                                    .findIndex(langItem => langItem.langCode === toRemove.langCode);

    if (indexOfFormControls !== -1) {
      this.languagesArray.removeAt(indexOfFormControls);
    }
  }

  private getFormValues(projectForm: FormGroup) {
    return <Project>{
      _id: this.project._id,
      title: projectForm.get('title').value,
      client: projectForm.get('client').value,
      status: projectForm.get('status').value,
      projectManager: projectForm.get('projectManager').value,
      endDate: projectForm.get('endDate').value,
      duration: projectForm.get('duration').value,
      maxBudget: projectForm.get('maxBudget').value,
      setRoom: projectForm.get('setRoom').value,
      type: projectForm.get('type').value,
      surveyDuration: projectForm.get('surveyDuration').value,
      bidTarget: projectForm.get('bidTarget').value,
      piiCompliance: projectForm.get('piiCompliance').value,
      languages: projectForm.get('languages').value,
      logoKey: projectForm.get('logoKey').value,
      expectedIR: projectForm.get('expectedIR').value,
      expectedDR: projectForm.get('expectedDR').value,
      expectedOQ: projectForm.get('expectedOQ').value,
      audience: projectForm.get('audience').value,
    };
  }

  private setProjectProfileProperties(currentProject: Project, savedProject: Project) {
    currentProject._id = savedProject._id;
    currentProject.title = savedProject.title;
    currentProject.client = savedProject.client;
    currentProject.status = savedProject.status;
    currentProject.projectManager = savedProject.projectManager;
    currentProject.endDate = savedProject.endDate;
    currentProject.duration = savedProject.duration;
    currentProject.maxBudget = savedProject.maxBudget;
    currentProject.setRoom = savedProject.setRoom;
    currentProject.type = savedProject.type;
    currentProject.surveyDuration = savedProject.surveyDuration;
    currentProject.bidTarget = savedProject.bidTarget;
    currentProject.piiCompliance = savedProject.piiCompliance;
    currentProject.languages = savedProject.languages;
    currentProject.logoKey = savedProject.logoKey;
    currentProject.expectedIR = savedProject.expectedIR;
    currentProject.expectedDR = savedProject.expectedDR;
    currentProject.expectedOQ = savedProject.expectedOQ;
    currentProject.audience = savedProject.audience;
    currentProject.numberOfPanelistsRequired = savedProject.numberOfPanelistsRequired;
    currentProject.projectIncidenceRate = savedProject.projectIncidenceRate;
    currentProject.securityOptions = savedProject.securityOptions;
    currentProject.expectedProjectCost = savedProject.expectedProjectCost;
  }

  // Success message for project creation
  private handleCreateSuccessMessage() {
    this.snackBarService.successWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_CREATED');
  }

  // Success message for project creation
  private handleUpdateSuccessMessage() {
    this.snackBarService.successWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_UPDATED');
  }

  // Generic error handler
  private handleError(error?: any) {
    this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.REQUEST_FAILED');
  }

  // Generic error handler
  private handleProjectManagersError(error: any) {
    this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_MANAGERS_REQUEST_FAILED');
  }

  // Generic error handler
  private handleClientsError(error: any) {
    this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.CLIENTS_REQUEST_FAILED');
  }

  // Generic error handler
  private handleProjectManagerUpdateError(error: any) {
    this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.PROJECT_MANAGER_UPDATE_FAILED');
  }

  // Generic error handler
  private handleClientUpdateError(error: any) {
    this.snackBarService.errorWithClose('PROJECT_PROFILE.MESSAGES.CLIENT_UPDATE_FAILED');
  }

  getProjectDurationOptionLabel(option: number) {
    if (!this.unitLabels) return option;
    return option > 1 ? `${option} ${this.unitLabels['DAYS']}` : `${option} ${this.unitLabels['DAY']}`;
  }

  getSurveyDurationOptionLabel(option: number) {
    if (!this.unitLabels) return option;
    return option > 1 ? `${option} ${this.unitLabels['MINUTES']}` : `${option} ${this.unitLabels['MINUTE']}`;
  }

  surveyUrlParamsValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const missingParam = !control.value.toLowerCase().includes('{id}');
      return missingParam ? { urlParams: missingParam } : null;
    };
  }

  surveyUrlFormatValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const URL_REGEXP = /^(http|https):\/\/(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
      const surveyUrl = control.value.toLowerCase().replace('{id}', 'validId');
      const invalidUrlString = surveyUrl.match(URL_REGEXP) === null;
      return invalidUrlString ? { urlFormat: invalidUrlString } : null;
    };
  }

  surveyUrlCheckValidator(surveyUrl): Boolean {
    const errors = surveyUrl.errors;

    return errors ? !!Object.keys(errors).length && !surveyUrl.untouched : false;
  }
}
