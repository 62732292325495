import { Component, OnDestroy, OnInit } from '@angular/core';
import { SupplierService } from 'src/app/services/supplier.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Supplier } from '../../../model/Common';
import { ProjectService } from '../../../services/project.service';
import { ProjectStepComponent } from '../project-step-component';
import { Project, ProjectUpdate } from '../../../model/project/project';
import { SupplierSources } from '../../../model/enums';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyFormService } from 'src/app/services/dirty-form.service';
import { Observable, Subscription } from 'rxjs';
import { AddSupplierDialogComponent } from './add-supplier-dialog/add-supplier-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SupplierModal } from 'src/app/model/supplier/supplier';
import { SupplierRequest } from 'src/app/model/supplier/request';
import { CreateSupplierResponse } from 'src/app/model/supplier/responses';

interface SelectableSupplier extends Supplier {
  checked: boolean;
  selected: boolean;
  source: SupplierSources;
  label: string;
}

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent extends ProjectStepComponent implements OnInit, OnDestroy {

  protected project: Project;
  public searchText: string = '';
  public updateProjectHttpSubscriber: Subscription;
  public suppliers: SelectableSupplier[];
  public suppliersForm: FormGroup;
  public isSearch: boolean = false;
  public dialogRef: MatDialogRef<AddSupplierDialogComponent>;

  constructor(private snackBarService: SnackBarService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private projectDataService: ProjectDataService,
              private router: Router,
              private supplierService: SupplierService,
              private dirtyFormService: DirtyFormService,
              private dialog: MatDialog) {
    super();

    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }
  }

  ngOnInit() {
    if (this.project._id) {
      this.setSuppliersFormData();
    }

    this.fetchSuppliers();
  }

  ngOnDestroy(): void {
    if (this.updateProjectHttpSubscriber) {
      this.updateProjectHttpSubscriber.unsubscribe();
    }
  }

  setSuppliersFormData() {
    this.suppliersForm = new FormGroup({
      suppliers: new FormArray(this.project.suppliers.map<FormGroup>((supplier) => {
        return new FormGroup({
          name: new FormControl(supplier.name, Validators.required),
          source: new FormControl(supplier.source, Validators.required),
          supplierId: new FormControl(supplier.supplierId),
        });
      })),
    });

    this.currentStepForm.form = this.suppliersForm;

    this.dirtyFormService.reset();
    this.suppliersForm.valueChanges.subscribe(() => { this.dirtyFormService.isDirty(); });
  }

  saveSuppliers() {
    if (this.project._id) {
      this.dirtyFormService.isValid();

      const projectData: ProjectUpdate = {
        suppliers: this.suppliersForm.get('suppliers').value,
        _id: this.project._id,
      };
      this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData).subscribe(
        (projectUpdated) => {
          this.projectDataService.projectData.suppliers = projectData.suppliers;
          this.snackBarService.successWithClose('SUPPLIERS.MESSAGES.SUPPLIERS_SAVED');
          this.currentStepForm.submitted = true;
          this.router.navigate([`/projects/${this.projectService.editionMode}/security`], { queryParamsHandling: 'preserve' });
        },
        (error) => {
          this.snackBarService.errorWithClose('SUPPLIERS.MESSAGES.SAVE_FAILED');
        });
    }
  }

  fetchSuppliers() {
    this.supplierService.getSuppliers().subscribe(
      (res) => {
        this.suppliers = res.payload.suppliers.map<SelectableSupplier>((supplier) => {
          return this.transformToSelectableSupplier(supplier);
        }).sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      },
      (error) => {
        this.handleGetSuppliersError();
      },
    );
  }

  private transformToSelectableSupplier(supplier: Supplier) {
    const projectSupplier = this.project.suppliers ?
      this.project.suppliers.find(projectSupplier => projectSupplier.supplierId === supplier._id) : null;

    return {
      ...supplier,
      supplierId: supplier._id,
      checked: false,
      selected: !!projectSupplier,
      label: `${supplier.name}${supplier.source === SupplierSources.USER ? '*' : ''}`,
    };
  }

  getSuppliers(selected: boolean) {
    if (!Array.isArray(this.suppliers)) return [];
    return this.suppliers.filter(supplier => supplier.selected === selected);
  }

  hasSelectedSuppliers(): boolean {
    return this.suppliers && this.suppliers.some(supplier => supplier.selected);
  }

  hasSuppliersWithProperties(selected: boolean, checked: boolean): boolean {
    return this.suppliers && this.suppliers.some(supplier => supplier.selected === selected && supplier.checked === checked);
  }

  addSelectedSuppliers() {

    this.suppliers.forEach((supplier) => {
      if (supplier.checked && !supplier.selected) {
        supplier.selected = true;
        supplier.checked = false;
        this.addSelectedSupplierToFormArray(supplier);
      }
    });
  }

  removeSelectedSuppliers() {

    this.suppliers.forEach((supplier) => {
      if (supplier.checked && supplier.selected) {
        supplier.selected = false;
        supplier.checked = false;
        this.removeSelectedSupplierFromFormArray(supplier);
      }
    });
  }

  get suppliersArray() {
    return this.suppliersForm.get('suppliers') as FormArray;
  }

  addSelectedSupplierToFormArray(supplier: Supplier) {
    this.suppliersArray.push(new FormGroup({
      name: new FormControl(supplier.name, Validators.required),
      source: new FormControl(supplier.source, Validators.required),
      supplierId: new FormControl(supplier.supplierId),
    }));
    this.suppliersForm.markAsDirty();
  }

  removeSelectedSupplierFromFormArray(supplier: Supplier) {
    const idx = this.suppliersArray.value.findIndex(sup => sup.name === supplier.name);
    this.suppliersArray.removeAt(idx);
    this.suppliersForm.markAsDirty();
  }

  private handleGetSuppliersError() {
    this.snackBarService.errorWithClose('SUPPLIERS.MESSAGES.GET_SUPPLIERS_FAILED');
  }

  enableSearch() {
    this.isSearch = true;
  }

  closeSearch() {
    this.searchText = '';
    this.isSearch = false;
  }

  getSupplierRequest(supplier: SupplierModal) {

    const supplierRequest: SupplierRequest = {
      supplier: {
        name: supplier.supplierName,
        panelistId: supplier.panelistId,
        redirectUrls: {
          COMPLETE: {
            url: supplier.completeUrl,
          },
          TERMINATE: {
            url: supplier.terminateUrl,
          },
          OVER_QUOTA: {
            url: supplier.overQuotaUrl,
          },
          SECURITY_TERMINATE: {
            url: supplier.securityTerminateUrl,
          },
        },
      },
    };

    return supplierRequest;
  }

  addSavedSupplierToSuppliersList(supplier) {

    this.suppliers.push({
      _id: supplier._id,
      name: supplier.name,
      source: supplier.source,
      supplierId: supplier._id,
      checked: false,
      selected: false,
      label: `${supplier.name}*`,
    });
  }

  saveNewSupplier() {

    this.dialogRef.afterClosed().subscribe((supplier: { value: SupplierModal }) => {

      if (supplier) {

        this.supplierService.createSupplier(this.getSupplierRequest(supplier.value)).subscribe(
          (payload: CreateSupplierResponse) => {
            this.addSavedSupplierToSuppliersList(payload.payload);
          });
      }
    });
  }

  openAddSupplierDialog(event: MouseEvent) {

    event.preventDefault();

    this.dialogRef = this.dialog.open(AddSupplierDialogComponent, {
      width: '760px',
      height: '760px',
      data: { suppliers: this.suppliers },
    });

    this.saveNewSupplier();
  }
}
