import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
var LanguagesDialogComponent = /** @class */ (function () {
    function LanguagesDialogComponent(translate, data) {
        this.translate = translate;
        this.data = data;
    }
    LanguagesDialogComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        var _this = this;
        // Shallow copy of the array to avoid changing the main data.
        var currentAllLanguages = tslib_1.__spread(this.data.allLanguages);
        try {
            for (var currentAllLanguages_1 = tslib_1.__values(currentAllLanguages), currentAllLanguages_1_1 = currentAllLanguages_1.next(); !currentAllLanguages_1_1.done; currentAllLanguages_1_1 = currentAllLanguages_1.next()) {
                var language = currentAllLanguages_1_1.value;
                language.label = this.translate.instant("LANGUAGES." + language.langCode);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (currentAllLanguages_1_1 && !currentAllLanguages_1_1.done && (_a = currentAllLanguages_1.return)) _a.call(currentAllLanguages_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        currentAllLanguages.sort(function (a, b) { return a.label > b.label ? 1 : -1; });
        this.languages = currentAllLanguages.map(function (lang) {
            return {
                langCode: lang.langCode,
                selected: _this.checkIfExists(lang.langCode),
                label: lang.label,
            };
        });
    };
    Object.defineProperty(LanguagesDialogComponent.prototype, "languagesArray", {
        get: function () {
            return this.data.projectForm.get('languages');
        },
        enumerable: true,
        configurable: true
    });
    LanguagesDialogComponent.prototype.updateFormControls = function () {
        var _this = this;
        this.languages.forEach(function (language) {
            var idx = _this.languagesArray.value.findIndex(function (lang) { return lang.langCode === language.langCode; });
            if (language.selected) {
                if (idx === -1) {
                    // if language is selected and not in the form, add it
                    _this.languagesArray.push(new FormGroup({
                        langCode: new FormControl(language.langCode, Validators.required),
                        surveyUrl: new FormControl('', [
                            Validators.required,
                            _this.data.surveyUrlFormatValidator,
                            _this.data.surveyUrlParamsValidator,
                        ]),
                        finalUrl: new FormControl(''),
                        completeUrl: new FormControl(''),
                    }));
                }
            }
            else {
                // if the language is not selected but is in the form, remove it
                if (idx > -1)
                    _this.languagesArray.removeAt(idx);
            }
        });
    };
    // Check the checkbox in dialog if the value language already selected.
    LanguagesDialogComponent.prototype.checkIfExists = function (langCode) {
        return this.languagesArray.value.findIndex(function (langItem) { return langItem.langCode === langCode; }) !== -1;
    };
    return LanguagesDialogComponent;
}());
export { LanguagesDialogComponent };
