import { environment } from '../../environments/environment';
import { ClientListResponse, ProjectManagerListResponse, UserResponse, } from '../model/account/Responses';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(http) {
        this.http = http;
    }
    AccountService.prototype.getAccount = function () {
        return this.http.get(environment.api_url + "/v1/web/user", HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.updateInfo = function (companyName, logoKey) {
        var request = {
            companyName: companyName,
            logoKey: logoKey,
        };
        return this.http.patch(environment.api_url + "/v1/web/user", request, HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.updatePassword = function (newPassword, oldPassword) {
        var request = {
            newPassword: newPassword,
            oldPassword: oldPassword,
        };
        return this.http.post(environment.api_url + "/v1/web/user/update_password", request, HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.deleteAccount = function () {
        return this.http.delete(environment.api_url + "/v1/web/user", HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.reactivateAccount = function (email, password) {
        var request = { user: { email: email, password: password } };
        return this.http.post(environment.api_url + "/v1/web/user/reactivate", request, HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.getProjectManagers = function () {
        return this.http.get(environment.api_url + "/v1/web/user/project_managers", HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.addProjectManager = function (fullName) {
        var request = { projectManager: { fullName: fullName } };
        return this.http.post(environment.api_url + "/v1/web/user/project_manager", request, HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.getClients = function () {
        return this.http.get(environment.api_url + "/v1/web/user/clients", HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.prototype.addClient = function (fullName) {
        var request = { client: { fullName: fullName } };
        return this.http.post(environment.api_url + "/v1/web/user/client", request, HttpUtils.getJsonContentTypeHeader());
    };
    AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
