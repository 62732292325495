import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar/typings/snack-bar-ref';
import { SimpleSnackBar } from '@angular/material/snack-bar/typings/simple-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private translate: TranslateService,
              private snackBar: MatSnackBar) { }

  errorWithClose(messageKey: string,
                 duration: number = 10000,
                 callback: Function = () => {}) {
    this.show(messageKey, 'GENERIC.LABELS.CLOSE', duration, null, callback);
  }

  successWithClose(messageKey: string,
                   duration: number = 10000,
                   panelClass = ['alert', 'success'],
                   callback: Function = () => {}) {
    this.show(messageKey, 'GENERIC.LABELS.CLOSE', duration, panelClass, callback);
  }

  show(messageKey: string,
       buttonLabelKey: string,
       duration: number = 10000,
       panelClass,
       callback: Function = () => {}) {

    this.translate.get([messageKey, buttonLabelKey]).subscribe((translations) => {
      this.snackBarRef = this.snackBar.open(
        translations[messageKey], translations[buttonLabelKey],
        { duration, panelClass },
        );

      this.snackBarRef.afterDismissed().subscribe(() => {
        callback();
      });

    });
  }

  hide() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }
}
