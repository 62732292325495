<div class="default-box-wrapper">
  <h2 class="main-title bold">
    <mat-icon aria-hidden="false" >{{data.iconName}}</mat-icon>
    {{ data.title }}
  </h2>
  <ul class="list-wrapper list-unstyled">
    <li class="list"
        *ngFor="let list of data.list"
    >
      <h3 class="sub-title normal">{{ list.title }}</h3>
      <p class="descrition">{{ list.value }} <span class="abr">{{ list.abvr }}</span></p>
    </li>
  </ul>
</div>
