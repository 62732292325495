import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SignedUrlResponse, ImageUrlResponse } from '../model/file-uploader/Responses';
import { GenericResponse } from '../model/Common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  baseUrl = `${environment.api_url}/v1/web`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth-token': `${localStorage.getItem('accessToken')}`,
    }),
  };

  // Get presinged url from the backend before uploadin an image
  public getPresignedUrls(fileName: string, fileContentType: string) {
    this.httpOptions['params'] = new HttpParams({
      fromString: `file_name=${fileName}&content_type=${fileContentType}`,
    });

    return this.http.get<SignedUrlResponse>(`${this.baseUrl}/upload/presigned_url`, this.httpOptions);
  }

  public uploadFileToServer(fileuploadurl: string, uploadContentType: string, file: any) {

    const httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': uploadContentType,
      }),
    };
    const req = new HttpRequest('PUT', fileuploadurl, file, httpHeader);
    return this.http.request(req);
  }

  // Get file/image signed url after uploading the file
  public getImageUrl(key: string) {
    this.httpOptions['params'] = new HttpParams({
      fromString: `key=${key}`,
    });

    return this.http.get<ImageUrlResponse>(`${this.baseUrl}/upload/signed_image_path`, this.httpOptions);
  }
}
