import { ClientsAction, ClientsActionType } from '../../actionstypes/clients/clients.actiontype';
import { Clients } from 'src/app/model/client/client';

const initialState: Clients = [];

export default (state = initialState, action: ClientsActionType) => {

  switch (action.type) {

    case ClientsAction.Get:
      return action.payload;

    case ClientsAction.Reset:
      return [];

    default:
      return state;
  }
};
