import { Component, OnDestroy } from '@angular/core';
import { AuthService, LocalStorageKey } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FileUploadService } from '../../services/file-upload.service';
import { NavigationService } from '../../services/navigation.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { Subscription } from 'rxjs';
import { RouteId } from 'src/app/model/Common';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnDestroy {
  private currentRouteId: RouteId;
  private readonly routeIdSubscription: Subscription;
  public imageSrc: string;
  private logoKey: string;

  constructor(private authService: AuthService,
              private router: Router,
              private navigationService: NavigationService,
              private snackBarService: SnackBarService,
              private fileUploadService: FileUploadService) {

    this.routeIdSubscription = this.navigationService.getRouteIdSubscription().subscribe((routeId) => {
      this.currentRouteId = routeId;
    });

    this.getImageProfile();
  }

  ngOnDestroy(): void {
    if (this.routeIdSubscription) {
      this.routeIdSubscription.unsubscribe();
    }
  }

  isUserConnected(): boolean {
    return this.authService.isLoggedIn();
  }

  getCompanyName(): string {
    return localStorage.getItem(LocalStorageKey.companyName);
  }

  getLogoKey(): string {
    const logoKey = localStorage.getItem(LocalStorageKey.logoKey);

    if (this.logoKey !== logoKey) {
      this.getImageProfile();
    }

    return logoKey;
  }

  getImageProfile(): void {
    this.logoKey = localStorage.getItem(LocalStorageKey.logoKey);
    if (this.logoKey) {
      this.fileUploadService.getImageUrl(this.logoKey).subscribe(
        (res) => {
          this.imageSrc = res.payload.url;
        },
        (error) => {
          this.imageSrc = '';
        },
      );
    } else {
      this.imageSrc = '';
    }
  }

  navigateTo(navigationItem: string) {
    return this.router.navigate([navigationItem.toLowerCase()]);
  }

  isCurrentNavigationItem(navigationItem: string): boolean {
    if (this.currentRouteId === RouteId.NO_ID) return false;
    return this.currentRouteId === RouteId[navigationItem];
  }

}
