<div class="dialog-wrapper">
	<span class="f-28 f-arial c-dark-blue" mat-dialog-title>{{data.title}}</span>
	<mat-dialog-content>
    <span class="f-12 f-arial justify c-dark-blue">{{data.description}}</span>
    <mat-form-field class="input-element">
      <input matInput type="text" placeholder="{{data.inputPlaceHolder}}" [(ngModel)]="inputValue">
    </mat-form-field>
  </mat-dialog-content>
	<mat-dialog-actions>
	  <button mat-stroked-button [mat-dialog-close]="false">{{data.cancelButton}}</button>
	  <button mat-raised-button color="primary" [mat-dialog-close]="inputValue" [disabled]="!inputValue">{{data.confirmButton}}</button>
	</mat-dialog-actions>
</div>
