import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProjectsService } from '../../services/projects.service';
import * as ProjectsAction from '../actionstypes/projects/projects.actiontype';
import { RequestStatusAction } from '../actionstypes/projects/request-status.actiontype';
import * as RequestStatus from '../actionstypes/request-status/request-status.actiontype';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { ProjectsPayload } from '../../model/project/projects';
import { of } from 'rxjs';

@Injectable()

export class ProjectsEffect {

  constructor(private actions$: Actions,
              private projects: ProjectsService) {
  }

  @Effect()
  loadProjects$ = this.actions$.pipe(
    ofType(ProjectsAction.ProjectsAction.Load),
    exhaustMap((action: ProjectsAction.Load) => this.projects.getProjects(action.query).pipe(
      map((projectsPayload: ProjectsPayload) => (new ProjectsAction.Get(projectsPayload.payload.projects))),
      catchError(() => of(new RequestStatus.RequestStatus((RequestStatusAction.Error)))),
    )),
  );

  @Effect()
  requestProjects$ = this.actions$.pipe(
    ofType(ProjectsAction.ProjectsAction.Get),
    mergeMap(() => of(new RequestStatus.RequestStatus(RequestStatusAction.Success))),
  );

  @Effect()
  resetProjects$ = this.actions$.pipe(
    ofType(ProjectsAction.ProjectsAction.Reset),
    mergeMap(() => of(new RequestStatus.RequestStatus(RequestStatusAction.Load))),
  );
}
