import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectStepComponent } from '../project-step-component';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { SnackBarService } from '../../../services/snack-bar.service';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Answer,
  I18nText,
  Outcome,
  Project,
  ProjectUpdate,
  Question,
  QuestionSource,
  QuestionType,
  RadioQuestion,
} from '../../../model/project/project';
import { AskDialogComponent } from '../../../widgets/ask-dialog/ask-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyFormService } from 'src/app/services/dirty-form.service';
import { Observable, Subscription } from 'rxjs';
import { Languages } from '../../../model/Common';
import { STATUS_OPTIONS } from '../../../model/project/options';
import { DEFAULT_LANG_CODE } from '../../../model/project/languages';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss'],
})
export class QualityComponent extends ProjectStepComponent implements OnInit, OnDestroy {

  protected project: Project;
  readonly QUESTION_COMPONENT_OFFSET = 140;
  // TODO Pricing: remove readonly and get price from BE
  readonly questionPriceTranslateParam = { questionPrice: '$0.02' };

  public updateProjectHttpSubscriber: Subscription;
  public questionsForm: FormGroup;
  public selectedIndex: number = -1;
  public currentSortProjectLanguages: Languages[];
  public selectedLangCode: string;
  public isEditModeDisabled: boolean = false;

  constructor(private snackBarService: SnackBarService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private projectDataService: ProjectDataService,
              private router: Router,
              private translate: TranslateService,
              private dialog: MatDialog,
              private dirtyFormService: DirtyFormService) {
    super();

    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }

    // Activate the current question.
    this.route.fragment.subscribe((fragments) => {
      if (fragments) {
        const match = fragments.match(/\d+/);

        if (match.length) {
          const result = match[0];
          const availableQuestion = this.project.questions[result];

          if (availableQuestion) {
            this.onQuestionSelected(Number(result));
          }
        }
      }
    });

    this.isEditModeDisabled = this.project.status !== STATUS_OPTIONS.DEFAULT;
  }

  ngOnInit(): void {

    /**
     * If the `id` of the project is available, that's means that the project is
     * created and accessible in the application by navigating between the different pages.
     * Conditions/validations are done in the ProjectResolver.
     */
    if (this.project._id) {
      this.setQualityFormData();
    }

    // Shallow copy of the array to avoid changing the main data.
    const currentProjectLanguages: Languages[] = [...this.project.languages];

    for (const language of currentProjectLanguages) {
      language.label = this.translate.instant(`LANGUAGES.${language.langCode}`);
    }

    this.currentSortProjectLanguages = currentProjectLanguages.sort((a, b) => a.label > b.label ? 1 : -1);

    // At page load, get/set the first selected language.
    const englishLanguage: Languages[] = this.currentSortProjectLanguages.filter((language) => {
      return language.langCode === DEFAULT_LANG_CODE;
    });

    this.selectedLangCode = englishLanguage.length ? englishLanguage[0].langCode.toLowerCase()
      : this.currentSortProjectLanguages[0].langCode.toLowerCase();
  }

  ngOnDestroy(): void {
    const langCodesWithEmptyText: string[] = this.getLangCodesWithEmptyText();

    if (langCodesWithEmptyText.length) {
      this.project.questions = this.projectService.deleteQuestions(this.project.questions, langCodesWithEmptyText);
    }

    if (this.updateProjectHttpSubscriber) {
      this.updateProjectHttpSubscriber.unsubscribe();
    }
  }

  private getLangCodesWithEmptyText() {
    const langCodes: string[] = [];

    if (this.project.questions
      && this.project.questions.length
      && this.project.questions[0].text
      && this.project.questions[0].text.length) {
      for (const text of this.project.questions[0].text) {
        if (text.text === '') {
          const questionLangCode = text.langCode.toLowerCase();
          const questionLanguageAvailable = this.project.languages.some((language) => {
            return language.langCode.toLowerCase() === questionLangCode;
          });

          if (questionLanguageAvailable) {
            langCodes.push(questionLangCode);
          }
        }
      }
    }

    return langCodes;
  }

  get questionsFormArray() {
    return this.questionsForm.get('questions') as FormArray;
  }

  setQualityFormData() {
    const questions = this.project.questions.length > 0 ?
      this.project.questions : [this.getEmptyQuestion()];

    this.questionsForm = new FormGroup({
      questions: new FormArray(
        questions.map<FormGroup>((question) => {
          return this.createQuestionFormGroup(question);
        }),
        { validators: this.questionLengthValidator },
      )});
    this.currentStepForm.form = this.questionsForm;

    if (this.project.questions.length === 0) {
      this.selectedIndex = 0;
    }

    this.dirtyFormService.reset();
    this.questionsForm.valueChanges.subscribe(() => { this.dirtyFormService.isDirty(); });
  }

  getQuestionForm(index: number) {
    return <FormGroup>this.questionsFormArray.at(index);
  }

  addQuestion() {
    this.questionsFormArray.push(
      this.createQuestionFormGroup(this.getEmptyQuestion()),
    );

    this.selectedIndex = this.questionsFormArray.length - 1;
  }

  focus() {
    this.router.navigate([], { fragment: `question-${this.selectedIndex}`, queryParamsHandling: 'preserve' });
  }

  onQuestionSelected(questionIndex: number) {
    this.selectedIndex = questionIndex;
    this.focus();
  }

  onQuestionRemove(questionIndex: number) {
    this.translate.get('QUESTION.DELETE_DIALOG_LABELS').subscribe((translations) => {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '480px',
        data: {
          title: translations['TITLE'],
          description: translations['DESCRIPTION'],
          cancelButton: translations['CANCEL_BUTTON'],
          confirmButton: translations['CONFIRM_BUTTON'],
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.questionsFormArray.markAsDirty();
          this.questionsFormArray.removeAt(questionIndex);

          if (this.questionsFormArray.length) {
            this.onQuestionSelected(0);
          } else {
            this.selectedIndex = -1;
          }
        }
      });
    });
  }

  getQuestionAnswersOffset(): number {
    if (this.selectedIndex <= 0) return 0;
    return this.selectedIndex * this.QUESTION_COMPONENT_OFFSET;
  }

  private getIdExtendObject(mainId, textData): object {
    const extend = {};
    const formattedTextData = [];
    const currentMainId = mainId !== '' ? mainId : null;

    if (currentMainId) {
      extend['_id'] = currentMainId;
    }

    for (const text of textData) {
      const currentTextId = text._id !== '' ? text._id : null;
      const currentText = { langCode: text.langCode, text: text.text };

      formattedTextData.push(currentTextId ? { _id: currentTextId, ...currentText } : currentText);
    }

    extend['text'] = formattedTextData;

    return extend;
  }

  save() {
    if (this.questionsForm.valid && this.project._id) {
      this.dirtyFormService.isValid();

      // TODO handle different types and sources in the future
      const questions:Question[] = this.questionsFormArray.value.map((questionAnswers) => {
        const extend = this.getIdExtendObject(questionAnswers._id, questionAnswers.text);

        return {
          ...extend,
          type: QuestionType.Radio,
          source: QuestionSource.User,
          answers: questionAnswers.answers.map((answer) => {
            const answersExtend = this.getIdExtendObject(answer._id, answer.text);

            return {
              ...answersExtend,
              outcome: answer.outcome,
              quota: answer.quota === 0 ? null : answer.quota,
            };
          })};
      });

      const projectData: ProjectUpdate = {
        questions,
        _id: this.project._id,
      };

      this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData).subscribe(
        (projectUpdated) => {
          this.projectDataService.projectData = projectUpdated.payload.project;
          this.snackBarService.successWithClose('QUALITY.MESSAGES.QUESTIONS_SAVED');
          this.currentStepForm.submitted  = true;
          this.router.navigate([`/projects/${this.projectService.editionMode}/generate`], { queryParamsHandling: 'preserve' });
        },
        (error) => {
          this.snackBarService.errorWithClose('QUALITY.MESSAGES.SAVE_FAILED');
        });
    }
  }

  questionLengthValidator: ValidatorFn = (questionsFormArray: FormArray): ValidationErrors | null => {
    return questionsFormArray.length > 0 ? null : { noQuestionDefined: true };
  }

  private createQuestionTextFormGroup(i18nText: I18nText) {
    return new FormGroup({
      _id: new FormControl(i18nText._id || ''),
      langCode: new FormControl(i18nText.langCode.toLowerCase()),
      text: new FormControl({
        value: this.getTextInLang([i18nText], i18nText.langCode),
        disabled: this.isEditModeDisabled,
      },                    Validators.required),
    });
  }

  private createQuestionFormGroup(question: Question) {
    switch (question.type) {
      case QuestionType.Radio: {
        const radioQuestion: RadioQuestion = <RadioQuestion>question;

        if (radioQuestion.text.length !== this.project.languages.length) {
          for (const language of this.project.languages) {
            const langCode = language.langCode.toLowerCase();
            const isTextAlreadyAvailable = radioQuestion.text.some((text) => {
              return text.langCode.toLowerCase() === langCode;
            });

            // Add new question text on language available
            if (!isTextAlreadyAvailable) {
              radioQuestion.text.push({ langCode, text: '' });
            }

            for (const answer of radioQuestion.answers) {
              const isAnswerTextAlreadyAvailable = answer.text.some((text) => {
                return text.langCode.toLowerCase() === langCode;
              });

              // Add new answer text on language available
              if (!isAnswerTextAlreadyAvailable) {
                answer.text.push({ langCode, text: '' });
              }
            }
          }
        }

        return new FormGroup({
          _id: new FormControl(radioQuestion._id || ''),
          text: new FormArray(
            radioQuestion.text.map<FormGroup>((i18nText) => {
              return this.createQuestionTextFormGroup(i18nText);
            })),
          answers: new FormArray(
            radioQuestion.answers.map<FormGroup>((answer) => {
              return this.createAnswerFormGroup(answer);
            }),
            { validators: this.radioAnswerOutcomesValidator }),
        });
      }
      // TODO add new question type here
      default:
        return null;
    }
  }

  radioAnswerOutcomesValidator: ValidatorFn = (answersFormArray: FormArray): ValidationErrors | null => {
    return answersFormArray.length > 1 && answersFormArray.controls.some((answerFormGroup) => {
      return (<FormGroup>answerFormGroup).controls['outcome'].value === Outcome.Accept;
    }) ? null : { noAcceptOutcome: true };
  }

  private createAnswerTextFormGroup(i18nText: I18nText) {
    return new FormGroup({
      _id: new FormControl(i18nText._id || ''),
      langCode: new FormControl(i18nText.langCode),
      text: new FormControl({
        value: this.getTextInLang([i18nText], i18nText.langCode),
        disabled: this.isEditModeDisabled,
      },                    Validators.required),
    });
  }

  private createAnswerFormGroup(answer: Answer) {
    return new FormGroup({
      _id: new FormControl(answer._id || ''),
      text: new FormArray(
       answer.text.map<FormGroup>((i18nText) => {
         return this.createAnswerTextFormGroup(i18nText);
       })),
      outcome: new FormControl(answer.outcome, Validators.required),
      quota: new FormControl(answer.quota),
    });
  }

  private getTextInLang(textArray: any[], lang: string) {
    const item = textArray.find(text => text.langCode === lang);
    return item ? item.text : '';
  }

  private getEmptyQuestion() {
    const textList = [];
    const answerList = [];

    answerList.push({ text: [], outcome: Outcome.Accept });
    answerList.push({ text: [], outcome: Outcome.Reject });

    for (const language of this.project.languages) {
      const langCode = language.langCode.toLowerCase();

      textList.push({ langCode, text: '' });

      for (const answer of answerList) {
        answer.text.push({ langCode, text: '' });
      }
    }

    return <Question>{
      type: QuestionType.Radio,
      source: QuestionSource.User,
      text: textList,
      answers: answerList};
  }
}
