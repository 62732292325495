/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-notify-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/common";
import * as i11 from "./status-notify-dialog.component";
var styles_StatusNotifyDialogComponent = [i0.styles];
var RenderType_StatusNotifyDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusNotifyDialogComponent, data: {} });
export { RenderType_StatusNotifyDialogComponent as RenderType_StatusNotifyDialogComponent };
function View_StatusNotifyDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["alowedAction", 1]], null, 10, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "button red mt-10 width-150"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "button dark-blue mt-10 width-150"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(9, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_6 = true; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("STATUS_NOTIFY_DIALOG.NO_BUTTON")); _ck(_v, 4, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 7).disabled || null); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 8).ariaLabel || null); _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("STATUS_NOTIFY_DIALOG.YES_BUTTON")); _ck(_v, 9, 0, currVal_7); }); }
function View_StatusNotifyDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["disalowedAction", 1]], null, 6, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "button dark-blue mt-10 width-150"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(5, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = false; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 4).ariaLabel || null); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("STATUS_NOTIFY_DIALOG.CLOSE_BUTTON")); _ck(_v, 5, 0, currVal_4); }); }
export function View_StatusNotifyDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "dialog-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "mat-icon", [["class", "close-dialog mat-icon notranslate"], ["mat-dialog-close", ""], ["role", "img"], ["type", "button"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), i1.ɵdid(7, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusNotifyDialogComponent_1)), i1.ɵdid(14, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusNotifyDialogComponent_2)), i1.ɵdid(16, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 6, 0); var currVal_5 = ""; _ck(_v, 7, 0, currVal_5); var currVal_7 = !_co.data.isDisallowed; _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.data.isDisallowed; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("STATUS_NOTIFY_DIALOG.HEADING")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).inline; var currVal_3 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); var currVal_4 = (i1.ɵnov(_v, 7).ariaLabel || null); _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.data.descriptionCopy; _ck(_v, 12, 0, currVal_6); }); }
export function View_StatusNotifyDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-notify-dialog", [], null, null, null, View_StatusNotifyDialogComponent_0, RenderType_StatusNotifyDialogComponent)), i1.ɵdid(1, 49152, null, 0, i11.StatusNotifyDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var StatusNotifyDialogComponentNgFactory = i1.ɵccf("app-status-notify-dialog", i11.StatusNotifyDialogComponent, View_StatusNotifyDialogComponent_Host_0, {}, {}, []);
export { StatusNotifyDialogComponentNgFactory as StatusNotifyDialogComponentNgFactory };
