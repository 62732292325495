import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {

  public registerForm: FormGroup;

  constructor(private authService: AuthService,
              private router: Router,
              private snackBarService: SnackBarService,
              private translate: TranslateService) {
    this.registerForm = new FormGroup({
      company: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      terms: new FormControl(false, Validators.requiredTrue),
      offers: new FormControl(false)});
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  public onRegister() {
    if (this.registerForm.valid) {
      this.authService.signUp(
        this.registerForm.get('company').value,
        this.registerForm.get('email').value,
        this.registerForm.get('password').value,
        this.registerForm.get('offers').value,
        this.translate.currentLang).subscribe(
          (result) => {
            this.router.navigate(['/account-confirmation-sent']);
          },
          (error) => {
            this.handleError(error);
          });
    }
  }

  private handleError(error: any) {
    this.snackBarService.errorWithClose('REGISTER.MESSAGES.SIGN_UP_ERROR');

    this.registerForm.get('password').reset();
    this.registerForm.get('confirmPassword').reset();
    this.registerForm.get('terms').reset();
  }
}
