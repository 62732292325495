import { Routes, ExtraOptions } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GenericDialogPageComponent } from './widgets/generic-dialog-page/generic-dialog-page.component';
import { EmailSentComponent } from './components/email-sent/email-sent.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { BillingComponent } from './components/billing/billing.component';
import { AnonymousGuardService } from './services/anonymous-guard.service';
import { RouteId } from './model/Common';
import { LogoutComponent } from './components/logout/logout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
var ɵ0 = { roles: ['User'] }, ɵ1 = {
    imagePath: 'assets/img/authentication.png',
    title: 'PASSWORD_UPDATED.TITLE',
    text: 'PASSWORD_UPDATED.DESCRIPTION',
    showButton: true,
    buttonLabel: 'PASSWORD_UPDATED.LABELS.LOGIN',
    buttonRoute: '/login',
}, ɵ2 = {
    imagePath: 'assets/img/authentication.png',
    title: 'ACCOUNT_CONFIRMED.TITLE',
    text: 'ACCOUNT_CONFIRMED.DESCRIPTION',
    showButton: true,
    buttonLabel: 'ACCOUNT_CONFIRMED.LABELS.GET_STARTED',
    buttonRoute: '/login',
}, ɵ3 = {
    imagePath: 'assets/img/authentication.png',
    title: 'ACCOUNT_CONFIRMATION_SENT.TITLE',
    text: 'ACCOUNT_CONFIRMATION_SENT.DESCRIPTION',
    showButton: false,
}, ɵ4 = { roles: ['User'], routeId: RouteId.PROFILE }, ɵ5 = { roles: ['User'], routeId: RouteId.NOTIFICATIONS }, ɵ6 = { roles: ['User'], routeId: RouteId.DASHBOARD }, ɵ7 = { roles: ['User'], routeId: RouteId.BILLING };
var routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: '404', component: PageNotFoundComponent },
    { path: 'login', component: LoginComponent, canActivate: [AnonymousGuardService] },
    {
        path: 'logout', component: LogoutComponent,
        canActivate: [AuthGuardService],
        data: ɵ0,
    },
    { path: 'register', component: RegisterComponent, canActivate: [AnonymousGuardService] },
    { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AnonymousGuardService] },
    { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AnonymousGuardService] },
    { path: 'password-updated', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
        data: ɵ1,
    },
    { path: 'email-sent/:email', component: EmailSentComponent, canActivate: [AnonymousGuardService] },
    { path: 'confirm-account', component: ConfirmAccountComponent, canActivate: [AnonymousGuardService] },
    { path: 'account-confirmed', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
        data: ɵ2,
    },
    { path: 'account-confirmation-sent', component: GenericDialogPageComponent, canActivate: [AnonymousGuardService],
        data: ɵ3,
    },
    {
        path: 'profile', component: ProfileComponent,
        canActivate: [AuthGuardService],
        data: ɵ4,
    },
    { path: 'notifications', component: NotificationsComponent,
        canActivate: [AuthGuardService],
        data: ɵ5,
    },
    { path: 'dashboard', component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: ɵ6,
    },
    { path: 'billing', component: BillingComponent,
        canActivate: [AuthGuardService],
        data: ɵ7,
    },
    { path: '**', component: PageNotFoundComponent },
];
var routerOptions = {
    anchorScrolling: 'enabled',
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
