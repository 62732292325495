import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services/project.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/project-data.service";
var ProjectResolver = /** @class */ (function () {
    function ProjectResolver(projectService, route, projectDataService, router) {
        this.projectService = projectService;
        this.route = route;
        this.projectDataService = projectDataService;
        this.router = router;
    }
    ProjectResolver.prototype.resolve = function (route, state) {
        var _this = this;
        this.currentProjectId = route.queryParams['id'];
        this.project = this.projectDataService.projectData;
        /**
         * Be sure to clear previous data when creating a new project and
         * get the project default data.
         */
        if (!this.currentProjectId) {
            this.project = this.projectDataService.resetProjectData();
        }
        /**
         * In edit mode, if there is no `id` parameter in the url, the user will be redirected to projects page.
         */
        if (!this.projectService.isNewModeProject && !this.currentProjectId) {
            this.projectService.setErrorProjectState(true);
            this.router.navigate(['/projects']);
            return of(null);
        }
        /**
         * If the user reload the page, validate if the `id` of the project is
         * in parameter before call the API endpoint to get the current project data.
         * Also, this means that the `this.project._id` data is not available.
         */
        if (this.currentProjectId &&
            !this.project._id) {
            // Get specific project and fill the form data.
            return this.projectService.getProject(this.currentProjectId).pipe(map(function (res) {
                if (!res['error']) {
                    _this.projectDataService.projectData = res.payload.project;
                    _this.project = _this.projectDataService.projectData;
                    return of(_this.project);
                }
            }), catchError(function (error) {
                _this.projectService.setErrorProjectState(true);
                _this.router.navigate(['/projects']);
                return of(null);
            }));
        }
        /**
         * Send the empty project from the model data (ProjectDataService).
         *
         * We're sending back an observable to stay constant with the API call `getProject()`.
         */
        var projectObservable = new Observable(function (requestObserver) {
            _this.projectDataService.projectData = _this.project;
            _this.project = _this.projectDataService.projectData;
            return requestObserver.next(_this.project);
        });
        return of(projectObservable);
    };
    ProjectResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectResolver_Factory() { return new ProjectResolver(i0.ɵɵinject(i1.ProjectService), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.ProjectDataService), i0.ɵɵinject(i2.Router)); }, token: ProjectResolver, providedIn: "root" });
    return ProjectResolver;
}());
export { ProjectResolver };
