<app-preloader [loader]="saveProjectHttpSubscriber" [isFullScreen]="true">
<div class="project-profile-wrapper">
    <div class="step-header">
        <li> 1 <span class='spacer'>{{ 'SIDEBAR.LABELS.PROJECT_PROFILE' | translate }}</span> </li>
        <app-project-pricing-dropdown></app-project-pricing-dropdown>
    </div>
    <div class="project-profile-form">
        <div class="importance-notice">
            <p class="bold">{{ 'PROJECT_PROFILE.HEADINGS.IMPORTANCE_OF_COMPLETION' | translate }}</p>
            <p class="italic">{{ 'PROJECT_PROFILE.DESCRIPTIONS.IMPORTANCE_OF_COMPLETION' | translate }}</p>
        </div>
        <form class="form"
              *ngIf="projectProfileForm"
              [formGroup]="projectProfileForm"
              (ngSubmit)="validateProjectProfile()"
        >
          <mat-card-content>
            <h4>{{ 'PROJECT_PROFILE.HEADINGS.CLIENT_LOGO' | translate }}</h4>
            <app-image-uploader [parentForm]="projectProfileForm"
                                [imageFormKey]="'logoKey'"
                                [imageSrc]="imageSrc"
                                [translationKey]="'IMAGE_UPLOADER_PROJECT'"
                                (uploadSuccessCb)="storeNewLogoUrl($event)"
                                (uploadErrorCb)="deleteNewLogoUrl()"
                                (removeSuccessCb)="deleteNewLogoUrl()"
            ></app-image-uploader>
            <div class="mt-40">
              <mat-form-field>
                  <mat-label>{{ 'PROJECT_PROFILE.LABELS.PROJECT_TITLE' | translate }}</mat-label>
                  <input matInput required formControlName="title" placeholder="{{ 'PROJECT_PROFILE.LABELS.PROJECT_TITLE' | translate }}" autocomplete="project-title">
                  <mat-error *ngIf="projectProfileForm.get('title').errors?.required">
                    {{ 'PROJECT_PROFILE.MESSAGES.PROJECT_TITLE_REQUIRED' | translate }}
                  </mat-error>
                <mat-error *ngIf="projectProfileForm.get('title').errors?.maxlength">
                  {{ 'PROJECT_PROFILE.MESSAGES.PROJECT_TITLE_MAX_LENGTH' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.STATUS' | translate }}</mat-label>
                <mat-select formControlName="status"
                            (selectionChange)="notifyStatusOptions()"
                            required
                >
                  <mat-option *ngFor="let option of statusOptions | keyvalue" [value]="option.value">{{option.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="projectProfileForm.get('status').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.STATUS_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <app-select-input [formSubmitted]="formSubmitted" [items]="clientsList" [parentForm]="projectProfileForm" [formControlItemName]="'client'"
                                [translationParentKey]="'SELECT_INPUT_CLIENT'" (itemAdded)="addClient($event)">
              </app-select-input>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.PROJECT_DURATION' | translate }}</mat-label>
                <mat-select formControlName="duration" required>
                  <mat-option *ngFor="let option of PROJECT_DURATION_OPTIONS;" [value]="option">{{ getProjectDurationOptionLabel(option) }}</mat-option>
                </mat-select>
                <mat-error *ngIf="projectProfileForm.get('duration').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.PROJECT_DURATION_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.SURVEY_DURATION' | translate }}</mat-label>
                <mat-select formControlName="surveyDuration" required>
                  <mat-option *ngFor="let option of SURVEY_DURATION_OPTIONS;" [value]="option">{{ getSurveyDurationOptionLabel(option) }}</mat-option>
                </mat-select>
                <mat-error *ngIf="projectProfileForm.get('surveyDuration').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.SURVEY_DURATION_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.PROJECT_TYPE' | translate }}</mat-label>
                <mat-select formControlName="type" required>
                  <mat-option *ngFor="let option of PROJECT_TYPE_OPTIONS;" [value]="option">{{option}}</mat-option>
                </mat-select>
                <mat-error *ngIf="projectProfileForm.get('type').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.PROJECT_TYPE_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.END_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker" [min]="todayDate" placeholder="End Date" formControlName="endDate" required autocomplete="project-end-date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="projectProfileForm.get('endDate').errors?.required">
                  {{ 'PROJECT_PROFILE.MESSAGES.END_DATE_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="projectProfileForm.get('endDate').errors?.matDatepickerMin">
                  {{ 'PROJECT_PROFILE.MESSAGES.MIN_END_DATE' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.SET_ROOM' | translate }}</mat-label>
                <input matInput formControlName="setRoom" type="number"  min="0" placeholder="{{ 'PROJECT_PROFILE.LABELS.SET_ROOM' | translate }}" required autocomplete="project-set-room">
                <span matSuffix>%</span>
                <mat-error *ngIf="projectProfileForm.get('setRoom').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.SET_ROOM' | translate }}
                </mat-error>
              </mat-form-field>

              <app-select-input [formSubmitted]="formSubmitted" [items]="projectManagersList" [parentForm]="projectProfileForm" [formControlItemName]="'projectManager'"
                                [translationParentKey]="'SELECT_INPUT_PROJECT_MANAGER'" (itemAdded)="addProjectManager($event)">
              </app-select-input>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.MAX_BUDGET' | translate }}</mat-label>
                <span matPrefix>$ &nbsp;</span>
                <input matInput formControlName="maxBudget" type="number" min="0" placeholder="{{ 'PROJECT_PROFILE.LABELS.MAX_BUDGET' | translate }}" required autocomplete="project-max-budget">
                <mat-error *ngIf="projectProfileForm.get('maxBudget').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.MAX_BUDGET' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="small-field">
                <mat-label>{{ 'PROJECT_PROFILE.LABELS.BID_TARGET' | translate }}</mat-label>
                <input matInput formControlName="bidTarget" type="number" min="1" placeholder="{{ 'PROJECT_PROFILE.LABELS.BID_TARGET' | translate }}" required autocomplete="project-bid-target">
                <mat-error *ngIf="projectProfileForm.get('bidTarget').errors">
                  {{ 'PROJECT_PROFILE.MESSAGES.BID_TARGET' | translate }}
                </mat-error>
              </mat-form-field>

              <div>
                <mat-form-field class="small-field">
                  <mat-label>{{ 'PROJECT_PROFILE.LABELS.EXPECTED_IR' | translate }}</mat-label>
                  <input matInput formControlName="expectedIR" type="number" min="0" placeholder="{{ 'PROJECT_PROFILE.LABELS.EXPECTED_IR' | translate }}" required autocomplete="project-expected-ir">
                  <span matSuffix>%</span>
                  <mat-error *ngIf="projectProfileForm.get('expectedIR').errors">
                    {{ 'PROJECT_PROFILE.MESSAGES.EXPECTED_IR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="small-field mr-50">
                  <mat-label>{{ 'PROJECT_PROFILE.LABELS.EXPECTED_DR' | translate }}</mat-label>
                  <input matInput formControlName="expectedDR" type="number" min="0" placeholder="{{ 'PROJECT_PROFILE.LABELS.EXPECTED_DR' | translate }}" required autocomplete="project-expected-dr">
                  <span matSuffix>%</span>
                  <mat-error *ngIf="projectProfileForm.get('expectedDR').errors">
                    {{ 'PROJECT_PROFILE.MESSAGES.EXPECTED_DR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="small-field">
                  <mat-label>{{ 'PROJECT_PROFILE.LABELS.EXPECTED_OQ' | translate }}</mat-label>
                  <input matInput formControlName="expectedOQ" type="number" min="0" placeholder="{{ 'PROJECT_PROFILE.LABELS.EXPECTED_OQ' | translate }}" required autocomplete="project-expected-oq">
                  <span matSuffix>%</span>
                  <mat-error *ngIf="projectProfileForm.get('expectedOQ').errors">
                    {{ 'PROJECT_PROFILE.MESSAGES.EXPECTED_OQ' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="small-field">
                  <mat-label>{{ 'PROJECT_PROFILE.LABELS.AUDIENCE' | translate }}</mat-label>
                  <mat-select formControlName="audience" required>
                    <mat-option *ngFor="let option of AUDIENCE_OPTIONS;" [value]="option">{{ 'PROJECT_PROFILE.LABELS.' + option.toUpperCase() | translate }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="projectProfileForm.get('audience').errors">
                    {{ 'PROJECT_PROFILE.MESSAGES.AUDIENCE' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>

              <h4 class="mb-10">{{ 'PROJECT_PROFILE.HEADINGS.SETTINGS' | translate }}</h4>
              <div class="settings-tooltip">
                <mat-icon aria-hidden="false" matTooltipPosition="right" matTooltip="{{ 'PROJECT_PROFILE.DESCRIPTIONS.COMPLIANCE' | translate }}" >help</mat-icon>
              </div>
              <mat-checkbox formControlName="piiCompliance" color="primary">{{ 'PROJECT_PROFILE.LABELS.PII_COMPLIANCE' | translate }}
              </mat-checkbox>

              <h4>{{ 'PROJECT_PROFILE.HEADINGS.LANGUAGES' | translate }}</h4>

              <button mat-raised-button
                      type="button"
                      (click)="openLanguagesDialog()"
                      disabled="{{!isDefaultStatusOption}}"
                      class="width-200 inverse">
                {{ 'PROJECT_PROFILE.LABELS.ADD_LANGUAGE' | translate }}
              </button>

              <mat-error class="mt-20" *ngIf="projectProfileForm.get('languages').errors?.required">
                {{ 'PROJECT_PROFILE.MESSAGES.LANGUAGE_IS_REQUIRED' | translate }}
              </mat-error>

              <mat-chip-list [selectable]="false"
                             [disabled]="!isDefaultStatusOption">
                <mat-chip *ngFor="let language of languagesArray.controls;" (removed)="removeLanguage(language)" [selectable]="false">
                  {{ 'LANGUAGES.'+ language.get('langCode').value | translate }}
                  <!-- Pass the language and remove it from the form controls: Easy -->
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>

              <div *ngIf="languagesArray.length">
                <h4>{{ 'PROJECT_PROFILE.HEADINGS.SURVEY_LINKS' | translate }}</h4>
                <p>{{ 'PROJECT_PROFILE.DESCRIPTIONS.SURVEY_LINKS' | translate }}</p>
                <div formArrayName="languages">
                  <div *ngFor="let language of languagesArray.controls; let languageIndex=index;" [formGroupName]="languageIndex">
                      <mat-form-field class="large-field" [ngClass]="{'large-spacing': surveyUrlCheckValidator(language.get('surveyUrl'))}">
                        <mat-label>{{ 'LANGUAGES.'+ language.get('langCode').value | translate }}</mat-label>
                        <input matInput formControlName="surveyUrl" placeholder="{{ 'LANGUAGES.'+ language.get('langCode').value | translate }}"
                               autocomplete="project-survey-url-{{ language.get('langCode').value }}">
                        <mat-error *ngIf="language.get('surveyUrl').errors?.required">{{ 'PROJECT_PROFILE.MESSAGES.SURVEY_URL_REQUIRED' | translate }}</mat-error>
                        <mat-error *ngIf="language.get('surveyUrl').errors?.urlFormat">{{ 'PROJECT_PROFILE.MESSAGES.SURVEY_URL_FORMAT' | translate }}</mat-error>
                        <mat-error *ngIf="language.get('surveyUrl').errors?.urlParams">{{ 'PROJECT_PROFILE.MESSAGES.SURVEY_URL_ID_REQUIRED' | translate }}</mat-error>
                        <mat-error *ngIf="language.get('surveyUrl').errors?.urlParams || language.get('surveyUrl').errors?.urlFormat">{{ 'PROJECT_PROFILE.MESSAGES.SURVEY_URL_EXAMPLE' | translate }}</mat-error>
                      </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>

          <mat-card-actions>
            <button mat-raised-button class="width-200 light-blue mt-40"
                    [disabled]="!this.projectProfileForm.valid"
                    type="submit"
            >{{ 'PROJECT_PROFILE.LABELS.SUBMIT_BUTTON' | translate }}</button>
          </mat-card-actions>

        </form>
    </div>
</div>
</app-preloader>
