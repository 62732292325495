import { Directive, HostListener, Input } from '@angular/core';
import { SnackBarService } from '../../services/snack-bar.service';
import { CsvService } from '../../services/csv.service';

/**
 * @example
 * <button
 *  mat-raised-button
 *  appExportCsv
 *  [projectId]="project._id"
 *  [translationKey]="'EXPORT_CSV_PROJECT'"
 * ></button>
 */
@Directive({
  selector: '[appExportCsv]',
})
export class ExportCsvDirective {

  @Input() projectId: string;
  @Input() translationKey: string;

  @HostListener('click') onClick() {
    if (this.projectId && this.translationKey) {
      this.fetchCsvFile();
    } else {
      console.error('The project id or the translation key are not defined!');
    }
  }

  constructor(private snackBarService: SnackBarService,
              private csvService: CsvService) {
  }

  public downloadBlobFile(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        // Create and object URL for the blob object.
        const blob = new Blob([payload.csv], {
          type: 'text/csv;charset=utf-8',
        });

        const url = window.URL.createObjectURL(blob);
        const filename = payload.filename || 'export.csv';

        // Creating the download link
        const link = document.createElement('a');

        link.setAttribute('hidden', '');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        // Download file automatically
        link.click();

        // Release an object URL
        URL.revokeObjectURL(url);

        document.body.removeChild(link);

        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }

  public fetchCsvFile(): any {
    this.csvService.getCsv(this.projectId).subscribe(
      (res) => {
        if (!Object.keys(res.payload).length) {
          // No participation found
          this.handleSuccess('REQUEST_SUCCESS_EMPTY');
        } else {
          this.downloadBlobFile(res.payload).then(() => {
            this.handleSuccess('REQUEST_SUCCESS');
          }).catch((err) => {
            this.handleError(err);
          });
        }
      },
      (err) => {
        this.handleError(err);
      },
    );
  }

  private handleSuccess(key: string) {
    this.snackBarService.successWithClose(`${this.translationKey}.${key}`);
  }

  private handleError(error: any) {
    this.snackBarService.errorWithClose(`${this.translationKey}.REQUEST_FAILED`, 30000);
  }
}
