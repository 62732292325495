import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SuppliersListResponse } from '../model/account/Responses';
import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import { SupplierRequest } from '../model/supplier/request';
import { CreateSupplierResponse } from '../model/supplier/responses';

@Injectable({ providedIn: 'root' })
export class SupplierService {

  constructor(private http: HttpClient) {}

  public getSuppliers() {
    return this.http.get<SuppliersListResponse>(`${environment.api_url}/v1/web/suppliers`, HttpUtils.getJsonContentTypeHeader());
  }

  public createSupplier(request: SupplierRequest) {
    // tslint:disable-next-line: max-line-length
    return this.http.post<CreateSupplierResponse>(`${environment.api_url}/v1/web/user/supplier`, request, HttpUtils.getJsonContentTypeHeader());
  }
}
