import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProjectsService = /** @class */ (function () {
    function ProjectsService(http) {
        this.http = http;
    }
    ProjectsService.prototype.getProjects = function (query) {
        var queryParams = new HttpParams();
        Object.keys(query).forEach(function (key) {
            queryParams = queryParams.append(key, query[key]);
        });
        return this.http.get(environment.api_url + "/v1/web/projects", { params: queryParams });
    };
    ProjectsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectsService_Factory() { return new ProjectsService(i0.ɵɵinject(i1.HttpClient)); }, token: ProjectsService, providedIn: "root" });
    return ProjectsService;
}());
export { ProjectsService };
