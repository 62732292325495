import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  CheckPasswordResetTokenRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from '../model/autentication/Requests';
import { GenericResponse } from '../model/Common';
import { HttpUtils } from '../util/http-util';
import { Observable } from 'rxjs';
import { CheckResetPasswordTokenResponse, LoginResponse } from '../model/autentication/Responses';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordService {

  constructor(private http: HttpClient) {
  }

  public passwordResetEmail(email: string, lang: string) {
    const request: ForgotPasswordRequest = { email, lang };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/send_reset_email`,
                                           request, HttpUtils.getJsonContentTypeHeader());
  }

  public updatePassword(password: string, email: string, resetToken: string) {
    const request: ResetPasswordRequest = {
      password,
      email,
      resetToken,
    };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/reset_password`,
                                           request, HttpUtils.getJsonContentTypeHeader());
  }

  public checkPasswordResetToken(email: string, resetToken: string) {
    const request: CheckPasswordResetTokenRequest = { email, resetToken };

    return new Observable<boolean>((observer:any) => {
      this.http.post<CheckResetPasswordTokenResponse>(`${environment.api_url}/v1/web/user/check_reset_token`,
                                                      request, HttpUtils.getJsonContentTypeHeader()).subscribe(
        (result) => {
          observer.next(result.payload && result.payload.valid);
        },
        (error) => {
          observer.next(false);
        },
        () => {
          observer.complete();
        });
    });
  }
}
