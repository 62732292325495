import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Outcome } from '../../../../../model/project/project';

interface OutcomeOption {
  value: string;
  labelKey: string;
}

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
})
export class AnswerComponent {
  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() currentLangCode: string;
  @Input() isEditModeDisabled: boolean;
  @Output() answerRemove = new EventEmitter<number>();

  public currentOutcomeLabelKey: string;
  public outcomeOptions: OutcomeOption[];

  constructor() { }

  public isInputQuota: boolean = false;

  ngOnInit() {
    const currentOutcomeValue = this.formGroup.get('outcome').value;

    this.outcomeOptions = Object.values(Outcome).map((enumValue) => {
      return { value: enumValue, labelKey: `OUTCOMES.${enumValue}` };
    });

    if (this.isEditModeDisabled) {
      const currentOutcome = this.outcomeOptions.filter((outcome) => {
        return outcome.value === currentOutcomeValue;
      });

      if (currentOutcome.length) {
        this.currentOutcomeLabelKey = currentOutcome[0].labelKey;
      }
    }
  }

  get text(): FormArray { return this.formGroup.get('text') as FormArray; }

  removeAnswer() {
    this.answerRemove.emit(this.index);
  }

  changeOutcome() {

    this.updateQuota(0);
    this.isInputQuota = false;
  }

  changeQuota(value: string) {
    this.updateQuota(parseInt(value, 0));
  }

  getOutcome() {

    if (this.formGroup.value.quota > 0) {
      this.isInputQuota = true;
    }

    return Outcome.Accept === this.formGroup.value.outcome;
  }

  getQuota() {
    if (this.formGroup.value.quota <= 0) {
      this.updateQuota(null);
    }
    return this.formGroup.value.quota;
  }

  addQuota(event: MouseEvent) {

    event.preventDefault();
    this.updateQuota('');
    this.isInputQuota = true;
  }

  deleteQuota() {
    this.updateQuota(0);
    this.isInputQuota = false;
  }

  updateQuota(quota) {
    this.formGroup.setValue(Object.assign(this.formGroup.value, { quota }));
  }
}
