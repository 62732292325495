import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  isDisallowed: boolean;
  descriptionCopy: string;
}

@Component({
  selector: 'app-status-notify-dialog',
  templateUrl: './status-notify-dialog.component.html',
  styleUrls: ['./status-notify-dialog.component.scss'],
})
export class StatusNotifyDialogComponent {

  constructor(private dialogRef: MatDialogRef<StatusNotifyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  cancelAction() {
    this.dialogRef.close();
  }
}
