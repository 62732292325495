import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import clientsReducer from './clients.reducer';
import requestStatusReducer from '../request-status/request-status.reducer';
import { RequestStatusAction } from '../../actionstypes/clients/request-status.actiontype';
import { RootClients } from 'src/app/model/client/client';

export const clientsRootReducer = combineReducers({
  requestStatus: requestStatusReducer(RequestStatusAction.Load, RequestStatusAction.Success, RequestStatusAction.Error),
  data: clientsReducer,
});

const getClientsFeatureState = createFeatureSelector<RootClients>('clients');

export const getClients = createSelector(
  getClientsFeatureState,
  state => state.data,
);
