import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, key: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    const lowerSearchText = searchText.toLowerCase();
    return items.filter((item) => {
      return item[key] && item[key].toLowerCase().includes(lowerSearchText);
    });
  }
}
