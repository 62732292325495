export enum STATUS_OPTIONS {
  DEFAULT = 'Draft',
  IN_FIELD = 'In Field',
  COMPLETE = 'Complete',
}

export const PROJECT_TYPE_OPTIONS = [
  'Administrative',
  'Construction',
  'Computer Software',
  'Equipment',
  'Event',
  'Maintenance',
  'New Product Development',
  'Research',
  'Other',
];

export const PROJECT_DURATION_OPTIONS = Array(30).fill(0).map((x, i) => i + 1);
export const SURVEY_DURATION_OPTIONS = Array(12).fill(0).map((x, i) => (i + 1) * 5);

export interface SecurityOption {
  code: number;
  name?: string;
  enabled: boolean;
  togglable: boolean;
  rate: number;
}

export const DEFAULT_EXPECTED_IR = 30;
export const DEFAULT_EXPECTED_DR = 10;
export const DEFAULT_EXPECTED_OQ = 10;
