import { ActionReducer } from '@ngrx/store';
import { RequestStatus } from '../../../model/request-status/request-status';
import { RequestStatusActionType } from '../../actionstypes/request-status/request-status.actiontype';

const initialState: RequestStatus = {
  isLoaded: false,
  isError: false,
};

export default (requestLoadType: string, requestSuccessType: string, requestErrorType: string): ActionReducer<RequestStatus> => {

  return (state: RequestStatus = initialState, action: RequestStatusActionType) => {

    switch (action.type) {

      case requestLoadType:
        return {
          isLoaded: false,
          isError: false,
        };

      case requestSuccessType:
        return {
          isLoaded: true,
          isError: false,
        };

      case requestErrorType:
        return {
          isLoaded: true,
          isError: true,
        };

      default:
        return state;
    }
  };
};
