import { EventEmitter } from '@angular/core';
import { Outcome } from '../../../../../model/project/project';
var AnswerComponent = /** @class */ (function () {
    function AnswerComponent() {
        this.answerRemove = new EventEmitter();
        this.isInputQuota = false;
    }
    AnswerComponent.prototype.ngOnInit = function () {
        var currentOutcomeValue = this.formGroup.get('outcome').value;
        this.outcomeOptions = Object.values(Outcome).map(function (enumValue) {
            return { value: enumValue, labelKey: "OUTCOMES." + enumValue };
        });
        if (this.isEditModeDisabled) {
            var currentOutcome = this.outcomeOptions.filter(function (outcome) {
                return outcome.value === currentOutcomeValue;
            });
            if (currentOutcome.length) {
                this.currentOutcomeLabelKey = currentOutcome[0].labelKey;
            }
        }
    };
    Object.defineProperty(AnswerComponent.prototype, "text", {
        get: function () { return this.formGroup.get('text'); },
        enumerable: true,
        configurable: true
    });
    AnswerComponent.prototype.removeAnswer = function () {
        this.answerRemove.emit(this.index);
    };
    AnswerComponent.prototype.changeOutcome = function () {
        this.updateQuota(0);
        this.isInputQuota = false;
    };
    AnswerComponent.prototype.changeQuota = function (value) {
        this.updateQuota(parseInt(value, 0));
    };
    AnswerComponent.prototype.getOutcome = function () {
        if (this.formGroup.value.quota > 0) {
            this.isInputQuota = true;
        }
        return Outcome.Accept === this.formGroup.value.outcome;
    };
    AnswerComponent.prototype.getQuota = function () {
        if (this.formGroup.value.quota <= 0) {
            this.updateQuota(null);
        }
        return this.formGroup.value.quota;
    };
    AnswerComponent.prototype.addQuota = function (event) {
        event.preventDefault();
        this.updateQuota('');
        this.isInputQuota = true;
    };
    AnswerComponent.prototype.deleteQuota = function () {
        this.updateQuota(0);
        this.isInputQuota = false;
    };
    AnswerComponent.prototype.updateQuota = function (quota) {
        this.formGroup.setValue(Object.assign(this.formGroup.value, { quota: quota }));
    };
    return AnswerComponent;
}());
export { AnswerComponent };
