import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ProjectStepComponent } from '../project-step-component';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
var SecurityComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SecurityComponent, _super);
    function SecurityComponent(snackBarService, route, projectService, projectDataService, router, securityOptionsService, translate, dirtyFormService) {
        var _this = _super.call(this) || this;
        _this.snackBarService = snackBarService;
        _this.route = route;
        _this.projectService = projectService;
        _this.projectDataService = projectDataService;
        _this.router = router;
        _this.securityOptionsService = securityOptionsService;
        _this.translate = translate;
        _this.dirtyFormService = dirtyFormService;
        if (_this.route.snapshot.data.project instanceof Observable) {
            _this.route.snapshot.data.project.subscribe(function (project) {
                _this.project = project;
            });
        }
        _this.translate.get('SECURITY.LABELS.FREE_PRICE').subscribe(function (translation) {
            _this.freePriceLabel = translation;
        });
        return _this;
    }
    SecurityComponent.prototype.saveSecurityOptions = function () {
        var _this = this;
        if (!this.project._id)
            return;
        this.dirtyFormService.isValid();
        var projectData = {
            securityOptions: this.securityForm.get('securityOptions').value,
            _id: this.project._id,
        };
        this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData).subscribe(function (projectUpdated) {
            _this.projectDataService.projectData = projectUpdated.payload.project;
            _this.snackBarService.successWithClose('SECURITY.MESSAGES.SECURITY_OPTIONS_SAVED');
            _this.currentStepForm.submitted = true;
        }, function (error) {
            _this.snackBarService.errorWithClose('SECURITY.MESSAGES.SAVE_FAILED');
        });
        return this.updateProjectHttpSubscriber;
    };
    SecurityComponent.prototype.completeStep = function () {
        this.router.navigate(["/projects/" + this.projectService.editionMode + "/quality"], { queryParamsHandling: 'preserve' });
    };
    SecurityComponent.prototype.ngOnInit = function () {
        /**
         * If the `id` of the project is available, that's means that the project is
         * created and accessible in the application by navigating between the different pages.
         * Conditions/validations are done in the ProjectResolver.
         */
        if (this.project._id) {
            this.setSecurityFormData();
        }
    };
    SecurityComponent.prototype.ngOnDestroy = function () {
        if (this.updateProjectHttpSubscriber) {
            this.updateProjectHttpSubscriber.unsubscribe();
        }
        if (this.securityFormChangeSubscriber) {
            this.securityFormChangeSubscriber.unsubscribe();
        }
    };
    SecurityComponent.prototype.getSecurityOptionsText = function (code, suffix) {
        var option = this.securityOptions.filter(function (option) {
            return option.code === code ? option.name : '';
        });
        if (option.length) {
            return this.translate.instant("SECURITY_OPTIONS." + option[0].name + "." + suffix);
        }
        return '';
    };
    SecurityComponent.prototype.setSecurityFormData = function () {
        var _this = this;
        this.securityOptionsService.getAll().subscribe(function (options) {
            var availableOptions = _this.project.securityOptions.length > 0 ? _this.project.securityOptions : options;
            _this.securityOptions = options;
            _this.securityForm = new FormGroup({
                securityOptions: new FormArray(availableOptions.map(function (option) {
                    return new FormGroup({
                        code: new FormControl(option.code, Validators.required),
                        enabled: new FormControl(option.enabled, Validators.required),
                        togglable: new FormControl(option.togglable, Validators.required),
                    });
                })),
            });
            _this.currentStepForm.form = _this.securityForm;
            _this.dirtyFormService.reset();
            _this.securityFormChangeSubscriber = _this.securityForm.valueChanges.subscribe(function () {
                _this.dirtyFormService.isDirty();
                _this.saveSecurityOptions();
            });
        });
    };
    Object.defineProperty(SecurityComponent.prototype, "securityOptionsArray", {
        get: function () {
            return this.securityForm.get('securityOptions');
        },
        enumerable: true,
        configurable: true
    });
    SecurityComponent.prototype.getSecurityOptionsByName = function (code, codeSearch) {
        return this.securityOptions.some(function (option) {
            return option.code === code && option.code === codeSearch;
        });
    };
    SecurityComponent.prototype.getFormattedSecurityOptions = function (securityOptions) {
        var options = {};
        securityOptions.forEach(function (ts) {
            options[ts.name] = ts.code;
        });
        return options;
    };
    SecurityComponent.prototype.getCostEstimate = function (securityOptionRate) {
        return this.project.numberOfPanelistsRequired * securityOptionRate / 100;
    };
    SecurityComponent.prototype.getFormattedPrice = function (securityOptionCode) {
        var securityOptions = this.getFormattedSecurityOptions(this.securityOptions);
        var isFreeSecurityOption = this.getSecurityOptionsByName(securityOptionCode, securityOptions.FREE_SECURITY);
        if (isFreeSecurityOption)
            return this.freePriceLabel;
        var rate = this.securityOptions.find(function (_a) {
            var code = _a.code;
            return code === Number(securityOptionCode);
        }).rate;
        var cost = this.getCostEstimate(rate);
        return this.translate.instant('CURRENCY', { value: cost });
    };
    return SecurityComponent;
}(ProjectStepComponent));
export { SecurityComponent };
