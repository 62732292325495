/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirm-account.component";
import * as i2 from "../../services/auth.service";
import * as i3 from "../../services/snack-bar.service";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
var styles_ConfirmAccountComponent = [];
var RenderType_ConfirmAccountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmAccountComponent, data: {} });
export { RenderType_ConfirmAccountComponent as RenderType_ConfirmAccountComponent };
export function View_ConfirmAccountComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ConfirmAccountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-account", [], null, null, null, View_ConfirmAccountComponent_0, RenderType_ConfirmAccountComponent)), i0.ɵdid(1, 4374528, null, 0, i1.ConfirmAccountComponent, [i2.AuthService, i3.SnackBarService, i4.Router, i4.ActivatedRoute, i5.TranslateService], null, null)], null, null); }
var ConfirmAccountComponentNgFactory = i0.ɵccf("app-confirm-account", i1.ConfirmAccountComponent, View_ConfirmAccountComponent_Host_0, {}, {}, []);
export { ConfirmAccountComponentNgFactory as ConfirmAccountComponentNgFactory };
