<div class="dialog-content-wrapper">
  <h2 mat-dialog-title>{{ 'STATUS_NOTIFY_DIALOG.HEADING' | translate }}</h2>
  <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
  <mat-dialog-content class="mat-typography">
    <p>{{ data.descriptionCopy }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
                      *ngIf="!data.isDisallowed"
                      #alowedAction>
    <button class="button red mt-10 width-150"
            mat-button
            (click)="cancelAction()"
            >
      {{ 'STATUS_NOTIFY_DIALOG.NO_BUTTON' | translate }}
    </button>
    <button class="button dark-blue mt-10 width-150"
            mat-button
            [mat-dialog-close]="true"
            >
      {{ 'STATUS_NOTIFY_DIALOG.YES_BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions align="end"
                      *ngIf="data.isDisallowed"
                      #disalowedAction>
    <button class="button dark-blue mt-10 width-150"
            mat-button
            [mat-dialog-close]="false"
            >
      {{ 'STATUS_NOTIFY_DIALOG.CLOSE_BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
</div>
