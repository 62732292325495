/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./default-box.component";
var styles_DefaultBoxComponent = [i0.styles];
var RenderType_DefaultBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DefaultBoxComponent, data: {} });
export { RenderType_DefaultBoxComponent as RenderType_DefaultBoxComponent };
function View_DefaultBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "sub-title normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [["class", "descrition"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "abr"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.abvr; _ck(_v, 6, 0, currVal_2); }); }
export function View_DefaultBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "default-box-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h2", [["class", "main-title bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["aria-hidden", "false"], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, "false"], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "list-wrapper list-unstyled"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultBoxComponent_1)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_4 = _co.data.list; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.data.iconName; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.data.title; _ck(_v, 5, 0, currVal_3); }); }
export function View_DefaultBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-default-box", [], null, null, null, View_DefaultBoxComponent_0, RenderType_DefaultBoxComponent)), i1.ɵdid(1, 49152, null, 0, i5.DefaultBoxComponent, [], null, null)], null, null); }
var DefaultBoxComponentNgFactory = i1.ɵccf("app-default-box", i5.DefaultBoxComponent, View_DefaultBoxComponent_Host_0, { data: "data" }, {}, []);
export { DefaultBoxComponentNgFactory as DefaultBoxComponentNgFactory };
