import { Action } from '@ngrx/store';
import { Clients } from 'src/app/model/client/client';

export enum ClientsAction {
  Load = '[Clients] Load',
  Get = '[Clients] Get',
  Reset = '[Clients] Reset',
}

export class Load implements Action {
  readonly type = ClientsAction.Load;
}

export class Get implements Action {
  readonly type = ClientsAction.Get;

  constructor(public payload: Clients) { }
}

export class Reset implements Action {
  readonly type = ClientsAction.Reset;
}

export type ClientsActionType = Load | Get | Reset;
