<div class="dialog-wrapper">
  <span class="f-28 f-arial c-dark-blue" mat-dialog-title>{{data.title}}</span>
  <mat-dialog-content>
    <span class="f-arial c-dark-blue description">{{data.description}}</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button type="button" [mat-dialog-close]="false" class="f-arial bold inverse">
      {{data.cancelButton}}
    </button>
    <button mat-raised-button class="dark-blue" [mat-dialog-close]="true">{{data.confirmButton}}</button>
  </mat-dialog-actions>
</div>
