<div class="reset-password-wrapper mt-100" fxLayout="row" fxLayoutAlign="space-evenly">
  <img fxHide.sm fxHide.xs fxFlex="45" src="{{imagePath}}" alt="authentication-image" class="mt-50 height-500">
  <mat-card  class="box auth-form-card" fxFlex="30" fxFlex.sm="70" fxFlex.xs="90">
    <mat-card-header>
      <mat-card-title>{{ title | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [innerHTML]="text | translate"></div>
    </mat-card-content>
    <button *ngIf="showButton" routerLink="{{buttonRoute}}" mat-raised-button color="primary" >{{ buttonLabel | translate }}</button>
  </mat-card>
</div>
