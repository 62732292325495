import { FormGroup } from '@angular/forms';

interface StepForm {
  submitted: boolean;
  form?: FormGroup;
}

export class ProjectStepComponent {

  protected currentStepForm: StepForm = { submitted: false };

  constructor() {}

  hasUnsavedChanges() {
    return !this.currentStepForm.submitted && this.currentStepForm.form && this.currentStepForm.form.dirty;
  }
}
