<ul class="sidebar-content bold">
  <li class="project-line">
    <span class="project-name">
      <span class="title">{{ !!title ? (title | truncate) : 'SIDEBAR.LABELS.PROJECT_UNTITLED' | translate }}</span>
      <mat-icon aria-hidden="false">keyboard_arrow_right</mat-icon>
    </span>
  </li>

  <li routerLink="profile" [routerLinkActive]="['active']" id="suppliers-step-1"
      queryParamsHandling="preserve"
      [ngClass]="{'enabled': isTabEnabled(projectSteps.PROJECT_PROFILE) }"> 1
      <span class='spacer'>{{ 'SIDEBAR.LABELS.PROJECT_PROFILE' | translate }}</span>
  </li>
  <li routerLink="suppliers" [routerLinkActive]="['active']" id="suppliers-step-2"
      queryParamsHandling="preserve"
      [ngClass]="{'enabled': isTabEnabled(projectSteps.SUPPLIERS) }" > 2
      <span class='spacer'>{{'SIDEBAR.LABELS.SUPPLIERS' | translate}}</span>
  </li>
  <li routerLink="security" [routerLinkActive]="['active']" id="suppliers-step-3"
      queryParamsHandling="preserve"
      [ngClass]="{'enabled': isTabEnabled(projectSteps.SECURITY) }" > 3
      <span class='spacer'>{{ 'SIDEBAR.LABELS.SECURITY' | translate }}</span>
  </li>
  <li routerLink="quality" [routerLinkActive]="['active']" id="suppliers-step-4"
      queryParamsHandling="preserve"
      [ngClass]="{'enabled': isTabEnabled(projectSteps.QUALITY) }"> 4
      <span class='spacer'> {{ 'SIDEBAR.LABELS.QUALITY' | translate }}</span>
  </li>
  <li routerLink="generate" [routerLinkActive]="['active']" id="suppliers-step-5"
      queryParamsHandling="preserve"
    [ngClass]="{'enabled': isTabEnabled(projectSteps.GENERATE) }"> 5
    <span class='spacer'> {{ 'SIDEBAR.LABELS.GENERATE' | translate }}
    </span>
  </li>
</ul>
