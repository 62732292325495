import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-supplier-dialog',
  templateUrl: './add-supplier-dialog.component.html',
  styleUrls: ['./add-supplier-dialog.component.scss'],
})

export class AddSupplierDialogComponent implements OnInit {

  public supplier: FormGroup;
  public validUrlRegExp: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.supplier = new FormGroup({
      supplierName: new FormControl('', [Validators.required, this.supplierNameValidator.bind(this)]),
      panelistId: new FormControl('', Validators.required),
      completeUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
      terminateUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
      overQuotaUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
      securityTerminateUrl: new FormControl('', [Validators.required, Validators.pattern(this.validUrlRegExp)]),
    });
  }

  supplierNameValidator({ value }: FormControl) {
    const isSupplier = this.data.suppliers.filter((supplier) => {
      return (supplier.name === value);
    });

    if (isSupplier.length !== 0) {
      return {
        supplierNameExists: true,
      };
    }

    return null;
  }
}
