<div class="question-container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="index-container" fxLayout="row" fxLayoutAlign="start center"
         [ngClass]="{'question-selected': selected}" (click)="questionSelected()">
      <mat-icon fxFlex="11" class="drag-icon pl-5 no-select">drag_indicator</mat-icon>
      <span fxFlex class="f-18 f-arial bold no-select">{{ index + 1 | number: '2.0-0' }}</span>
    </div>
    <div *ngIf="selected" class="arrow-right"></div>
  </div>
  <div id="input-container" class="content-container" fxLayoutAlign="start center" [formGroup]="formGroup">
      <div class="content" formArrayName="text">
        <div *ngFor="let txt of text.controls; index as i"
             [formGroupName]="i"
             [hidden]="txt.value.langCode !== currentLangCode">
          <input #textInput
                 matInput
                 class="content-input f-arial disabled-input"
                 formControlName="text"
                 autocomplete="question-text"
                 placeholder="{{ 'QUESTION.LABELS.PLACE_HOLDER' | translate }}"
          >
        </div>
      </div>
  </div>
</div>

