import { EventEmitter, Injectable } from '@angular/core';
import { Project } from '../model/project/project';
import { DEFAULT_LANG_CODE } from '../model/project/languages';
import { STATUS_OPTIONS, DEFAULT_EXPECTED_DR, DEFAULT_EXPECTED_OQ, DEFAULT_EXPECTED_IR } from '../model/project/options';

@Injectable({
  providedIn: 'root',
})
export class ProjectDataService {

  private project: Project;

  // Used to attach subscription for external component like `CreateProjectComponent`.
  public receivedProject: EventEmitter<Project>;

  constructor() {
    this.project = this.defaultProjectData();
    this.receivedProject = new EventEmitter<Project>();
  }

  public resetProjectData(): Project {
    this.projectData = this.defaultProjectData();
    this.project = this.projectData;

    return this.project;
  }

  get projectData() {
    return this.project;
  }

  set projectData(project) {
    // The Object.assign is to prevent error with read only property
    this.project = Object.assign({}, project);
    this.receivedProject.emit(this.project);
  }

  private defaultProjectData() {
    return <Project> {
      title: '',
      client: null,
      status: STATUS_OPTIONS.DEFAULT,
      projectManager: null,
      endDate: '',
      duration: '',
      maxBudget: null,
      setRoom: null,
      type: '',
      surveyDuration: '',
      bidTarget: null,
      piiCompliance: false,
      languages: [{
        langCode: DEFAULT_LANG_CODE,
        surveyUrl: '',
        finalUrl: '',
        completeUrl: '',
      }],
      logoKey: '',
      suppliers: [],
      securityOptions: [],
      questions: [],
      expectedDR: DEFAULT_EXPECTED_DR,
      expectedOQ: DEFAULT_EXPECTED_OQ,
      expectedIR: DEFAULT_EXPECTED_IR,
      expectedProjectCost: 0,
    };
  }
}
