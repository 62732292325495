<ol class="breadcrumb">
  <li class="breadcrumb-list"
      *ngFor="let breadcrumb of breadcrumbs">
    <a class="link bold"
       *ngIf="breadcrumb.url"
       [routerLink]="breadcrumb.url"
       [innerHTML]="breadcrumb.labelKey | translate"
       [routerLinkActive]="['active']"
    ></a>
    <span *ngIf="!breadcrumb.url"
          [routerLink]="breadcrumb.url"
          [innerHTML]="breadcrumb.label"
    ></span>
  </li>
</ol>
