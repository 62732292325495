import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../../../model/project/project';

@Component({
  selector: 'app-project-summary-box',
  templateUrl: './project-summary-box.component.html',
  styleUrls: ['./project-summary-box.component.scss'],
})
export class ProjectSummaryBoxComponent implements OnInit {

  @Input() project: Project;
  @Input() isBoxHover: boolean = false;
  @Input() isBoxBorder: boolean = false;
  @Input() isViewReportBtn: boolean = false;
  @Input() isExportReportBtn: boolean = false;
  @Input() isProjectHealth: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
