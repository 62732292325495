import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import { Client, Clients } from '../model/client/client';
import { pluck, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class ClientsService {

  constructor(private http: HttpClient) {

  }

  getClients(): Observable<Clients> {

    return this.http.get<Clients>(`${environment.api_url}/v1/web/user/clients`, HttpUtils.getJsonContentTypeHeader()).pipe(
      pluck('payload', 'clients'),
      map((clients: any) => clients.map((client: Client) => client.fullName)),
      catchError(() => of([]),
    ));
  }
}
