import { Projects } from '../../../model/project/projects';
import { ProjectsAction, ProjectsActionType } from '../../actionstypes/projects/projects.actiontype';

const initialState: Projects = [];

export default (state = initialState, action: ProjectsActionType) => {

  switch (action.type) {

    case ProjectsAction.Get:
      return action.payload;

    case ProjectsAction.Reset:
      return [];

    default:
      return state;
  }
};
