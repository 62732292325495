import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SurveyStatusService = /** @class */ (function () {
    function SurveyStatusService(http) {
        this.http = http;
    }
    SurveyStatusService.prototype.getStatus = function () {
        return this.http.get(environment.api_url + "/v1/web/survey_status", HttpUtils.getJsonContentTypeHeader());
    };
    SurveyStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SurveyStatusService_Factory() { return new SurveyStatusService(i0.ɵɵinject(i1.HttpClient)); }, token: SurveyStatusService, providedIn: "root" });
    return SurveyStatusService;
}());
export { SurveyStatusService };
