import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ProjectStepComponent } from '../project-step-component';
import { SupplierSources } from '../../../model/enums';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AddSupplierDialogComponent } from './add-supplier-dialog/add-supplier-dialog.component';
var SuppliersComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SuppliersComponent, _super);
    function SuppliersComponent(snackBarService, route, projectService, projectDataService, router, supplierService, dirtyFormService, dialog) {
        var _this = _super.call(this) || this;
        _this.snackBarService = snackBarService;
        _this.route = route;
        _this.projectService = projectService;
        _this.projectDataService = projectDataService;
        _this.router = router;
        _this.supplierService = supplierService;
        _this.dirtyFormService = dirtyFormService;
        _this.dialog = dialog;
        _this.searchText = '';
        _this.isSearch = false;
        if (_this.route.snapshot.data.project instanceof Observable) {
            _this.route.snapshot.data.project.subscribe(function (project) {
                _this.project = project;
            });
        }
        return _this;
    }
    SuppliersComponent.prototype.ngOnInit = function () {
        if (this.project._id) {
            this.setSuppliersFormData();
        }
        this.fetchSuppliers();
    };
    SuppliersComponent.prototype.ngOnDestroy = function () {
        if (this.updateProjectHttpSubscriber) {
            this.updateProjectHttpSubscriber.unsubscribe();
        }
    };
    SuppliersComponent.prototype.setSuppliersFormData = function () {
        var _this = this;
        this.suppliersForm = new FormGroup({
            suppliers: new FormArray(this.project.suppliers.map(function (supplier) {
                return new FormGroup({
                    name: new FormControl(supplier.name, Validators.required),
                    source: new FormControl(supplier.source, Validators.required),
                    supplierId: new FormControl(supplier.supplierId),
                });
            })),
        });
        this.currentStepForm.form = this.suppliersForm;
        this.dirtyFormService.reset();
        this.suppliersForm.valueChanges.subscribe(function () { _this.dirtyFormService.isDirty(); });
    };
    SuppliersComponent.prototype.saveSuppliers = function () {
        var _this = this;
        if (this.project._id) {
            this.dirtyFormService.isValid();
            var projectData_1 = {
                suppliers: this.suppliersForm.get('suppliers').value,
                _id: this.project._id,
            };
            this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData_1).subscribe(function (projectUpdated) {
                _this.projectDataService.projectData.suppliers = projectData_1.suppliers;
                _this.snackBarService.successWithClose('SUPPLIERS.MESSAGES.SUPPLIERS_SAVED');
                _this.currentStepForm.submitted = true;
                _this.router.navigate(["/projects/" + _this.projectService.editionMode + "/security"], { queryParamsHandling: 'preserve' });
            }, function (error) {
                _this.snackBarService.errorWithClose('SUPPLIERS.MESSAGES.SAVE_FAILED');
            });
        }
    };
    SuppliersComponent.prototype.fetchSuppliers = function () {
        var _this = this;
        this.supplierService.getSuppliers().subscribe(function (res) {
            _this.suppliers = res.payload.suppliers.map(function (supplier) {
                return _this.transformToSelectableSupplier(supplier);
            }).sort(function (a, b) {
                return a.name > b.name ? 1 : -1;
            });
        }, function (error) {
            _this.handleGetSuppliersError();
        });
    };
    SuppliersComponent.prototype.transformToSelectableSupplier = function (supplier) {
        var projectSupplier = this.project.suppliers ?
            this.project.suppliers.find(function (projectSupplier) { return projectSupplier.supplierId === supplier._id; }) : null;
        return tslib_1.__assign({}, supplier, { supplierId: supplier._id, checked: false, selected: !!projectSupplier, label: "" + supplier.name + (supplier.source === SupplierSources.USER ? '*' : '') });
    };
    SuppliersComponent.prototype.getSuppliers = function (selected) {
        if (!Array.isArray(this.suppliers))
            return [];
        return this.suppliers.filter(function (supplier) { return supplier.selected === selected; });
    };
    SuppliersComponent.prototype.hasSelectedSuppliers = function () {
        return this.suppliers && this.suppliers.some(function (supplier) { return supplier.selected; });
    };
    SuppliersComponent.prototype.hasSuppliersWithProperties = function (selected, checked) {
        return this.suppliers && this.suppliers.some(function (supplier) { return supplier.selected === selected && supplier.checked === checked; });
    };
    SuppliersComponent.prototype.addSelectedSuppliers = function () {
        var _this = this;
        this.suppliers.forEach(function (supplier) {
            if (supplier.checked && !supplier.selected) {
                supplier.selected = true;
                supplier.checked = false;
                _this.addSelectedSupplierToFormArray(supplier);
            }
        });
    };
    SuppliersComponent.prototype.removeSelectedSuppliers = function () {
        var _this = this;
        this.suppliers.forEach(function (supplier) {
            if (supplier.checked && supplier.selected) {
                supplier.selected = false;
                supplier.checked = false;
                _this.removeSelectedSupplierFromFormArray(supplier);
            }
        });
    };
    Object.defineProperty(SuppliersComponent.prototype, "suppliersArray", {
        get: function () {
            return this.suppliersForm.get('suppliers');
        },
        enumerable: true,
        configurable: true
    });
    SuppliersComponent.prototype.addSelectedSupplierToFormArray = function (supplier) {
        this.suppliersArray.push(new FormGroup({
            name: new FormControl(supplier.name, Validators.required),
            source: new FormControl(supplier.source, Validators.required),
            supplierId: new FormControl(supplier.supplierId),
        }));
        this.suppliersForm.markAsDirty();
    };
    SuppliersComponent.prototype.removeSelectedSupplierFromFormArray = function (supplier) {
        var idx = this.suppliersArray.value.findIndex(function (sup) { return sup.name === supplier.name; });
        this.suppliersArray.removeAt(idx);
        this.suppliersForm.markAsDirty();
    };
    SuppliersComponent.prototype.handleGetSuppliersError = function () {
        this.snackBarService.errorWithClose('SUPPLIERS.MESSAGES.GET_SUPPLIERS_FAILED');
    };
    SuppliersComponent.prototype.enableSearch = function () {
        this.isSearch = true;
    };
    SuppliersComponent.prototype.closeSearch = function () {
        this.searchText = '';
        this.isSearch = false;
    };
    SuppliersComponent.prototype.getSupplierRequest = function (supplier) {
        var supplierRequest = {
            supplier: {
                name: supplier.supplierName,
                panelistId: supplier.panelistId,
                redirectUrls: {
                    COMPLETE: {
                        url: supplier.completeUrl,
                    },
                    TERMINATE: {
                        url: supplier.terminateUrl,
                    },
                    OVER_QUOTA: {
                        url: supplier.overQuotaUrl,
                    },
                    SECURITY_TERMINATE: {
                        url: supplier.securityTerminateUrl,
                    },
                },
            },
        };
        return supplierRequest;
    };
    SuppliersComponent.prototype.addSavedSupplierToSuppliersList = function (supplier) {
        this.suppliers.push({
            _id: supplier._id,
            name: supplier.name,
            source: supplier.source,
            supplierId: supplier._id,
            checked: false,
            selected: false,
            label: supplier.name + "*",
        });
    };
    SuppliersComponent.prototype.saveNewSupplier = function () {
        var _this = this;
        this.dialogRef.afterClosed().subscribe(function (supplier) {
            if (supplier) {
                _this.supplierService.createSupplier(_this.getSupplierRequest(supplier.value)).subscribe(function (payload) {
                    _this.addSavedSupplierToSuppliersList(payload.payload);
                });
            }
        });
    };
    SuppliersComponent.prototype.openAddSupplierDialog = function (event) {
        event.preventDefault();
        this.dialogRef = this.dialog.open(AddSupplierDialogComponent, {
            width: '760px',
            height: '760px',
            data: { suppliers: this.suppliers },
        });
        this.saveNewSupplier();
    };
    return SuppliersComponent;
}(ProjectStepComponent));
export { SuppliersComponent };
