import { Component, Input } from '@angular/core';
import { DefaultBox } from 'src/app/model/project/default-box';

@Component({
  selector: 'app-default-box',
  templateUrl: './default-box.component.html',
  styleUrls: ['./default-box.component.scss'],
})
export class DefaultBoxComponent {

  @Input() data: DefaultBox;

  constructor() { }

}
