import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';

interface DialogData {
  langCodes: string[];
}

@Component({
  selector: 'app-status-warning-dialog',
  templateUrl: './status-warning-dialog.component.html',
  styleUrls: ['./status-warning-dialog.component.scss'],
})
export class StatusWarningDialogComponent implements OnInit {

  public languagesLabel: string[];

  constructor(private projectService: ProjectService,
              private dialogRef: MatDialogRef<StatusWarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    this.languagesLabel = this.projectService.sortLangCodesByLabel(this.data.langCodes);
  }

  cancelAction() {
    this.dialogRef.close();
  }
}
