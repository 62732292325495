import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RouteId } from '../model/Common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {

  private readonly routeIdSubject: BehaviorSubject<RouteId>;
  private routeIdSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute,
              private router: Router) {
    this.routeIdSubject = new BehaviorSubject<RouteId>(RouteId.NO_ID);
    this.subscribeToRouteChange();
  }

  ngOnDestroy(): void {
    if (this.routeIdSubscription) {
      this.routeIdSubscription.unsubscribe();
    }
  }

  getRouteIdSubscription(): BehaviorSubject<RouteId> {
    return this.routeIdSubject;
  }

  private getLatestChild(route: ActivatedRoute) {
    let latestRoute = route;
    while (latestRoute.firstChild) {
      latestRoute = latestRoute.firstChild;
    }

    return latestRoute;
  }

  private subscribeToRouteChange() {
    // Initial navigation state
    const latestRoute = this.getLatestChild(this.activeRoute);
    if (latestRoute) {
      latestRoute.data.pipe(take(1)).subscribe((data) => {
        this.routeIdSubject.next(data.routeId || RouteId.NO_ID);
      });
    }

    this.routeIdSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activeRoute),
      map(route => this.getLatestChild(route)),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    ).subscribe((data: any) => {
      this.routeIdSubject.next(data.routeId || RouteId.NO_ID);
    });
  }
}
