import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { reducers } from './ngrx/reducers';
export function createHttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var ɵ0 = reducers;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
