import { Action } from '@ngrx/store';
import { Projects, ProjectsQueryParams } from '../../../model/project/projects';

export enum ProjectsAction {
  Load = '[Projects] Load',
  Get = '[Projects] Get',
  Reset = '[Projects] Reset',
}

export class Load implements Action {
  readonly type = ProjectsAction.Load;

  constructor(public query: ProjectsQueryParams) { }
}

export class Get implements Action {
  readonly type = ProjectsAction.Get;

  constructor(public payload: Projects) { }
}

export class Reset implements Action {
  readonly type = ProjectsAction.Reset;
}

export type ProjectsActionType = Load | Get | Reset;
