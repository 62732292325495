import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { BillingInfoResponse } from '../model/project/Responses';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { GroupedProjectBillingInfo } from '../model/billing/billing';

@Injectable({
  providedIn: 'root',
})
export class BillingService {

  public groupedBillingInfo: GroupedProjectBillingInfo[];
  constructor(private http: HttpClient) { }

  public getBillingInfo(): Observable<GroupedProjectBillingInfo[]> {
    return this.http.get<BillingInfoResponse>(`${environment.api_url}/v1/web/billing`, HttpUtils.getJsonContentTypeHeader())
      .pipe(
        map((response) => {
          this.groupedBillingInfo = response.payload.billingInfo;
          return response.payload.billingInfo;
        }),
        catchError(error => throwError(error)),
      );
  }
}
