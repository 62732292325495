import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UpdateInfo, UpdatePassword } from '../model/account/Requests';
import { GenericResponse } from '../model/Common';
import {
  ClientListResponse,
  ProjectManagerListResponse,
  UserResponse,
  SuppliersListResponse,
} from '../model/account/Responses';
import { HttpUtils } from '../util/http-util';

@Injectable({ providedIn: 'root' })
export class AccountService {

  constructor(private http: HttpClient) {}

  public getAccount() {
    return this.http.get<UserResponse>(`${environment.api_url}/v1/web/user`, HttpUtils.getJsonContentTypeHeader());
  }

  public updateInfo(companyName: string, logoKey: string) {
    const request: UpdateInfo = {
      companyName,
      logoKey,
    };
    return this.http.patch<GenericResponse>(`${environment.api_url}/v1/web/user`, request, HttpUtils.getJsonContentTypeHeader());
  }

  public updatePassword(newPassword: string, oldPassword: string) {
    const request: UpdatePassword = {
      newPassword,
      oldPassword,
    };
    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/update_password`, request,
                                           HttpUtils.getJsonContentTypeHeader());
  }

  public deleteAccount() {
    return this.http.delete<GenericResponse>(`${environment.api_url}/v1/web/user`, HttpUtils.getJsonContentTypeHeader());
  }

  public reactivateAccount(email: string, password: string) {
    const request = { user: { email, password } };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/reactivate`, request,
                                           HttpUtils.getJsonContentTypeHeader());
  }

  public getProjectManagers() {
    return this.http.get<ProjectManagerListResponse>(`${environment.api_url}/v1/web/user/project_managers`,
                                                     HttpUtils.getJsonContentTypeHeader());
  }

  public addProjectManager(fullName: string) {
    const request = { projectManager: { fullName }  };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/project_manager`, request,
                                           HttpUtils.getJsonContentTypeHeader());
  }

  public getClients() {
    return this.http.get<ClientListResponse>(`${environment.api_url}/v1/web/user/clients`,
                                             HttpUtils.getJsonContentTypeHeader());
  }

  public addClient(fullName: string) {
    const request = { client: { fullName }  };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/user/client`, request,
                                           HttpUtils.getJsonContentTypeHeader());
  }
}
