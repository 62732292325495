/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "dashboard-wrapper default-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "page-header"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "welcome-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ", ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "current-date light-font"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "button", [["class", "width-200"], ["color", "primary"], ["mat-raised-button", ""], ["routerLink", "/projects/new/profile"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(13, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(14, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "mat-icon", [["aria-hidden", "false"], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(16, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, "false"], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵted(18, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_6 = "/projects/new/profile"; _ck(_v, 13, 0, currVal_6); var currVal_7 = "primary"; _ck(_v, 14, 0, currVal_7); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("DASHBOARD.HEADER.LABELS.WELCOME_HEADER")); var currVal_2 = _co.companyName; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.date, "fullDate")); _ck(_v, 9, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 14).disabled || null); var currVal_5 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 16).inline; var currVal_9 = (((i1.ɵnov(_v, 16).color !== "primary") && (i1.ɵnov(_v, 16).color !== "accent")) && (i1.ɵnov(_v, 16).color !== "warn")); _ck(_v, 15, 0, currVal_8, currVal_9); var currVal_10 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("DASHBOARD.HEADER.LABELS.CREATE_PROJECT_BUTTON_TEXT")); _ck(_v, 18, 0, currVal_10); }); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i13.DashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i13.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
