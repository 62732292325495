import { HttpErrorResponse, } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpUtils } from '../util/http-util';
import * as jwt_decode from 'jwt-decode';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ErrorUtils } from '../util/error-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./account.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, accountService) {
        this.http = http;
        this.accountService = accountService;
    }
    AuthService.prototype.signUp = function (companyName, email, password, receiveMarketingEmail, lang) {
        var request = {
            lang: lang,
            user: {
                companyName: companyName,
                email: email,
                password: password,
                receiveMarketingEmail: receiveMarketingEmail,
            },
        };
        return this.http.post(environment.api_url + "/v1/web/user/sign_up", request, HttpUtils.getJsonContentTypeHeader());
    };
    AuthService.prototype.confirmAccount = function (email, token) {
        var request = { email: email, token: token };
        return this.http.post(environment.api_url + "/v1/web/user/confirm_account", request, HttpUtils.getJsonContentTypeHeader());
    };
    AuthService.prototype.login = function (email, password, rememberMe) {
        var _this = this;
        var request = {
            user: {
                email: email,
                password: password,
                rememberMe: rememberMe,
            },
        };
        return new Observable(function (loginRequestObserver) {
            _this.http.post(environment.api_url + "/v1/web/user/sign_in", request, HttpUtils.getJsonContentTypeHeader()).subscribe(function (result) {
                _this.storeAuthInfo(result.payload.accessToken, result.payload.refreshToken);
                loginRequestObserver.next({ loggedIn: true });
            }, function (errorResponse) {
                _this.clearAuthInfo();
                loginRequestObserver.next({ error: errorResponse.error, loggedIn: false });
            }, function () {
                loginRequestObserver.complete();
            });
        });
    };
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        var refreshToken = localStorage.getItem("refresh_token" /* refreshToken */);
        var request = {
            refreshToken: refreshToken,
            accessToken: localStorage.getItem("access_token" /* accessToken */),
        };
        return this.http.post(environment.api_url + "/v1/web/user/refresh", request, HttpUtils.getJsonContentTypeHeader())
            .pipe(tap(function (response) {
            _this.storeAuthInfo(response.payload.accessToken, refreshToken);
        }));
    };
    AuthService.prototype.storeAuthInfo = function (accessToken, refreshToken) {
        var tokenPayload = jwt_decode(accessToken);
        localStorage.setItem("access_token" /* accessToken */, accessToken);
        localStorage.setItem("refresh_token" /* refreshToken */, refreshToken);
        localStorage.setItem("auth_token_expire" /* expire */, tokenPayload.exp);
        localStorage.setItem("auth_role" /* role */, tokenPayload.role);
        this.accountService.getAccount().subscribe(function (userResponse) {
            localStorage.setItem("email" /* email */, userResponse.payload.email);
            localStorage.setItem("company_name" /* companyName */, userResponse.payload.companyName);
            localStorage.setItem("logoKey" /* logoKey */, userResponse.payload.logoKey);
        });
    };
    AuthService.prototype.clearAuthInfo = function () {
        localStorage.clear();
    };
    AuthService.prototype.isSessionExpired = function () {
        try {
            var jwtExp = localStorage.getItem("auth_token_expire" /* expire */);
            if (jwtExp == null)
                return true;
            return (Number(jwtExp) * 1000) < new Date().getTime();
        }
        catch (error) {
            return true;
        }
    };
    AuthService.prototype.isLoggedIn = function () {
        return localStorage.getItem("access_token" /* accessToken */) && !this.isSessionExpired();
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccountService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
var AuthenticationHttpInterceptor = /** @class */ (function () {
    function AuthenticationHttpInterceptor(authService) {
        this.authService = authService;
        this.isRefreshing = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
    }
    AuthenticationHttpInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var accessToken = localStorage.getItem("access_token" /* accessToken */);
        if (accessToken != null) {
            // tslint:disable-next-line:no-parameter-reassignment
            request = this.addToken(request, accessToken);
        }
        return next.handle(request).pipe(catchError(function (errorResponse, caught) {
            if (errorResponse instanceof HttpErrorResponse &&
                ErrorUtils.containsErrorCode(errorResponse.error, 1009 /* ACCESS_TOKEN_EXPIRED */)) {
                return _this.handle401Error(request, next);
            }
            return throwError(errorResponse);
        }));
    };
    AuthenticationHttpInterceptor.prototype.addToken = function (request, token) {
        return request.clone({ setHeaders: { 'x-auth-token': token } });
    };
    AuthenticationHttpInterceptor.prototype.handle401Error = function (request, next) {
        var _this = this;
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(switchMap(function (refreshTokenResponse) {
                _this.isRefreshing = false;
                var accessToken = refreshTokenResponse.payload.accessToken;
                _this.refreshTokenSubject.next(accessToken);
                return next.handle(_this.addToken(request, accessToken));
            }));
        } // else
        return this.refreshTokenSubject.pipe(filter(function (token) { return token != null; }), take(1), switchMap(function (accessToken) {
            return next.handle(_this.addToken(request, accessToken));
        }));
    };
    return AuthenticationHttpInterceptor;
}());
export { AuthenticationHttpInterceptor };
