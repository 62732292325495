import { Component, Input } from '@angular/core';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { ProjectSteps } from 'src/app/model/enums';

@Component({
  selector: 'app-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrls: ['./project-sidebar.component.scss'],
})

export class ProjectSidebarComponent {
  @Input() title;
  public projectSteps: typeof ProjectSteps = ProjectSteps;

  constructor(private projectDataService: ProjectDataService) { }

  // function to disable/enable the steps in project navigation
  isTabEnabled(step) {
    return this.getEnabledTabStates(this.projectDataService.projectData)[step];
  }

  // Get enabled tab states.
  public getEnabledTabStates(project) {
    const tabStates: boolean[] = [];
    const isSecurityEnabled = project.suppliers && project.suppliers.length > 0;
    tabStates[ProjectSteps.PROJECT_PROFILE] = true;
    tabStates[ProjectSteps.SUPPLIERS] = !!project._id;
    tabStates[ProjectSteps.SECURITY] = isSecurityEnabled;
    tabStates[ProjectSteps.QUALITY] = project.securityOptions && isSecurityEnabled;
    tabStates[ProjectSteps.GENERATE] = project.questions && project.questions.length > 0;
    return tabStates;
  }
}
