import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectsPayload, ProjectsQueryParams } from '../model/project/projects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ProjectsService {

  constructor(private http: HttpClient) {

  }

  getProjects(query: ProjectsQueryParams): Observable<ProjectsPayload> {

    let queryParams = new HttpParams();

    Object.keys(query).forEach((key) => {
      queryParams = queryParams.append(key, query[key]);
    });

    return this.http.get<ProjectsPayload>(`${environment.api_url}/v1/web/projects`, { params: queryParams });
  }
}
