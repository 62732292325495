import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectStepComponent } from '../project-step-component';
import { environment } from '../../../../environments/environment';
var GenerateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GenerateComponent, _super);
    function GenerateComponent(route, snackBarService, surveyStatusService, translate) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.snackBarService = snackBarService;
        _this.surveyStatusService = surveyStatusService;
        _this.translate = translate;
        _this.testUrl = environment.test_url;
        _this.panelistId = '{id}';
        if (_this.route.snapshot.data.project instanceof Observable) {
            _this.route.snapshot.data.project.subscribe(function (project) {
                _this.project = project;
            });
        }
        return _this;
    }
    GenerateComponent.prototype.ngOnInit = function () {
        /**
         * If the `id` of the project is available, that's means that the project is
         * created and accessible in the application by navigating between the different pages.
         * Conditions/validations are done in the ProjectResolver.
         */
        if (this.project._id) {
            this.setGenerateFormData();
        }
        this.fetchSurveyStatus();
    };
    GenerateComponent.prototype.fetchSurveyStatus = function () {
        var _this = this;
        this.surveyStatusService.getStatus().subscribe(function (res) {
            _this.setRedirectLinks(res.payload.surveyStatus);
        }, function () {
            _this.handleGetStatusError();
        });
    };
    // Display error when the get suppliers request fails
    GenerateComponent.prototype.handleGetStatusError = function () {
        this.snackBarService.errorWithClose('GENERATE.MESSAGES.GET_STATUS_FAILED');
    };
    GenerateComponent.prototype.setGenerateFormData = function () {
        this.languagesList = this.project.languages;
        this.suppliersList = this.project.suppliers;
        // At page load, get/set the first supplier to entrance link (default)
        this.selectedSupplier = this.suppliersList[0];
        this.setEntranceLinks();
    };
    GenerateComponent.prototype.updateEntranceLinks = function () {
        this.setEntranceLinks();
    };
    GenerateComponent.prototype.setEntranceLinks = function () {
        var e_1, _a;
        var entranceLinks = [];
        var supplierId = this.selectedSupplier.supplierId || '';
        var _loop_1 = function (language) {
            var lang = language.langCode;
            // tslint:disable-next-line:max-line-length
            var url = this_1.testUrl + "/questionnaire?id=" + this_1.project._id + "&panelistId=" + this_1.panelistId + "&supplierId=" + supplierId + "&lang=" + lang;
            var isQuestionAvailable = this_1.project.questions[0].text.some(function (text) {
                return text.langCode === lang.toLowerCase() && text.text !== '';
            });
            entranceLinks.push({ url: url, active: isQuestionAvailable, label: this_1.translate.instant("LANGUAGES." + lang) });
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.languagesList), _c = _b.next(); !_c.done; _c = _b.next()) {
                var language = _c.value;
                _loop_1(language);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        entranceLinks.sort(function (a, b) { return a.label > b.label ? 1 : -1; });
        this.entranceLinksList = entranceLinks;
    };
    GenerateComponent.prototype.setRedirectLinks = function (surveyStatusList) {
        var e_2, _a;
        var redirectLinks = [];
        try {
            for (var surveyStatusList_1 = tslib_1.__values(surveyStatusList), surveyStatusList_1_1 = surveyStatusList_1.next(); !surveyStatusList_1_1.done; surveyStatusList_1_1 = surveyStatusList_1.next()) {
                var status_1 = surveyStatusList_1_1.value;
                if (status_1.link) {
                    var url = this.testUrl + "/survey-outcome?id=" + this.project._id + "&panelistId=" + this.panelistId + "&status=" + status_1.code;
                    redirectLinks.push({ url: url, status: status_1.name });
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (surveyStatusList_1_1 && !surveyStatusList_1_1.done && (_a = surveyStatusList_1.return)) _a.call(surveyStatusList_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.redirectLinksList = redirectLinks;
    };
    return GenerateComponent;
}(ProjectStepComponent));
export { GenerateComponent };
