import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SecurityOptionsResponse } from '../model/project/Responses';
import { SecurityOption } from '../model/project/options';

@Injectable({
  providedIn: 'root',
})
export class SecurityOptionsService {

  public securityOptions: SecurityOption[];
  constructor(private http: HttpClient) {}

  public getAll(): Observable<SecurityOption[]> {
    return this.http.get<SecurityOptionsResponse>(`${environment.api_url}/v1/web/security_options`, HttpUtils.getJsonContentTypeHeader())
      .pipe(
        map((response) => {
          this.securityOptions = response.payload.securityOptions;
          return response.payload.securityOptions;
        }),
        catchError(error => throwError(error)),
    );
  }
}
