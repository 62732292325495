<div class="register-wrapper mt-100" fxLayout="row" fxLayoutAlign="space-evenly">
  <img fxHide.sm fxHide.xs fxFlex="45" src="assets/img/authentication.png" alt="authentication-image" class="mt-50 height-500">

  <div fxFlex="30" fxFlex.sm="70" fxFlex.xs="90" class="box">
    <mat-card class="box auth-form-card">
      <mat-card-header>
        <mat-card-title>{{ 'REGISTER.TITLE' | translate }}</mat-card-title>
      </mat-card-header>

      <form class="form" [formGroup]="registerForm" (ngSubmit)="onRegister()">
        <mat-card-content>
          <mat-form-field class="width-100">
            <input matInput formControlName="company" placeholder="{{ 'REGISTER.LABELS.COMPANY' | translate }}" autocomplete="company-name">
            <mat-error *ngIf="registerForm.get('company').errors?.required">
              {{ 'REGISTER.MESSAGES.COMPANY_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('company').errors?.maxlength">
              {{ 'REGISTER.MESSAGES.COMPANY_MAX_LENGTH' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="width-100">
            <input matInput formControlName="email" placeholder="{{ 'REGISTER.LABELS.EMAIL' | translate }}" autocomplete="username">
            <mat-error *ngIf="registerForm.get('email').errors?.required">
              {{ 'REGISTER.MESSAGES.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('email').errors?.email">
              {{ 'REGISTER.MESSAGES.EMAIL_INVALID' | translate }}
            </mat-error>
          </mat-form-field>

          <input-password-confirm [parentForm]="registerForm"></input-password-confirm>

          <div class="spacer"></div>
          <mat-checkbox formControlName="terms" color="primary">
            {{ 'REGISTER.LABELS.TERMS_CONDITIONS_1' | translate }}
            <a routerLink="/terms">{{ 'REGISTER.LABELS.TERMS_CONDITIONS_LINK' | translate }} </a>
          </mat-checkbox>
          <div class="spacer"></div>
          <mat-checkbox formControlName="offers" color="primary">
            {{ 'REGISTER.LABELS.OFFERS_UPDATES_1' | translate }}
            <br/>
            {{ 'REGISTER.LABELS.OFFERS_UPDATES_2' | translate }}
          </mat-checkbox>


        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit" [disabled]="!this.registerForm.valid">{{ 'REGISTER.LABELS.SIGN_UP' | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card>
    <br/>
    <div class="login-container">
      <a routerLink="/login">{{ 'REGISTER.LABELS.SIGN_IN' | translate }}</a>
    </div>
  </div>
</div>
