// tslint:disable-next-line:variable-name
export const TimeUtils = {
  convertMiliseconds(miliseconds) {
    const seconds = Math.floor(miliseconds / 1000) % 60;
    const minutes = Math.floor(miliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(miliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(miliseconds / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  },
};
