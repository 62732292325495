import { EventEmitter } from '@angular/core';
import { DEFAULT_LANG_CODE } from '../model/project/languages';
import { STATUS_OPTIONS, DEFAULT_EXPECTED_DR, DEFAULT_EXPECTED_OQ, DEFAULT_EXPECTED_IR } from '../model/project/options';
import * as i0 from "@angular/core";
var ProjectDataService = /** @class */ (function () {
    function ProjectDataService() {
        this.project = this.defaultProjectData();
        this.receivedProject = new EventEmitter();
    }
    ProjectDataService.prototype.resetProjectData = function () {
        this.projectData = this.defaultProjectData();
        this.project = this.projectData;
        return this.project;
    };
    Object.defineProperty(ProjectDataService.prototype, "projectData", {
        get: function () {
            return this.project;
        },
        set: function (project) {
            // The Object.assign is to prevent error with read only property
            this.project = Object.assign({}, project);
            this.receivedProject.emit(this.project);
        },
        enumerable: true,
        configurable: true
    });
    ProjectDataService.prototype.defaultProjectData = function () {
        return {
            title: '',
            client: null,
            status: STATUS_OPTIONS.DEFAULT,
            projectManager: null,
            endDate: '',
            duration: '',
            maxBudget: null,
            setRoom: null,
            type: '',
            surveyDuration: '',
            bidTarget: null,
            piiCompliance: false,
            languages: [{
                    langCode: DEFAULT_LANG_CODE,
                    surveyUrl: '',
                    finalUrl: '',
                    completeUrl: '',
                }],
            logoKey: '',
            suppliers: [],
            securityOptions: [],
            questions: [],
            expectedDR: DEFAULT_EXPECTED_DR,
            expectedOQ: DEFAULT_EXPECTED_OQ,
            expectedIR: DEFAULT_EXPECTED_IR,
            expectedProjectCost: 0,
        };
    };
    ProjectDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectDataService_Factory() { return new ProjectDataService(); }, token: ProjectDataService, providedIn: "root" });
    return ProjectDataService;
}());
export { ProjectDataService };
