/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ask-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./ask-dialog.component";
var styles_AskDialogComponent = [i0.styles];
var RenderType_AskDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AskDialogComponent, data: {} });
export { RenderType_AskDialogComponent as RenderType_AskDialogComponent };
export function View_AskDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "dialog-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "f-28 f-arial c-dark-blue mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "f-arial c-dark-blue description"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "f-arial bold inverse"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(12, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "dark-blue"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_6 = false; _ck(_v, 12, 0, currVal_6); var currVal_11 = true; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.description; _ck(_v, 7, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11).disabled || null); var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 12).ariaLabel || null); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = _co.data.cancelButton; _ck(_v, 13, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 15).disabled || null); var currVal_9 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_10 = (i1.ɵnov(_v, 16).ariaLabel || null); _ck(_v, 14, 0, currVal_8, currVal_9, currVal_10); var currVal_12 = _co.data.confirmButton; _ck(_v, 17, 0, currVal_12); }); }
export function View_AskDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ask-dialog", [], null, null, null, View_AskDialogComponent_0, RenderType_AskDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.AskDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var AskDialogComponentNgFactory = i1.ɵccf("app-ask-dialog", i7.AskDialogComponent, View_AskDialogComponent_Host_0, {}, {}, []);
export { AskDialogComponentNgFactory as AskDialogComponentNgFactory };
