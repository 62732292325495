<div class="dashboard-wrapper default-padding">
  <div class="page-header" fxLayout="row" >
    <span>
      <p class="welcome-text">{{'DASHBOARD.HEADER.LABELS.WELCOME_HEADER' | translate}}, {{companyName}}</p>
      <p class="current-date light-font">{{date | date: 'fullDate'}}</p>
    </span>

    <span>
      <button mat-raised-button color="primary" class="width-200" routerLink="/projects/new/profile">
        <mat-icon aria-hidden="false" >add</mat-icon>
        {{ 'DASHBOARD.HEADER.LABELS.CREATE_PROJECT_BUTTON_TEXT' | translate }}
      </button>
    </span>
  </div>
</div>
