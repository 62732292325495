import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormGroupDirective } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { FileUploadService } from '../../services/file-upload.service';
import { ConfirmDialogComponent } from '../../widgets/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SnackBarService } from '../../services/snack-bar.service';
import { AuthService, LocalStorageKey } from '../../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  public updateProfileForm: FormGroup;
  public updatePasswordForm: FormGroup;
  public imageFormKey: string = 'logoKey';
  // Dialog data variables
  private title: string;
  private description: string;
  private confirmMessage: string;
  private cancelButton: string;
  private confirmButton: string;
  // Image source url
  public imageSrc: string;

  constructor(private accountService: AccountService,
              public dialog: MatDialog,
              private snackBarService: SnackBarService,
              private translate: TranslateService,
              private router: Router,
              private fileUploadService: FileUploadService,
              private authService: AuthService) {

    this.updateProfileForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      companyName: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      logoKey: new FormControl(''),
    });

    this.updatePasswordForm = new FormGroup({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      currentPassword: new FormControl('', Validators.required),
    });

    this.imageSrc = '';
    // Dialog Content
    this.dialogData();
  }

  ngOnInit() {
    // Get the logged in users data  to disaply in the form
    this.accountService.getAccount().subscribe(
      (result) => {
        this.setInfo(result.payload);
        if (result.payload.logoKey !== undefined && result.payload.logoKey !== '') {
          this.getImageUrl(result.payload.logoKey);
        }
      },
      (error) => {
        this.handleError(error);
      });
  }

  // Open confirm dialog box
  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: this.title,
        description: this.description,
        confirm_message: this.confirmMessage,
        cancel_button: this.cancelButton,
        confirm_button: this.confirmButton},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteAccount();
      }
    });
  }

  private dialogData() {
    this.translate.get(['PROFILE.DIALOG'],
                      ).subscribe((translations) => {
                        const dialogContent = translations['PROFILE.DIALOG'];
                        this.title =  dialogContent.TITLE;
                        this.description = dialogContent.DESCRIPTION;
                        this.confirmMessage = dialogContent.CONFIRM_MESSAGE;
                        this.cancelButton = dialogContent.CANCEL_BUTTON;
                        this.confirmButton = dialogContent.CONFIRM_BUTTON;
                      });
  }

  // Update user info. Email and Company Name
  updateInfo() {
    const form = this.updateProfileForm;
    if (form.valid) {
      const companyName = form.get('companyName').value;
      const logoKey = form.get('logoKey').value;
      this.accountService.updateInfo(companyName,
                                     form.get('logoKey').value).subscribe(
        (infoUpdated) => {
          this.handleInfoSuccessMessage();
          localStorage.setItem(LocalStorageKey.companyName, companyName);
          localStorage.setItem(LocalStorageKey.logoKey, logoKey);
        },
        (error) => {
          this.handleError(error);
        });
    }
  }

  // Update user password with current password.
  updatePassword(formDirective: FormGroupDirective) {
    const form = this.updatePasswordForm;
    if (form.valid) {
      this.accountService.updatePassword(form.get('password').value,
                                         form.get('currentPassword').value).subscribe(
        (passwordUpdated) => {
          this.handlePasswordSuccessMessage();
          formDirective.resetForm();
        },
        (error) => {
          this.handleError(error);
        });
    }
  }

  // Delete user account and redirect to login page.
  deleteAccount() {
    this.accountService.deleteAccount().subscribe(
      (accountDeleted) => {
        this.authService.clearAuthInfo();
        this.handleDeleteAccountSuccessMessage();
        this.router.navigate(['/login']);
      },
      (error) => {
        this.handleError(error);
      });
  }

  private getImageUrl(key) {
    this.fileUploadService.getImageUrl(key).subscribe(
      (res) => {
        this.imageSrc = res.payload.url;
        this.updateProfileForm.controls['logoKey'].setValue(key);
      },
      (error) => {
        this.handleError(error);
      },
    );
  }

  // Preload values in the form for email and comapny name
  private setInfo(payload) {
    this.updateProfileForm.patchValue({
      email: payload.email,
      companyName: payload.companyName,
    });
  }

  // Generic error handler
  private handleError(error: any) {
    this.snackBarService.errorWithClose('PROFILE.MESSAGES.REQUEST_FAILED');
  }

  // Success message for account infor update
  private handleInfoSuccessMessage() {
    this.snackBarService.successWithClose('PROFILE.ACCOUNT_INFORMATION.MESSAGES.UPDATE_SUCCESS');
  }

  // Success message for password update
  private handlePasswordSuccessMessage() {
    this.snackBarService.successWithClose('PROFILE.UPDATE_PASSWORD.MESSAGES.UPDATE_SUCCESS');
  }

  // Success message for password update
  private handleDeleteAccountSuccessMessage() {
    this.snackBarService.successWithClose('PROFILE.MESSAGES.ACCOUNT_DELETED');
  }
}
