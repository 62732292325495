<div class="reset-password-wrapper mt-100" fxLayout="row" fxLayoutAlign="space-evenly">
  <img fxHide.sm fxHide.xs fxFlex="45" src="assets/img/authentication.png" alt="authentication-image" class="mt-50 height-500">

  <mat-card  class="box auth-form-card" fxFlex="30" fxFlex.sm="70" fxFlex.xs="90">
    <mat-card-header>
      <mat-card-title>{{ 'RESET_PASSWORD.TITLE' | translate }}</mat-card-title>
    </mat-card-header>

    <form class="form" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        {{ 'RESET_PASSWORD.DESCRIPTION' | translate }}
          <input-password-confirm [parentForm]="resetPasswordForm"></input-password-confirm>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit" [disabled]="!this.resetPasswordForm.valid">{{ 'RESET_PASSWORD.LABELS.SUBMIT_BUTTON' | translate }}</button>
      </mat-card-actions>
    </form>
  </mat-card>

</div>
