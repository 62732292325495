import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements AfterViewInit, OnDestroy {

  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() selected: boolean;
  @Input() currentLangCode: string;
  @Output() projectQuestionSelect = new EventEmitter<number>();

  @ViewChild('textInput', { static: false }) textInputRef: ElementRef;

  private valueChangesSubscription: Subscription;

  constructor() { }

  get text(): FormArray { return this.formGroup.get('text') as FormArray; }

  ngAfterViewInit() {
    this.valueChangesSubscription = this.formGroup.controls['text'].valueChanges.subscribe((value) => {
      this.formGroup.controls['text'].setValue(value, { emitEvent: false });

      // TODO it seems to be a bug in angular (related to https://github.com/angular/angular/issues/13792)
      // The displayed input value is not refreshed even though the formControl holds the correct updated value
      // Using ChangeDetectorRef or ngZone to let the DOM know that it needs to refresh its view have failed as
      // well as moving this method somewhere else.
      if (value !== this.textInputRef.nativeElement.value) {
        this.textInputRef.nativeElement.value = value[0].text;
      }
    });
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  questionSelected() {
    this.projectQuestionSelect.emit(this.index);
  }
}
