import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectStepComponent } from '../project-step-component';
import { Project } from '../../../model/project/project';
import { Supplier, Languages, SurveyStatus } from '../../../model/Common';
import { environment } from '../../../../environments/environment';
import { SnackBarService } from '../../../services/snack-bar.service';
import { SurveyStatusService } from '../../../services/survey-status.service';
import { TranslateService } from '@ngx-translate/core';
import { EntranceLinks, TerminateLinks } from 'src/app/model/project/generate';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss'],
})
export class GenerateComponent extends ProjectStepComponent implements OnInit {

  protected project: Project;
  public languagesList: Languages[];
  public suppliersList: Supplier[];
  public selectedSupplier: Supplier;
  public entranceLinksList: EntranceLinks[];
  public redirectLinksList: any[];
  public testUrl: string = environment.test_url;
  public panelistId: string = '{id}';

  constructor(private route: ActivatedRoute,
              private snackBarService: SnackBarService,
              private surveyStatusService: SurveyStatusService,
              private translate: TranslateService) {
    super();

    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }
  }

  ngOnInit() {
    /**
     * If the `id` of the project is available, that's means that the project is
     * created and accessible in the application by navigating between the different pages.
     * Conditions/validations are done in the ProjectResolver.
     */
    if (this.project._id) {
      this.setGenerateFormData();
    }

    this.fetchSurveyStatus();
  }

  private fetchSurveyStatus() {
    this.surveyStatusService.getStatus().subscribe(
      (res) => {
        this.setRedirectLinks(res.payload.surveyStatus);
      },
      () => {
        this.handleGetStatusError();
      },
    );
  }

  // Display error when the get suppliers request fails
  private handleGetStatusError() {
    this.snackBarService.errorWithClose('GENERATE.MESSAGES.GET_STATUS_FAILED');
  }

  private setGenerateFormData() {
    this.languagesList = this.project.languages;
    this.suppliersList = this.project.suppliers;

    // At page load, get/set the first supplier to entrance link (default)
    this.selectedSupplier = this.suppliersList[0];

    this.setEntranceLinks();
  }

  public updateEntranceLinks() {
    this.setEntranceLinks();
  }

  private setEntranceLinks() {
    const entranceLinks: EntranceLinks[] = [];
    const supplierId: string = this.selectedSupplier.supplierId || '';

    for (const language of this.languagesList) {
      const lang = language.langCode;
      // tslint:disable-next-line:max-line-length
      const url = `${this.testUrl}/questionnaire?id=${this.project._id}&panelistId=${this.panelistId}&supplierId=${supplierId}&lang=${lang}`;
      const isQuestionAvailable = this.project.questions[0].text.some((text) => {
        return text.langCode === lang.toLowerCase() && text.text !== '';
      });

      entranceLinks.push({ url, active: isQuestionAvailable, label: this.translate.instant(`LANGUAGES.${lang}`) });
    }

    entranceLinks.sort((a, b) => a.label > b.label ? 1 : -1);

    this.entranceLinksList = entranceLinks;
  }

  private setRedirectLinks(surveyStatusList: SurveyStatus[]) {
    const redirectLinks: TerminateLinks[] = [];

    for (const status of surveyStatusList) {
      if (status.link) {
        const url = `${this.testUrl}/survey-outcome?id=${this.project._id}&panelistId=${this.panelistId}&status=${status.code}`;

        redirectLinks.push({ url, status: status.name });
      }
    }

    this.redirectLinksList = redirectLinks;
  }
}
