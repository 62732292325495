import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import * as ProjectsAction from '../actionstypes/projects/projects.actiontype';
import * as ClientsAction from '../actionstypes/clients/clients.actiontype';
import { RequestStatusAction } from '../actionstypes/clients/request-status.actiontype';
import * as RequestStatus from '../actionstypes/request-status/request-status.actiontype';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
var ClientsEffect = /** @class */ (function () {
    function ClientsEffect(actions$, clients) {
        var _this = this;
        this.actions$ = actions$;
        this.clients = clients;
        this.loadClients$ = this.actions$.pipe(ofType(ProjectsAction.ProjectsAction.Load), exhaustMap(function (action) { return _this.clients.getClients().pipe(map(function (clients) { return (new ClientsAction.Get(clients)); }), catchError(function () { return of(new RequestStatus.RequestStatus((RequestStatusAction.Error))); })); }));
        this.requestClients$ = this.actions$.pipe(ofType(ClientsAction.ClientsAction.Get), mergeMap(function () { return of(new RequestStatus.RequestStatus(RequestStatusAction.Success)); }));
    }
    tslib_1.__decorate([
        Effect()
    ], ClientsEffect.prototype, "loadClients$", void 0);
    tslib_1.__decorate([
        Effect()
    ], ClientsEffect.prototype, "requestClients$", void 0);
    return ClientsEffect;
}());
export { ClientsEffect };
