import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SecurityOptionsService = /** @class */ (function () {
    function SecurityOptionsService(http) {
        this.http = http;
    }
    SecurityOptionsService.prototype.getAll = function () {
        var _this = this;
        return this.http.get(environment.api_url + "/v1/web/security_options", HttpUtils.getJsonContentTypeHeader())
            .pipe(map(function (response) {
            _this.securityOptions = response.payload.securityOptions;
            return response.payload.securityOptions;
        }), catchError(function (error) { return throwError(error); }));
    };
    SecurityOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityOptionsService_Factory() { return new SecurityOptionsService(i0.ɵɵinject(i1.HttpClient)); }, token: SecurityOptionsService, providedIn: "root" });
    return SecurityOptionsService;
}());
export { SecurityOptionsService };
