import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
var EventManagerService = /** @class */ (function () {
    function EventManagerService() {
        var _this = this;
        this.listeners = {};
        this.eventsSubject = new Subject();
        this.events = new EventEmitter();
        this.events.subscribe(function (_a) {
            var e_1, _b;
            var name = _a.name, args = _a.args;
            if (_this.listeners[name]) {
                try {
                    for (var _c = tslib_1.__values(_this.listeners[name]), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var listener = _d.value;
                        listener.apply(void 0, tslib_1.__spread(args));
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    }
    EventManagerService.prototype.on = function (name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }
        this.listeners[name].push(listener);
    };
    EventManagerService.prototype.off = function (name, listener) {
        if (this.listeners[name] && this.listeners[name].indexOf(listener) > -1) {
            this.listeners[name] = this.listeners[name].filter(function (x) { return x !== listener; });
        }
    };
    EventManagerService.prototype.broadcast = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.eventsSubject.next({
            name: name,
            args: args,
        });
    };
    return EventManagerService;
}());
export { EventManagerService };
