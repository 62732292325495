import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { projectsRootReducer } from './projects/projects.root.reducer';
import { clientsRootReducer } from './clients/clients.root.reducer';
import { RootProjects } from '../../model/project/projects';
import { InjectionToken } from '@angular/core';
import { RootClients } from 'src/app/model/client/client';

export interface AppState {
  projects: RootProjects;
  clients: RootClients;
}

export const reducers: ActionReducerMap<AppState> = {
  projects: projectsRootReducer,
  clients: clientsRootReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [];

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');
