import { Component, OnInit } from '@angular/core';
import { LocalStorageKey } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {
  public date: number;
  public companyName: string;

  ngOnInit() {
    this.date = Date.now();
    this.companyName = localStorage.getItem(LocalStorageKey.companyName);
  }
}
