import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../widgets/confirm-dialog/confirm-dialog.component';
import { Observable, of  } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStepComponent } from '../components/project/project-step-component';

@Injectable({
  providedIn: 'root',
})
export class ProjectGuardService implements CanDeactivate<ProjectStepComponent> {

  constructor(private dialog: MatDialog,
              private translate: TranslateService) {}

  canDeactivate(component: ProjectStepComponent): Observable<boolean> {
    if (component.hasUnsavedChanges()) {
      return new Observable<boolean>((requestObserver: any) => {
        this.translate.get('UNSAVED_DIALOG_DATA').subscribe((translations) => {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
              title: translations['TITLE'],
              description: translations['DESCRIPTION'],
              cancel_button: translations['CANCEL_BUTTON'],
              confirm_button: translations['CONFIRM_BUTTON'],
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              return requestObserver.next(true);
            }

            if (document.activeElement) {
              (<HTMLElement>document.activeElement).blur();
            }

            return requestObserver.next(false);
          });
        });
      });
    }
    return of(true);
  }
}
