<div class="answer-container" fxLayout="row" fxLayoutAlign="center center" >
  <mat-icon fxFlex="8" class="drag-icon c-detect-bg pl-5">drag_indicator</mat-icon>
  <div fxFlex class="input-container" fxLayout="row" fxLayoutAlign="center center" [formGroup]="formGroup">
    <div class="content"
         fxFlex="70"
         fxFlex.lt-lg="65"
         formArrayName="text">
      <div class="height-100"
           fxFlex="100"
           *ngFor="let txt of text.controls; index as i"
           [formGroupName]="i"
           [hidden]="txt.value.langCode !== currentLangCode"
      >
        <input matInput
               name="text"
               class="answer-text-input f-arial"
               formControlName="text"
               autocomplete="answer-text"
               placeholder="{{ 'QUESTION.LABELS.ANSWER_PLACE_HOLDER' | translate }}"
        >
      </div>
    </div>
    <mat-select fxFlex class="select-input"
                name="outcome"
                formControlName="outcome"
                required
                [placeholder]="'QUESTION.LABELS.OUTCOME' | translate"
                *ngIf="!isEditModeDisabled"
                (selectionChange)="changeOutcome()"
    >
      <mat-option *ngFor="let outcome of outcomeOptions" [value]="outcome.value">{{outcome.labelKey | translate}}</mat-option>
    </mat-select>
    <input fxFlex
           matInput
           *ngIf="isEditModeDisabled"
           value="{{ currentOutcomeLabelKey | translate }}"
           readonly
    >
  </div>
  <button mat-icon-button
          type="button"
          class="remove-button"
          (click)="removeAnswer()"
          *ngIf="!isEditModeDisabled"
  >
    <span  class="f-18 font-awesome c-detect-bg"></span>
  </button>
</div>

<div class="answer-container quota-container">
  <a *ngIf="getOutcome() && !isInputQuota && !isEditModeDisabled" href="#" (click)="addQuota($event)" class="add-quota-plus ">{{ 'QUESTION.LABELS.ADD_QUOTA' | translate }}</a>
  <div *ngIf="getOutcome() && isInputQuota" class="input-container" [ngClass]="isEditModeDisabled ? 'add-quota-disabled' : 'add-quota'">
    <div class="add-quota-lable">{{ 'QUESTION.LABELS.QUOTA' | translate }}</div>
    <div [formGroup]="formGroup">
      <input matInput name="quota" required [readonly]="isEditModeDisabled" formControlName="quota" type="number" class="answer-text-input quota-input f-arial" [value]="getQuota()" (input)="changeQuota($event.target.value)">
    </div>
  </div>
  <button *ngIf="getOutcome() && isInputQuota && !isEditModeDisabled" mat-icon-button type="button" class="remove-button quota-close" (click)="deleteQuota()">
    <span  class="f-18 font-awesome c-detect-bg quota"></span>
  </button>
</div>
