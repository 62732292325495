<div class="projects-list-wrapper">
  <div class="page-header default-padding" fxLayout="row"  fxLayout.lt-sm="column">
    <span>
      <p class="project-text" *ngIf="!(projectsAreInError$ | async) && (projectsAreLoaded$ | async); else projects">
        {{'PROJECT_LIST.HEADER.LABELS.PROJECTS' | translate}} ( {{(projects$ | async)?.length}} )
      </p>
      <ng-template #projects let-user>
        <p class="project-text">
          {{'PROJECT_LIST.HEADER.LABELS.PROJECTS' | translate}}
        </p>
      </ng-template>
    </span>

    <span>
      <button mat-raised-button color="primary" class="width-200" routerLink="/projects/new/profile">
        <mat-icon aria-hidden="false">add</mat-icon>
        {{ 'PROJECT_LIST.HEADER.LABELS.CREATE_PROJECT_BUTTON_TEXT' | translate }}
      </button>
    </span>
  </div>
  <div class="projects-list default-padding">
    <app-preloader [loader]="!(projectsAreLoaded$ | async)">
      <div class="filters" fxLayout="row" fxLayout.lt-sm="column">
        <div fxFlex fxFlex.gt-sm="66" fxLayoutAlign.xs="center center" fxLayout.gt-md="row" fxLayout.lt-md="column">
          <div fxFlex fxFlex.gt-md="40" fxFlex.gt-lg="30" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign.xs="center center">
            <span class="filter-label">Filter by:</span>
            <div>
              <mat-form-field class="filter">
                <mat-label>{{ 'PROJECT_LIST.FILTERS.CLIENT.LABEL' | translate }}</mat-label>
                <mat-select (selectionChange)="filterSearch()" [(ngModel)]="client" [disabled]="!(projectsAreLoaded$ | async)">
                  <mat-option>{{ 'PROJECT_LIST.FILTERS.CLIENT.PLACEHOLDER' | translate }}</mat-option>
                  <mat-option *ngFor="let client of clientsArray$ | async" [value]="client">
                    {{client}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxFlex fxFlex.gt-md="60" fxFlex.gt-lg="70" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="end" fxLayoutAlign.xs="center center">
            <span class="filter-label filter-status-label">Status:</span>
            <div>
              <mat-form-field class="filter">
                <mat-label>{{ 'PROJECT_LIST.FILTERS.STATUS.LABEL' | translate }}</mat-label>
                <mat-select (selectionChange)="filterSearch()" [(ngModel)]="status" [disabled]="!(projectsAreLoaded$ | async)">
                  <mat-option>{{ 'PROJECT_LIST.FILTERS.STATUS.PLACEHOLDER' | translate }}</mat-option>
                  <mat-option *ngFor="let stat of statusArray" [value]="stat">
                    {{stat}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="empty"></div>
          </div>
        </div>
        <div fxFlex fxFlex.gt-sm="33" fxLayoutAlign="end none" fxLayoutAlign.xs="center">
          <mat-form-field class="example-form-field" (keydown.enter)="filterSearch()">
            <input matInput type="text" placeholder="{{ 'PROJECT_LIST.FILTERS.SEARCH' | translate }}" [(ngModel)]="text">
            <button mat-button *ngIf="text" matSuffix mat-icon-button (click)="text=''; filterSearch()" class="search-clear-icon">
              <mat-icon>close</mat-icon>
            </button>
            <button mat-button matSuffix mat-icon-button (click)="filterSearch()" class="search-clear-icon">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="(projectsAreLoaded$ | async)">
        <div *ngIf="!(projectsAreInError$ | async); else error">
          <div *ngIf="(projects$ | async)?.length; else noProjects">
            <app-project-summary-box
              *ngFor="let project of projects$ | async"
              [project]="project"
              [isBoxHover]="true"
              [isBoxBorder]="true"
              [isViewReportBtn]="true"
              [isProjectHealth]="true"
            ></app-project-summary-box>
          </div>
        </div>
        <ng-template #noProjects>
          <div *ngIf="client === undefined && status === undefined && text === ''; else noProjectsFilter">
            <div class="results">
              <span class="title">{{ 'PROJECT_LIST.EMPTY_TITLE' | translate }}</span>
              <p class="text">{{ 'PROJECT_LIST.EMPTY_TEXT' | translate }}</p>
            </div>
          </div>
          <ng-template #noProjectsFilter>
            <div class="results">
              <span class="title">{{ 'PROJECT_LIST.SEARCH_EMPTY_TITLE' | translate }}</span>
              <p class="text">{{ 'PROJECT_LIST.SEARCH_EMPTY_TEXT' | translate }}</p>
            </div>
          </ng-template>
        </ng-template>
        <ng-template #error>
          <div class="error">
            <div class="results">
              <span class="title">{{ 'PROJECT_LIST.ERROR_TITLE' | translate }}</span>
              <p class="text">{{ 'PROJECT_LIST.ERROR_TEXT' | translate }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </app-preloader>
  </div>
</div>
