import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./snack-bar.service";
import * as i3 from "@ngx-translate/core";
var ProjectService = /** @class */ (function () {
    function ProjectService(http, snackBarService, translate) {
        this.http = http;
        this.snackBarService = snackBarService;
        this.translate = translate;
    }
    ProjectService.prototype.setEditionMode = function (mode) {
        this.editionMode = mode;
    };
    ProjectService.prototype.setIsNewProject = function (isNew) {
        this.isNewModeProject = isNew;
    };
    ProjectService.prototype.storeCurrentProjectLogoUrl = function (logoKey, logoUrl) {
        this.currentProjectLogoUrl = { key: logoKey, url: logoUrl };
    };
    ProjectService.prototype.deleteCurrentProjectLogoUrl = function () {
        this.currentProjectLogoUrl = null;
    };
    ProjectService.prototype.sortLangCodesByLabel = function (langCodes) {
        var e_1, _a;
        var languagesLabel = [];
        try {
            for (var _b = tslib_1.__values(tslib_1.__spread(langCodes)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var langCode = _c.value;
                var formattedLanguage = langCode.replace(/[-_][a-z]+$/g, function (s) { return s.toUpperCase(); });
                languagesLabel.push(this.translate.instant("LANGUAGES." + formattedLanguage));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        languagesLabel.sort(function (a, b) { return a > b ? 1 : -1; });
        return languagesLabel;
    };
    ProjectService.prototype.deleteQuestions = function (projectQuestions, langCodes) {
        var e_2, _a;
        var questions = tslib_1.__spread(projectQuestions);
        var _loop_1 = function (langCode) {
            var e_3, _a;
            try {
                for (var questions_1 = (e_3 = void 0, tslib_1.__values(questions)), questions_1_1 = questions_1.next(); !questions_1_1.done; questions_1_1 = questions_1.next()) {
                    var question = questions_1_1.value;
                    question.text = question.text.filter(function (text) { return text.langCode !== langCode; });
                    for (var index in question.answers) {
                        question.answers[index].text = question.answers[index].text.filter(function (text) { return text.langCode !== langCode; });
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (questions_1_1 && !questions_1_1.done && (_a = questions_1.return)) _a.call(questions_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        };
        try {
            for (var langCodes_1 = tslib_1.__values(langCodes), langCodes_1_1 = langCodes_1.next(); !langCodes_1_1.done; langCodes_1_1 = langCodes_1.next()) {
                var langCode = langCodes_1_1.value;
                _loop_1(langCode);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (langCodes_1_1 && !langCodes_1_1.done && (_a = langCodes_1.return)) _a.call(langCodes_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return !questions[0].text.length ? [] : questions;
    };
    ProjectService.prototype.getCurrentProjectLogoUrl = function (logoKey) {
        var logoUrl = '';
        if (this.currentProjectLogoUrl &&
            this.currentProjectLogoUrl.key === logoKey) {
            logoUrl = this.currentProjectLogoUrl.url;
        }
        return logoUrl;
    };
    ProjectService.prototype.setErrorProjectState = function (hasError) {
        this.isErrorProjectState = hasError;
    };
    ProjectService.prototype.resetErrorProjectState = function () {
        this.setErrorProjectState(false);
    };
    ProjectService.prototype.handleProjectError = function () {
        var _this = this;
        var callback = function () {
            _this.resetErrorProjectState();
        };
        this.snackBarService.errorWithClose('PROJECT.MESSAGES.PROJECT_REQUEST_FAILED', null, callback);
    };
    ProjectService.prototype.hideProjectError = function () {
        this.snackBarService.hide();
    };
    ProjectService.prototype.getProjectStatsByProjectId = function (projectId) {
        return this.http.get(environment.api_url + "/v1/web/project/" + projectId + "/statistic", HttpUtils.getJsonContentTypeHeader());
    };
    ProjectService.prototype.getProject = function (projectId) {
        return this.http.get(environment.api_url + "/v1/web/project/" + projectId, HttpUtils.getJsonContentTypeHeader());
    };
    ProjectService.prototype.createProject = function (project) {
        var request = this.getProjectRequest(project);
        return this.http.post(environment.api_url + "/v1/web/project", request, HttpUtils.getJsonContentTypeHeader());
    };
    ProjectService.prototype.updateProject = function (projectUpdate) {
        // The `id` is to retrieve the current project because the backend is expecting to receive an 'id'.
        var request = { project: Object.assign({ id: projectUpdate._id }, tslib_1.__assign({}, projectUpdate)) };
        return this.http.patch(environment.api_url + "/v1/web/project", request, HttpUtils.getJsonContentTypeHeader());
    };
    ProjectService.prototype.getProjectRequest = function (project) {
        var request = {
            project: {
                title: project.title,
                client: project.client,
                status: project.status,
                projectManager: project.projectManager,
                endDate: project.endDate,
                duration: project.duration,
                maxBudget: project.maxBudget,
                setRoom: project.setRoom,
                type: project.type,
                surveyDuration: project.surveyDuration,
                bidTarget: project.bidTarget,
                piiCompliance: project.piiCompliance,
                languages: project.languages,
                logoKey: project.logoKey,
                expectedIR: project.expectedIR,
                expectedDR: project.expectedDR,
                expectedOQ: project.expectedOQ,
                audience: project.audience,
            },
        };
        return request;
    };
    ProjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SnackBarService), i0.ɵɵinject(i3.TranslateService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
export { ProjectService };
