import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService, LocalStorageKey } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,
              private location: Location) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const acceptedRoles = route.data.roles || [];
    const isErrorPage = route.component['name'] === 'PageNotFoundComponent';

    if (!this.authService.isLoggedIn()) {
      if (localStorage.getItem(LocalStorageKey.refreshToken) === null) {
        this.authService.clearAuthInfo();

        if (isErrorPage) {
          this.router.navigate(['/404'], { skipLocationChange: true });

          // Keep the wrong url in browser url.
          this.location.replaceState(state.url);

        } else {
          this.router.navigate(['/login']);
        }

        return of(false);
      }

      return new Observable<boolean>((requestObserver:any) => {
        this.authService.refreshToken().subscribe(
          (result) => {
            return requestObserver.next(this.checkRole(acceptedRoles));
          },
          (error) => {
            this.authService.clearAuthInfo();
            this.router.navigate(['/login']);

            return requestObserver.next(false);
          });
      });
    }

    return of(this.checkRole(acceptedRoles));
  }

  checkRole(acceptedRoles: string[]): boolean {
    const role = localStorage.getItem(LocalStorageKey.role);

    if (!role || !acceptedRoles.includes(role)) {
      this.router.navigate(['/dashboard']);

      return false;
    }

    return true;
  }
}
