/**
 * Preloader
 *
 * @param {Subscriber | Boolean} loader
 * A subscriber on which to bind the loader. Any time a new Subscriber is added the preloader will
 * keep track of the notification (error, complete) that an observable send and control the loading state.
 * Or pass a boolean on which to bind the loader to changed the visibility.
 * @param {Boolean} isFullScreen set to "true" if the preloader should fullscreen overlay the page.
 *
 * @example
 * <pre>
 *  // Example
 *  <app-preloader [loader]="(Subscriber<any>)">the content</app-preloader>
 *  <app-preloader [loader]="!(Observable[boolean] | async)">
 *  <app-preloader [loader]="!(Observable[object] | async)?.length">
 *  <app-preloader [loader]="!(Observable[string] | async)?.length">
 *  <app-preloader [loader]="(Boolean)">
 *
 *  // Use this example when you have at least one more preloader component inside your fullscreen preloader component.
 *  // You need to add this data attribute only on the fullscreen preloader component.
 *  <app-preloader [loader]="(Subscriber<any>)" [isFullScreen]="true">the content</app-preloader>
 * </pre>
 */
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscriber } from 'rxjs';
import { isFunction } from 'util';
import { EventManagerService } from '../../services/event-manager.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent implements OnChanges, OnDestroy {

  @Input() loader: Subscriber<any> | Boolean;
  @Input() isFullScreen?: Boolean;

  private loadingStackCount = 0;
  private hidePreloaderClass: string = 'preload-hide';
  private hideSpinnerClass: string = 'spinner-hide';

  constructor(private elRef: ElementRef,
              private eventManager: EventManagerService,
  ) {
    this.elRef.nativeElement.classList.add(this.hidePreloaderClass);
  }

  public setIsLoading = (isLoading) => {
    this.loadingStackCount += isLoading ? 1 : -1;

    if (this.loadingStackCount > 0) {
      this.elRef.nativeElement.classList.remove(this.hidePreloaderClass);
    } else {
      this.elRef.nativeElement.classList.add(this.hidePreloaderClass);
    }

    // If this loader is fullscreen and showing spinner, notify the other loader to start showing
    if (!this.getIsLoading()) {

      if (this.isFullScreen && !this.elRef.nativeElement.classList.contains(this.hideSpinnerClass)) {
        this.eventManager.broadcast('preloaderFullScreenDone');
      }

      this.elRef.nativeElement.classList.remove(this.hideSpinnerClass);
    }
  }

  public getIsLoading = () => this.loadingStackCount > 0;

  private showSpinner = () => {
    this.elRef.nativeElement.classList.remove(this.hideSpinnerClass);
  }

  private hideSpinnerIfFullScreenPresent = () => {
    const fullscreenPreloaderEl: any = document.querySelectorAll('app-preloader.full-screen');

    for (const preloaderEl of fullscreenPreloaderEl) {
      // If a fullscreen preloader is still shown, hide the spinner of this element
      if (!this.isFullScreen && !preloaderEl.classList.contains(this.hidePreloaderClass)) {
        this.elRef.nativeElement.classList.add(this.hideSpinnerClass);

        this.eventManager.on('preloaderFullScreenDone', this.showSpinner);

        break;
      }
    }
  }

  private hideSpinner = () => {
    this.setIsLoading(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loader &&
      ((changes.loader.currentValue && changes.loader.currentValue instanceof Subscriber) ||
        typeof changes.loader.currentValue === 'boolean')) {

      if (changes.loader.currentValue instanceof Subscriber) {
        this.setIsLoading(true);

        changes.loader.currentValue.complete = () => {
          const completeParentCallback = changes.loader.currentValue.destination._complete;

          this.hideSpinner();

          if (completeParentCallback && isFunction(completeParentCallback)) {
            completeParentCallback();
          }
        };

        changes.loader.currentValue.error = (err) => {
          const errorParentCallback = changes.loader.currentValue.destination._error;

          this.hideSpinner();

          if (errorParentCallback && isFunction(errorParentCallback)) {
            errorParentCallback(err);
          }
        };
      }

      if (typeof changes.loader.currentValue === 'boolean') {
        if (changes.loader.currentValue) {
          this.setIsLoading(true);
        } else {
          this.hideSpinner();
        }
      }
    }

    this.hideSpinnerIfFullScreenPresent();

    if (this.isFullScreen) {
      this.elRef.nativeElement.classList.add('full-screen');
    } else {
      this.elRef.nativeElement.classList.remove('full-screen');
    }
  }

  ngOnDestroy() {
    this.eventManager.off('preloaderFullScreenDone', this.showSpinner);
  }
}
