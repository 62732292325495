import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EDITION_MODE } from 'src/app/model/project/project';
import { ProjectService } from '../services/project.service';

@Injectable({ providedIn: 'root' })
export class CreateProjectResolver implements Resolve<Observable<any>> {

  constructor(private projectService: ProjectService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.projectService.setIsNewProject(route.data.mode === EDITION_MODE.NEW);
    this.projectService.setEditionMode(route.data.mode);

    return of();
  }
}
