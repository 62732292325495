import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginRequest, RefreshTokenRequest, SignUpRequest } from '../model/autentication/Requests';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GenericResponse } from '../model/Common';
import {
  ConfirmAccountResponse,
  LoginResponse,
} from '../model/autentication/Responses';
import { HttpUtils } from '../util/http-util';
import * as jwt_decode from 'jwt-decode';
import { AccountService } from './account.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { RefreshTokenResponse } from '../model/account/Responses';
import { ErrorCode, ErrorUtils } from '../util/error-util';

export const enum LocalStorageKey {
  accessToken = 'access_token', refreshToken = 'refresh_token', expire = 'auth_token_expire', role = 'auth_role',
  email = 'email', companyName = 'company_name', logoKey = 'logoKey',
}

export interface LoginData {
  loggedIn: boolean;
  error: any;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  public accessToken: string;

  constructor(private http: HttpClient, private accountService: AccountService) {
  }

  public signUp(companyName: string, email: string, password: string, receiveMarketingEmail: boolean, lang: string) {
    const request: SignUpRequest = {
      lang,
      user: {
        companyName,
        email,
        password,
        receiveMarketingEmail,
      },
    };

    return this.http.post<GenericResponse>(
      `${environment.api_url}/v1/web/user/sign_up`, request,
      HttpUtils.getJsonContentTypeHeader());
  }

  public confirmAccount(email: string, token: string) {
    const request = { email, token };

    return this.http.post<ConfirmAccountResponse>(
        `${environment.api_url}/v1/web/user/confirm_account`,
        request, HttpUtils.getJsonContentTypeHeader());
  }

  public login(email: string, password: string, rememberMe: boolean) {
    const request: LoginRequest = {
      user: {
        email,
        password,
        rememberMe,
      },
    };

    return new Observable<LoginData>((loginRequestObserver:any) => {
      this.http.post<LoginResponse>(`${environment.api_url}/v1/web/user/sign_in`, request,
                                    HttpUtils.getJsonContentTypeHeader()).subscribe(
       (result) => {
         this.storeAuthInfo(result.payload.accessToken, result.payload.refreshToken);
         loginRequestObserver.next({ loggedIn: true });
       },
       (errorResponse) => {
         this.clearAuthInfo();
         loginRequestObserver.next({ error: errorResponse.error, loggedIn: false });
       },
       () => {
         loginRequestObserver.complete();
       });
    });
  }

  refreshToken() {
    const refreshToken = localStorage.getItem(LocalStorageKey.refreshToken);
    const request: RefreshTokenRequest = {
      refreshToken,
      accessToken: localStorage.getItem(LocalStorageKey.accessToken),
    };

    return this.http.post<RefreshTokenResponse>(`${environment.api_url}/v1/web/user/refresh`, request, HttpUtils.getJsonContentTypeHeader())
      .pipe(tap((response) => {
        this.storeAuthInfo(response.payload.accessToken, refreshToken);
      }));
  }

  private storeAuthInfo(accessToken: string, refreshToken: string): void {
    const tokenPayload = jwt_decode(accessToken);
    localStorage.setItem(LocalStorageKey.accessToken, accessToken);
    localStorage.setItem(LocalStorageKey.refreshToken, refreshToken);
    localStorage.setItem(LocalStorageKey.expire, tokenPayload.exp);
    localStorage.setItem(LocalStorageKey.role, tokenPayload.role);

    this.accountService.getAccount().subscribe(
      (userResponse) => {
        localStorage.setItem(LocalStorageKey.email, userResponse.payload.email);
        localStorage.setItem(LocalStorageKey.companyName, userResponse.payload.companyName);
        localStorage.setItem(LocalStorageKey.logoKey, userResponse.payload.logoKey);
      });
  }

  clearAuthInfo(): void {
    localStorage.clear();
  }

  isSessionExpired(): boolean {
    try {
      const jwtExp = localStorage.getItem(LocalStorageKey.expire);
      if (jwtExp == null) return true;
      return (Number(jwtExp) * 1000) < new Date().getTime();
    } catch (error) {
      return true;
    }
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(LocalStorageKey.accessToken) && !this.isSessionExpired();
  }
}

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService) { }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(LocalStorageKey.accessToken);
    if (accessToken != null) {
      // tslint:disable-next-line:no-parameter-reassignment
      request = this.addToken(request, accessToken);
    }
    return next.handle(request).pipe(catchError((errorResponse, caught) => {
      if (errorResponse instanceof HttpErrorResponse &&
        ErrorUtils.containsErrorCode(errorResponse.error, ErrorCode.ACCESS_TOKEN_EXPIRED)) {
        return this.handle401Error(request, next);
      }
      return throwError(errorResponse);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { 'x-auth-token': token } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((refreshTokenResponse) => {
          this.isRefreshing = false;
          const accessToken = refreshTokenResponse.payload.accessToken;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(request, accessToken));
        }));
    } // else
    return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addToken(request, accessToken));
        }));
  }
}
