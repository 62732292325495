import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService,
              private snackBarService: SnackBarService) {
  }

  ngOnInit() {
    this.authService.clearAuthInfo();
    this.snackBarService.successWithClose('NAVIGATION.MESSAGES.LOGOUT_SUCCESSFUL');
    this.router.navigate(['login']);
  }

}
