import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { ErrorCode, ErrorUtils } from '../../util/error-util';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-confirm-account',
  template: ' ',
})
export class ConfirmAccountComponent implements AfterViewInit, OnDestroy {

  private email: string;
  private token: string;
  private queryParamSubscription: Subscription;

  constructor(private authService: AuthService,
              private snackBarService: SnackBarService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.queryParamSubscription = this.route.queryParamMap.subscribe((queryParamsMap) => {
      this.token = queryParamsMap.get('token');
      this.email = queryParamsMap.get('email');
      if (!this.token || !this.email) {
        this.router.navigate(['/login']);
      }
      this.authService.confirmAccount(this.email, this.token).subscribe(
        (response) => {
          this.router.navigate(response.payload.confirmed ? ['/account-confirmed'] : ['/register']);
        },
        (errorResponse) => {
          if (ErrorUtils.containsErrorCode(errorResponse.error, ErrorCode.ACCOUNT_ALREADY_CONFIRMED)) {
            this.snackBarService.errorWithClose('CONFIRM_ACCOUNT.MESSAGES.ACCOUNT_ALREADY_CONFIRMED');
            return this.router.navigate(['/login']);
          }
          this.snackBarService.errorWithClose('CONFIRM_ACCOUNT.MESSAGES.CONFIRMATION_FAIL');
          return this.router.navigate(['/register']);
        });
    });
  }

  ngOnDestroy(): void {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }
}
