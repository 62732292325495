import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var AnonymousGuardService = /** @class */ (function () {
    function AnonymousGuardService(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AnonymousGuardService.prototype.canActivate = function (route, state) {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['/dashboard']);
            return false;
        }
        return true;
    };
    AnonymousGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnonymousGuardService_Factory() { return new AnonymousGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AnonymousGuardService, providedIn: "root" });
    return AnonymousGuardService;
}());
export { AnonymousGuardService };
