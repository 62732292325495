import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import projectsReducer from './projects.reducer';
import requestStatusReducer from '../request-status/request-status.reducer';
import { RequestStatusAction } from '../../actionstypes/projects/request-status.actiontype';
import { RootProjects } from 'src/app/model/project/projects';

export const projectsRootReducer = combineReducers({
  requestStatus: requestStatusReducer(RequestStatusAction.Load, RequestStatusAction.Success, RequestStatusAction.Error),
  data: projectsReducer,
});

const getProjectsFeatureState = createFeatureSelector<RootProjects>('projects');

export const getProjects = createSelector(
  getProjectsFeatureState,
  state => state.data,
);

export const projectsAreLoaded = createSelector(
  getProjectsFeatureState,
  state => state.requestStatus.isLoaded,
);

export const projectsAreInError = createSelector(
  getProjectsFeatureState,
  state => state.requestStatus.isError,
);
