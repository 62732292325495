import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ErrorCode, ErrorUtils } from '../../util/error-util';
import { SnackBarService } from '../../services/snack-bar.service';
import { ConfirmDialogComponent } from '../../widgets/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../../services/account.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public showCaptcha: boolean;
  private captchaResponse: string;
  public recaptchaSettings = { siteKey: '6LfV3rcUAAAAAEOX7g-UF_yK0GmU13kSVBj1Lu7N' };

  @ViewChild('captchaElem', { static: false })
  captchaElem?: ReCaptcha2Component;

  constructor(private authService: AuthService,
              private accountService: AccountService,
              private router: Router,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private snackBarService: SnackBarService,
              private dialog: MatDialog) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      remember: new FormControl(false),
      recaptcha: new FormControl('')});

    this.showCaptcha = false;
  }

  ngOnInit() {
  }

  public onLogin() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.get('email').value,
                             this.loginForm.get('password').value,
                             this.loginForm.get('remember').value).subscribe(
        (loginData) => {
          if (loginData.loggedIn) {
            this.router.navigate(['/dashboard']);
          } else {
            this.handleError(loginData.error);
          }
        });
    }
  }

  getCurrentLang(): string {
    return this.translate.currentLang;
  }

  handleCaptchaReset() {
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleCaptchaExpire() {
    this.cdr.detectChanges();
  }

  handleCaptchaSuccess(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleCaptchaLoad(): void {
    this.cdr.detectChanges();
  }

  private showCaptchaComponent(show: boolean) {
    this.showCaptcha = show;
    if (show) {
      if (this.captchaElem) {
        this.captchaElem.resetCaptcha();
      }
      this.loginForm.controls['recaptcha'].setValidators(Validators.required);
    } else {
      this.loginForm.controls['recaptcha'].clearValidators();
    }
    this.loginForm.controls['recaptcha'].updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private handleError(error: any) {
    if (ErrorUtils.containsErrorCode(error, ErrorCode.ACCOUNT_NOT_CONFIRMED)) {
      return this.snackBarService.errorWithClose('LOGIN.MESSAGES.ACCOUNT_NOT_CONFIRMED');
    }
    if (ErrorUtils.containsErrorCode(error, ErrorCode.ACCOUNT_MARKED_FOR_DELETION)) {
      this.translate.get('LOGIN.ACTIVATE_ACCOUNT_DIALOG').subscribe((translations) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '500px',
          data: {
            title: translations['TITLE'],
            description: translations['DESCRIPTION'],
            confirm_message: translations['CONFIRM_MESSAGE'],
            cancel_button: translations['CANCEL_BUTTON'],
            confirm_button: translations['CONFIRM_BUTTON'],
          },
        });
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.accountService.reactivateAccount(this.loginForm.get('email').value, this.loginForm.get('password').value).subscribe(
              (response) => {
                this.onLogin();
              },
              (error) => {
                this.snackBarService.errorWithClose('LOGIN.MESSAGES.REACTIVATE_FAILED');
              });
          }
        });
      });
    } else {
      this.showCaptchaComponent(true);
      this.snackBarService.errorWithClose('LOGIN.MESSAGES.INCORRECT_CREDENTIALS');
    }
  }
}
