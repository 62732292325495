import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SecurityOptionsService } from 'src/app/services/security-options.service';
import { SecurityOption } from 'src/app/model/project/options';
import { TranslateService } from '@ngx-translate/core';
import { BillingService } from 'src/app/services/billing.service';
import { GroupedProjectBillingInfo } from 'src/app/model/billing/billing';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  public securityOptionsDetails: SecurityOption[];

  public isBillingLoading: boolean;
  public isBillingInError: boolean;
  public billingInfo: GroupedProjectBillingInfo[];
  private billingServiceSubscription: Subscription;

  constructor(private securityOptionsService: SecurityOptionsService,
              private translate: TranslateService,
              private billingService: BillingService) {

    this.securityOptionsDetails = this.securityOptionsService.securityOptions;
    this.isBillingLoading = true;
    this.isBillingInError = false;
  }

  ngOnInit() {
    this.securityOptionsService.getAll().subscribe((options) => {
      this.securityOptionsDetails = options;
    });

    this.billingServiceSubscription = this.billingService
      .getBillingInfo()
      .subscribe(
        (billingInfo) => {
          this.billingInfo = billingInfo;
          this.isBillingLoading = false;
        },
        () => {
          this.isBillingLoading = false;
          this.isBillingInError = true;
        });
  }

  ngOnDestroy() {
    this.billingServiceSubscription.unsubscribe();
  }

  formatDate(date: string) {
    const year = new Intl.DateTimeFormat(this.translate.currentLang, { year: 'numeric' }).format(new Date(date));
    const month = new Intl.DateTimeFormat(this.translate.currentLang, { month: 'long' }).format(new Date(date));
    return `${month} ${year}`;
  }

  formatCurrencyValue(value: number) {
    return {
      value: value / 100,
    };
  }
}
