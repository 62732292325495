import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './app.material.module';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectRoutingModule } from './project-routing.module';

// Services
import { AuthenticationHttpInterceptor, AuthService } from './services/auth.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { AccountService } from './services/account.service';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { AuthGuardService } from './services/auth-guard.service';
import { FileUploadService } from './services/file-upload.service';
import { NavigationService } from './services/navigation.service';
import { AnonymousGuardService } from './services/anonymous-guard.service';
import { ProjectService } from './services/project.service';
import { ProjectGuardService } from './services/project-guard.service';
import { SurveyStatusService } from './services/survey-status.service';
import { CsvService } from './services/csv.service';
import { EventManagerService } from './services/event-manager.service';

// Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

// Components
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PasswordConfirmComponent } from './widgets/password-confirm/password-confirm.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EmailSentComponent } from './components/email-sent/email-sent.component';
import { GenericDialogPageComponent } from './widgets/generic-dialog-page/generic-dialog-page.component';
import { ConfirmDialogComponent } from './widgets/confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { BillingComponent } from './components/billing/billing.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CreateProjectComponent } from './components/project/create-project/create-project.component';
import { ProjectSidebarComponent } from './components/project/project-sidebar/project-sidebar.component';
import { ProjectProfileComponent } from './components/project/project-profile/project-profile.component';
import { ImageUploaderComponent } from './widgets/image-uploader/image-uploader.component';
import { LanguagesDialogComponent } from './components/project/languages-dialog/languages-dialog.component';
import { LanguagesWarningDialogComponent } from './components/project/languages-warning-dialog/languages-warning-dialog.component';
import { StatusWarningDialogComponent } from './components/project/status-warning-dialog/status-warning-dialog.component';
import { StatusNotifyDialogComponent } from './components/project/status-notify-dialog/status-notify-dialog.component';
import { SelectInputComponent } from './widgets/select-input/select-input.component';
import { InputDialogComponent } from './widgets/input-dialog/input-dialog.component';
import { SuppliersComponent } from './components/project/suppliers/suppliers.component';
import { SecurityComponent } from './components/project/security/security.component';
import { QualityComponent } from './components/project/quality/quality.component';
import { QuestionComponent } from './components/project/quality/question/question.component';
import { QuestionAnswersComponent } from './components/project/quality/question-answers/question-answers.component';
import { AnswerComponent } from './components/project/quality/question-answers/answer/answer.component';
import { AskDialogComponent } from './widgets/ask-dialog/ask-dialog.component';
import { GenerateComponent } from './components/project/generate/generate.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { ProjectDataService } from './services/project-data.service';
import { AddSupplierDialogComponent } from './components/project/suppliers/add-supplier-dialog/add-supplier-dialog.component';
import { ViewReportComponent } from './components/project/view-report/view-report.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { ProjectSummaryBoxComponent } from './components/project/project-summary-box/project-summary-box.component';
import { DefaultBoxComponent } from './components/project/view-report/default-box/default-box.component';
import { ProjectPricingDropdownComponent } from './components/project-pricing-dropdown/project-pricing-dropdown.component';

// Directives
import { CopyClipboardDirective } from './directives/copy-clipboard/copy-clipboard.directive';
import { ExportCsvDirective } from './directives/export-csv/export-csv.directive';

// Store
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, REDUCER_TOKEN } from './ngrx/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Effects
import { EffectsModule } from '@ngrx/effects';
import { ProjectsEffect } from './ngrx/effects/projects.effect';
import { ClientsEffect } from './ngrx/effects/clients.effect';

import { environment } from '../environments/environment';

// Resolver
import { ProjectResolver } from './resolvers/project.resolver';
import { CreateProjectResolver } from './resolvers/create-project.resolver';

export function createHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    PasswordConfirmComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    GenericDialogPageComponent,
    EmailSentComponent,
    ProfileComponent,
    DashboardComponent,
    ConfirmDialogComponent,
    ConfirmAccountComponent,
    CreateProjectComponent,
    ProjectSidebarComponent,
    NavigationBarComponent,
    ProjectsComponent,
    BillingComponent,
    NotificationsComponent,
    ProjectProfileComponent,
    ImageUploaderComponent,
    LanguagesDialogComponent,
    LanguagesWarningDialogComponent,
    StatusWarningDialogComponent,
    StatusNotifyDialogComponent,
    SelectInputComponent,
    InputDialogComponent,
    SuppliersComponent,
    SecurityComponent,
    FilterPipe,
    TruncatePipe,
    QualityComponent,
    QuestionComponent,
    QuestionAnswersComponent,
    AnswerComponent,
    AskDialogComponent,
    GenerateComponent,
    PageNotFoundComponent,
    PreloaderComponent,
    CopyClipboardDirective,
    ExportCsvDirective,
    AddSupplierDialogComponent,
    ViewReportComponent,
    BreadcrumbComponent,
    ProjectsListComponent,
    ProjectSummaryBoxComponent,
    DefaultBoxComponent,
    ProjectPricingDropdownComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ProjectRoutingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatPasswordStrengthModule.forRoot(),
    NgSelectModule,
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      ProjectsEffect,
      ClientsEffect,
    ]),
    StoreDevtoolsModule.instrument({ name: 'Quest Detect Angular', maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationHttpInterceptor,
      multi: true,
    },
    {
      provide: REDUCER_TOKEN,
      useValue: reducers,
    },
    AuthService,
    AuthGuardService,
    ProjectGuardService,
    AnonymousGuardService,
    ForgotPasswordService,
    AccountService,
    FileUploadService,
    NavigationService,
    ProjectService,
    ProjectDataService,
    SurveyStatusService,
    CsvService,
    EventManagerService,
    ProjectResolver,
    CreateProjectResolver,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MatIcon,
    ConfirmDialogComponent,
    LanguagesDialogComponent,
    LanguagesWarningDialogComponent,
    StatusWarningDialogComponent,
    StatusNotifyDialogComponent,
    InputDialogComponent,
    AskDialogComponent,
    AddSupplierDialogComponent,
  ],
})
export class AppModule { }
