<div class="dialog-content-wrapper">
  <h2 mat-dialog-title>{{ 'LANGUAGES_DIALOG.HEADING' | translate }}</h2>
  <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
  <mat-dialog-content class="mat-typography">
      <p> {{ 'LANGUAGES_DIALOG.DESCRIPTION' | translate }} </p>
      <div class="languages-list">
        <ul>
          <li *ngFor="let language of languages">
            <mat-checkbox [checked]='language.selected'
                          (change)="language.selected = $event.checked"
                          color="primary"
            >
              {{ language.label}}
            </mat-checkbox>
          </li>
        </ul>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button class="button dark-blue mt-10 width-150" mat-button [mat-dialog-close]="true" (click)="updateFormControls()" cdkFocusInitial> {{ 'LANGUAGES_DIALOG.UPDATE_BUTTON' | translate }} </button>
  </mat-dialog-actions>
</div>
