export const DEFAULT_LANG_CODE = 'en_CA';

export const ALL_LANGUAGES = [
  { langCode: 'en_CA' },
  { langCode: 'fr_FR' },
  { langCode: 'zh_CN' },
  { langCode: 'du_BE' },
  { langCode: 'ar_AE' },
  { langCode: 'hi_IN' },
  { langCode: 'fs_PK' },
];
