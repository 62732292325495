import { Component, OnInit, OnDestroy } from '@angular/core';
import { EDITION_MODE, Project } from 'src/app/model/project/project';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { DirtyFormService } from 'src/app/services/dirty-form.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  public project: Project;
  public isOpenedSidebar: boolean = false;
  private isDirty: boolean;

  constructor(private projectDataService: ProjectDataService,
              private projectService: ProjectService,
              private dirtyFormService: DirtyFormService,
              private route: ActivatedRoute) {

    this.project = this.projectDataService.projectData;

    // Listen the project data to set the sidebar title.
    this.projectDataService.receivedProject.subscribe((project: Project) => {
      this.project = project;
    });

    // Push the mode edition value in service
    this.route.data.subscribe((data) => {
      this.projectService.setIsNewProject(data.mode === EDITION_MODE.NEW);

      this.isOpenedSidebar = [EDITION_MODE].some((editionMode) => {
        return data.mode === editionMode.NEW || data.mode === editionMode.EDIT;
      });
    });
  }

  ngOnInit() {
    this.unloadPage = this.unloadPage.bind(this);

    this.dirtyFormService.receivedFilter.subscribe((isDirty: boolean) => {
      this.isDirty = isDirty;
    });

    window.onbeforeunload = this.unloadPage;
  }

  ngOnDestroy() {
    // Clear and reset the project data
    this.projectDataService.resetProjectData();

    // Clear project logo url
    this.projectService.deleteCurrentProjectLogoUrl();
  }

  /*
  The custom message should not be shown in modern browser, as the browser have block the edition of the prompt message
  to prevent any scam.
   */
  private unloadPage(event) {
    if (!this.isDirty) {
      return;
    }

    const confirmationMessage = 'Changes you made may not be saved.';
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }

}
