import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isUndefined } from 'util';
import { IBreadCrumb } from 'src/app/model/breadcrumb/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  @Input() lastRoutePartLabel: string;

  public breadcrumbs: IBreadCrumb[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    const breadcrumbLabelKey = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumbLabelKey : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let labelKey = '';

    if (breadcrumbLabelKey !== '' && !isUndefined(breadcrumbLabelKey)) {
      labelKey = breadcrumbLabelKey;
    }

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];

      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
    }

    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadCrumb = {
      labelKey,
      url: nextUrl,
    };

    const newBreadcrumbs = breadcrumb.labelKey ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    if (!route.firstChild && this.lastRoutePartLabel) {
      newBreadcrumbs.push({ label: `${this.lastRoutePartLabel}` });
    }

    return newBreadcrumbs;
  }
}
