import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, } from '@angular/core';
var QuestionComponent = /** @class */ (function () {
    function QuestionComponent() {
        this.projectQuestionSelect = new EventEmitter();
    }
    Object.defineProperty(QuestionComponent.prototype, "text", {
        get: function () { return this.formGroup.get('text'); },
        enumerable: true,
        configurable: true
    });
    QuestionComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.valueChangesSubscription = this.formGroup.controls['text'].valueChanges.subscribe(function (value) {
            _this.formGroup.controls['text'].setValue(value, { emitEvent: false });
            // TODO it seems to be a bug in angular (related to https://github.com/angular/angular/issues/13792)
            // The displayed input value is not refreshed even though the formControl holds the correct updated value
            // Using ChangeDetectorRef or ngZone to let the DOM know that it needs to refresh its view have failed as
            // well as moving this method somewhere else.
            if (value !== _this.textInputRef.nativeElement.value) {
                _this.textInputRef.nativeElement.value = value[0].text;
            }
        });
    };
    QuestionComponent.prototype.ngOnDestroy = function () {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    };
    QuestionComponent.prototype.questionSelected = function () {
        this.projectQuestionSelect.emit(this.index);
    };
    return QuestionComponent;
}());
export { QuestionComponent };
