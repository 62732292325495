import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormGroupDirective } from '@angular/forms';
import { ForgotPasswordService } from '../../services/forgot-password.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

  public forgotPasswordForm: FormGroup;

  constructor(private forgotPasswordService: ForgotPasswordService,
              private router: Router,
              private translate: TranslateService) {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  public onSubmit() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.get('email').value;
      this.forgotPasswordService.passwordResetEmail(email, this.translate.currentLang).subscribe(
        (result) => {
          this.router.navigate(['/email-sent', email]);
        },
        (error) => {
          // Don't display error if email doesn't exist
          this.router.navigate(['/email-sent', email]);
        });
    }
  }
}
