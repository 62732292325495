import { Component, OnDestroy, OnInit } from '@angular/core';
import { SecurityOptionsService } from '../../../services/security-options.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStepComponent } from '../project-step-component';
import { ProjectService } from '../../../services/project.service';
import { Project, ProjectUpdate } from '../../../model/project/project';
import { SecurityOption } from '../../../model/project/options';
import { SnackBarService } from '../../../services/snack-bar.service';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { ProjectDataService } from 'src/app/services/project-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyFormService } from 'src/app/services/dirty-form.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent extends ProjectStepComponent implements OnInit, OnDestroy {

  protected project: Project;
  protected securityOptions: SecurityOption[];
  public updateProjectHttpSubscriber: Subscription;
  public securityFormChangeSubscriber: Subscription;
  public freePriceLabel; string;
  public securityForm: FormGroup;

  constructor(private snackBarService: SnackBarService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private projectDataService: ProjectDataService,
              private router: Router,
              private securityOptionsService: SecurityOptionsService,
              private translate: TranslateService,
              private dirtyFormService: DirtyFormService) {
    super();

    if (this.route.snapshot.data.project instanceof Observable) {
      this.route.snapshot.data.project.subscribe((project) => {
        this.project = project;
      });
    }

    this.translate.get('SECURITY.LABELS.FREE_PRICE').subscribe((translation) => {
      this.freePriceLabel = translation;
    });
  }

  saveSecurityOptions() {

    if (!this.project._id) return;

    this.dirtyFormService.isValid();

    const projectData: ProjectUpdate = {
      securityOptions: this.securityForm.get('securityOptions').value,
      _id: this.project._id,
    };

    this.updateProjectHttpSubscriber = this.projectService.updateProject(projectData).subscribe(
      (projectUpdated) => {
        this.projectDataService.projectData = projectUpdated.payload.project;
        this.snackBarService.successWithClose('SECURITY.MESSAGES.SECURITY_OPTIONS_SAVED');
        this.currentStepForm.submitted = true;
      },
      (error) => {
        this.snackBarService.errorWithClose('SECURITY.MESSAGES.SAVE_FAILED');
      });
    return this.updateProjectHttpSubscriber;
  }

  completeStep() {
    this.router.navigate([`/projects/${this.projectService.editionMode}/quality`], { queryParamsHandling: 'preserve' });
  }

  ngOnInit() {
    /**
     * If the `id` of the project is available, that's means that the project is
     * created and accessible in the application by navigating between the different pages.
     * Conditions/validations are done in the ProjectResolver.
     */
    if (this.project._id) {
      this.setSecurityFormData();
    }
  }

  ngOnDestroy(): void {
    if (this.updateProjectHttpSubscriber) {
      this.updateProjectHttpSubscriber.unsubscribe();
    }

    if (this.securityFormChangeSubscriber) {
      this.securityFormChangeSubscriber.unsubscribe();
    }
  }

  getSecurityOptionsText(code, suffix) {
    const option = this.securityOptions.filter((option) => {
      return option.code === code ? option.name : '';
    });

    if (option.length) {
      return this.translate.instant(`SECURITY_OPTIONS.${option[0].name}.${suffix}`);
    }

    return '';
  }

  setSecurityFormData() {
    this.securityOptionsService.getAll().subscribe((options) => {
      const availableOptions = this.project.securityOptions.length > 0 ? this.project.securityOptions : options;

      this.securityOptions = options;

      this.securityForm = new FormGroup({
        securityOptions: new FormArray(availableOptions.map<FormGroup>((option) => {
          return new FormGroup({
            code: new FormControl(option.code, Validators.required),
            enabled: new FormControl(option.enabled, Validators.required),
            togglable: new FormControl(option.togglable, Validators.required),
          });
        })),
      });

      this.currentStepForm.form = this.securityForm;

      this.dirtyFormService.reset();
      this.securityFormChangeSubscriber = this.securityForm.valueChanges.subscribe(() => {
        this.dirtyFormService.isDirty();
        this.saveSecurityOptions();
      });
    });
  }

  get securityOptionsArray() {
    return this.securityForm.get('securityOptions') as FormArray;
  }

  getSecurityOptionsByName(code, codeSearch) {
    return this.securityOptions.some((option) => {
      return option.code === code && option.code === codeSearch;
    });
  }

  getFormattedSecurityOptions(securityOptions): any {
    const options = {};

    securityOptions.forEach((ts) => {
      options[ts.name] = ts.code;
    });

    return options;
  }

  getCostEstimate(securityOptionRate: number): number {
    return this.project.numberOfPanelistsRequired * securityOptionRate / 100;
  }

  getFormattedPrice(securityOptionCode: string) {
    const securityOptions = this.getFormattedSecurityOptions(this.securityOptions);
    const isFreeSecurityOption = this.getSecurityOptionsByName(securityOptionCode, securityOptions.FREE_SECURITY);

    if (isFreeSecurityOption) return this.freePriceLabel;

    const { rate } = this.securityOptions.find(({ code }) => code === Number(securityOptionCode));
    const cost = this.getCostEstimate(rate);
    return this.translate.instant('CURRENCY', { value: cost });
  }
}
