import { Component, OnDestroy, OnInit } from '@angular/core';
import { Projects } from 'src/app/model/project/projects';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../ngrx/reducers';
import * as ProjectsActions from '../../ngrx/actionstypes/projects/projects.actiontype';
import { Observable } from 'rxjs';
import { getProjects, projectsAreInError, projectsAreLoaded } from 'src/app/ngrx/reducers/projects/projects.root.reducer';
import { RequestStatus } from '../../ngrx/actionstypes/request-status/request-status.actiontype';
import { RequestStatusAction } from 'src/app/ngrx/actionstypes/projects/request-status.actiontype';
import { ProjectService } from '../../services/project.service';
import { Clients } from 'src/app/model/client/client';
import { getClients } from 'src/app/ngrx/reducers/clients/clients.root.reducer';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit, OnDestroy {

  public projectsAreLoaded$: Observable<boolean>;
  public projectsAreInError$: Observable<boolean>;
  public projects$: Observable<Projects>;

  public client: string;
  public status: string;
  public text: string = '';

  public clientsArray$: Observable<Clients>;
  public statusArray: string[];

  constructor(private store: Store<AppState>,
              private projectService: ProjectService) {

    // Show error if user come from project child pages with data/state or url error.
    if (this.projectService.isErrorProjectState) {
      this.projectService.resetErrorProjectState();
      this.projectService.handleProjectError();
    }
  }

  ngOnInit() {
    this.store.dispatch(new ProjectsActions.Load({}));

    this.projectsAreLoaded$ = this.store.pipe(select(projectsAreLoaded));
    this.projectsAreInError$ = this.store.pipe(select(projectsAreInError));
    this.projects$ = this.store.pipe(select(getProjects));
    this.clientsArray$ = this.store.pipe(select(getClients));

    this.statusArray = ['Complete', 'Draft', 'In Field'];
  }

  ngOnDestroy() {
    this.store.dispatch(new ProjectsActions.Reset());
    this.projectService.hideProjectError();
  }

  filterSearchKeysValues() {
    return {
      client: this.client ? this.client : '',
      status: this.status ? this.status : '',
      text: this.text ? this.text : '',
    };
  }

  filterSearchQuery(querykeysValues) {

    this.store.dispatch(new RequestStatus(RequestStatusAction.Load));

    this.store.dispatch(new ProjectsActions.Load(querykeysValues));
  }

  filterSearch() {
    this.filterSearchQuery(this.filterSearchKeysValues());
  }
}
