import { OnDestroy } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { RouteId } from '../model/Common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var NavigationService = /** @class */ (function () {
    function NavigationService(activeRoute, router) {
        this.activeRoute = activeRoute;
        this.router = router;
        this.routeIdSubject = new BehaviorSubject(RouteId.NO_ID);
        this.subscribeToRouteChange();
    }
    NavigationService.prototype.ngOnDestroy = function () {
        if (this.routeIdSubscription) {
            this.routeIdSubscription.unsubscribe();
        }
    };
    NavigationService.prototype.getRouteIdSubscription = function () {
        return this.routeIdSubject;
    };
    NavigationService.prototype.getLatestChild = function (route) {
        var latestRoute = route;
        while (latestRoute.firstChild) {
            latestRoute = latestRoute.firstChild;
        }
        return latestRoute;
    };
    NavigationService.prototype.subscribeToRouteChange = function () {
        var _this = this;
        // Initial navigation state
        var latestRoute = this.getLatestChild(this.activeRoute);
        if (latestRoute) {
            latestRoute.data.pipe(take(1)).subscribe(function (data) {
                _this.routeIdSubject.next(data.routeId || RouteId.NO_ID);
            });
        }
        this.routeIdSubscription = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activeRoute; }), map(function (route) { return _this.getLatestChild(route); }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; })).subscribe(function (data) {
            _this.routeIdSubject.next(data.routeId || RouteId.NO_ID);
        });
    };
    NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
