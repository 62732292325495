import { of } from 'rxjs';
import { EDITION_MODE } from 'src/app/model/project/project';
import * as i0 from "@angular/core";
import * as i1 from "../services/project.service";
var CreateProjectResolver = /** @class */ (function () {
    function CreateProjectResolver(projectService) {
        this.projectService = projectService;
    }
    CreateProjectResolver.prototype.resolve = function (route) {
        this.projectService.setIsNewProject(route.data.mode === EDITION_MODE.NEW);
        this.projectService.setEditionMode(route.data.mode);
        return of();
    };
    CreateProjectResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateProjectResolver_Factory() { return new CreateProjectResolver(i0.ɵɵinject(i1.ProjectService)); }, token: CreateProjectResolver, providedIn: "root" });
    return CreateProjectResolver;
}());
export { CreateProjectResolver };
