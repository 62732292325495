/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preloader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preloader.component";
import * as i3 from "../../services/event-manager.service";
var styles_PreloaderComponent = [i0.styles];
var RenderType_PreloaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreloaderComponent, data: {} });
export { RenderType_PreloaderComponent as RenderType_PreloaderComponent };
export function View_PreloaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "preloaded-content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
export function View_PreloaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preloader", [], null, null, null, View_PreloaderComponent_0, RenderType_PreloaderComponent)), i1.ɵdid(1, 704512, null, 0, i2.PreloaderComponent, [i1.ElementRef, i3.EventManagerService], null, null)], null, null); }
var PreloaderComponentNgFactory = i1.ɵccf("app-preloader", i2.PreloaderComponent, View_PreloaderComponent_Host_0, { loader: "loader", isFullScreen: "isFullScreen" }, {}, ["*"]);
export { PreloaderComponentNgFactory as PreloaderComponentNgFactory };
