import { Injectable } from '@angular/core';
import { ProjectStatsResponse, ProjectResponse } from '../model/project/Responses';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../util/http-util';
import { ProjectRequest } from '../model/project/Requests';
import { Project, ProjectUpdate, Question, StoreLogoData } from 'src/app/model/project/project';
import { SnackBarService } from './snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {

  public editionMode: string;
  public isNewModeProject: boolean;
  public isErrorProjectState: boolean;
  private currentProjectLogoUrl: StoreLogoData;

  constructor(private http: HttpClient,
              private snackBarService: SnackBarService,
              private translate: TranslateService) { }

  public setEditionMode(mode: string): void {
    this.editionMode = mode;
  }

  public setIsNewProject(isNew: boolean): void {
    this.isNewModeProject = isNew;
  }

  public storeCurrentProjectLogoUrl(logoKey: string, logoUrl: string) {
    this.currentProjectLogoUrl = { key: logoKey, url: logoUrl };
  }

  public deleteCurrentProjectLogoUrl(): void {
    this.currentProjectLogoUrl = null;
  }

  public sortLangCodesByLabel(langCodes): string[] {
    const languagesLabel: string[] = [];

    for (const langCode of [...langCodes]) {
      const formattedLanguage = langCode.replace(/[-_][a-z]+$/g, s => s.toUpperCase());

      languagesLabel.push(this.translate.instant(`LANGUAGES.${formattedLanguage}`));
    }

    languagesLabel.sort((a, b) => a > b ? 1 : -1);

    return languagesLabel;
  }

  public deleteQuestions(projectQuestions: Question[], langCodes: string[]): Question[] {
    const questions = [...projectQuestions];

    for (const langCode of langCodes) {
      for (const question of questions) {
        question.text = question.text.filter(text => text.langCode !== langCode);

        for (const index in question.answers) {
          question.answers[index].text = question.answers[index].text.filter(text => text.langCode !== langCode);
        }
      }
    }

    return !questions[0].text.length ? [] : questions;
  }

  public getCurrentProjectLogoUrl(logoKey: string): string {
    let logoUrl = '';

    if (this.currentProjectLogoUrl &&
      this.currentProjectLogoUrl.key === logoKey) {
      logoUrl = this.currentProjectLogoUrl.url;
    }

    return logoUrl;
  }

  public setErrorProjectState(hasError: boolean): void {
    this.isErrorProjectState = hasError;
  }

  public resetErrorProjectState(): void {
    this.setErrorProjectState(false);
  }

  public handleProjectError(): void {
    const callback = () => {
      this.resetErrorProjectState();
    };

    this.snackBarService.errorWithClose('PROJECT.MESSAGES.PROJECT_REQUEST_FAILED', null, callback);
  }

  public hideProjectError(): void {
    this.snackBarService.hide();
  }

  public getProjectStatsByProjectId(projectId: String) {
    return this.http.get<ProjectStatsResponse>(`${environment.api_url}/v1/web/project/${projectId}/statistic`,
                                               HttpUtils.getJsonContentTypeHeader());
  }

  public getProject(projectId: String) {
    return this.http.get<ProjectResponse>(`${environment.api_url}/v1/web/project/${projectId}`, HttpUtils.getJsonContentTypeHeader());
  }

  public createProject(project: Project) {
    const request: ProjectRequest = this.getProjectRequest(project);

    return this.http.post<ProjectResponse>(`${environment.api_url}/v1/web/project`, request, HttpUtils.getJsonContentTypeHeader());
  }

  public updateProject(projectUpdate: ProjectUpdate) {
    // The `id` is to retrieve the current project because the backend is expecting to receive an 'id'.
    const request = { project: Object.assign({ id: projectUpdate._id }, { ...projectUpdate }) };

    return this.http.patch<ProjectResponse>(`${environment.api_url}/v1/web/project`, request, HttpUtils.getJsonContentTypeHeader());
  }

  getProjectRequest(project: Project) {
    const request: ProjectRequest = {
      project: {
        title: project.title,
        client: project.client,
        status: project.status,
        projectManager: project.projectManager,
        endDate: project.endDate,
        duration: project.duration,
        maxBudget: project.maxBudget,
        setRoom: project.setRoom,
        type: project.type,
        surveyDuration: project.surveyDuration,
        bidTarget: project.bidTarget,
        piiCompliance: project.piiCompliance,
        languages: project.languages,
        logoKey: project.logoKey,
        expectedIR: project.expectedIR,
        expectedDR: project.expectedDR,
        expectedOQ: project.expectedOQ,
        audience: project.audience,
      },
    };
    return request;
  }
}
