<div class="billing-container">
  <app-preloader [loader]="isBillingLoading">
    <div class="results" *ngIf="isBillingInError; else projects;">
      <span class="title">{{ 'BILLING.ERROR_TITLE' | translate }}</span>
      <p class="text">{{ 'BILLING.ERROR_TEXT' | translate }}</p>
    </div>
    <ng-template #projects>
      <div class="results" *ngIf="!billingInfo.length">
        <p class="text">
          {{ 'BILLING.NO_PROJECTS_TEXT' | translate }}
        </p>
      </div>
      <mat-accordion *ngFor="let group of billingInfo">
        <div class="project-group">
          <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="'120px'" [expandedHeight]="'120px'">
              <div class="project-group__header">
                <mat-panel-title class="project-group__header__status">
                  {{ 'BILLING.DUE' | translate }}
                </mat-panel-title>
                <mat-panel-title>
                  <b>
                    {{ formatDate(group.groupName) }}
                  </b>
                </mat-panel-title>
                <mat-panel-title class="project-group__header__amount">
                  <b>
                    {{ 'BILLING.AMOUNT_DUE' | translate }}: {{ 'CURRENCY' | translate:formatCurrencyValue(group.amountDue) }}
                  </b>
                </mat-panel-title>
              </div>
            </mat-expansion-panel-header>
            <div *ngFor="let project of group.projects" class="projectContainer">
              <mat-divider></mat-divider>
              <div class="projectInfo">
                <div class="projectItem">
                  <span class="field-description">
                    {{ 'BILLING.PROJECT' | translate }}
                  </span>
                  <span class="title">
                    <b>
                      {{ project.title }}
                    </b>
                  </span>
                </div>
                <div class="projectItem">
                  <span class="field-description">
                    {{ 'BILLING.CLIENT' | translate }}
                  </span>
                  <span>
                    <b>
                      {{ project.client }}
                    </b>
                  </span>
                </div>
                <div class="projectItem">
                  <span class="field-description">
                    {{ 'BILLING.NUMBER_OF_PANELISTS' | translate }}
                  </span>
                  <span>
                    <b>
                      {{ project.panelists }}
                    </b>
                  </span>
                </div>
                <div class="projectItem">
                  <span class="field-description">
                    {{ 'BILLING.COST_PER_PANELIST' | translate }}
                  </span>
                  <span>
                    <b>
                      {{ 'CURRENCY' | translate:formatCurrencyValue(project.cost) }}
                    </b>
                  </span>
                </div>
                <div class="projectItem">
                  <span class="field-description">
                    {{ 'BILLING.PROJECT_COST' | translate }}
                  </span>
                  <span>
                    <b>
                      {{ 'CURRENCY' | translate:formatCurrencyValue(project.total) }}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </ng-template>
  </app-preloader>
</div>
