import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { Observable } from 'rxjs';
import { CheckResetPasswordTokenResponse } from '../model/autentication/Responses';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ForgotPasswordService = /** @class */ (function () {
    function ForgotPasswordService(http) {
        this.http = http;
    }
    ForgotPasswordService.prototype.passwordResetEmail = function (email, lang) {
        var request = { email: email, lang: lang };
        return this.http.post(environment.api_url + "/v1/web/user/send_reset_email", request, HttpUtils.getJsonContentTypeHeader());
    };
    ForgotPasswordService.prototype.updatePassword = function (password, email, resetToken) {
        var request = {
            password: password,
            email: email,
            resetToken: resetToken,
        };
        return this.http.post(environment.api_url + "/v1/web/user/reset_password", request, HttpUtils.getJsonContentTypeHeader());
    };
    ForgotPasswordService.prototype.checkPasswordResetToken = function (email, resetToken) {
        var _this = this;
        var request = { email: email, resetToken: resetToken };
        return new Observable(function (observer) {
            _this.http.post(environment.api_url + "/v1/web/user/check_reset_token", request, HttpUtils.getJsonContentTypeHeader()).subscribe(function (result) {
                observer.next(result.payload && result.payload.valid);
            }, function (error) {
                observer.next(false);
            }, function () {
                observer.complete();
            });
        });
    };
    ForgotPasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ForgotPasswordService_Factory() { return new ForgotPasswordService(i0.ɵɵinject(i1.HttpClient)); }, token: ForgotPasswordService, providedIn: "root" });
    return ForgotPasswordService;
}());
export { ForgotPasswordService };
