import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
})
export class EmailSentComponent implements OnInit {

  public description: string;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit() {
    const email = this.route.snapshot.paramMap.get('email');
    this.translate.get('EMAIL_SENT.DESCRIPTION', { email }).subscribe((translation) => {
      this.description = translation;
    });
  }

}
