import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/material/snack-bar";
var SnackBarService = /** @class */ (function () {
    function SnackBarService(translate, snackBar) {
        this.translate = translate;
        this.snackBar = snackBar;
    }
    SnackBarService.prototype.errorWithClose = function (messageKey, duration, callback) {
        if (duration === void 0) { duration = 10000; }
        if (callback === void 0) { callback = function () { }; }
        this.show(messageKey, 'GENERIC.LABELS.CLOSE', duration, null, callback);
    };
    SnackBarService.prototype.successWithClose = function (messageKey, duration, panelClass, callback) {
        if (duration === void 0) { duration = 10000; }
        if (panelClass === void 0) { panelClass = ['alert', 'success']; }
        if (callback === void 0) { callback = function () { }; }
        this.show(messageKey, 'GENERIC.LABELS.CLOSE', duration, panelClass, callback);
    };
    SnackBarService.prototype.show = function (messageKey, buttonLabelKey, duration, panelClass, callback) {
        var _this = this;
        if (duration === void 0) { duration = 10000; }
        if (callback === void 0) { callback = function () { }; }
        this.translate.get([messageKey, buttonLabelKey]).subscribe(function (translations) {
            _this.snackBarRef = _this.snackBar.open(translations[messageKey], translations[buttonLabelKey], { duration: duration, panelClass: panelClass });
            _this.snackBarRef.afterDismissed().subscribe(function () {
                callback();
            });
        });
    };
    SnackBarService.prototype.hide = function () {
        if (this.snackBarRef) {
            this.snackBarRef.dismiss();
        }
    };
    SnackBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
    return SnackBarService;
}());
export { SnackBarService };
