<app-preloader [loader]="updateProjectHttpSubscriber" [isFullScreen]="true">
<div class="security-wrapper">
  <div class="step-header">
    <li> 3 <span class='spacer'>{{ 'SIDEBAR.LABELS.SECURITY' | translate }}</span> </li>
    <app-project-pricing-dropdown></app-project-pricing-dropdown>
  </div>
  <div class="security-form">
    <div class="importance-notice">
      <p class="bold">{{ 'SECURITY.HEADINGS.HOW_SECURITY_WORK' | translate }}</p>
      <p class="italic">{{ 'SECURITY.DESCRIPTIONS.HOW_SECURITY_WORK' | translate }}</p>
    </div>
    <form class="form" *ngIf="securityForm" [formGroup]="securityForm" (ngSubmit)='completeStep()'>
      <div class="security-options">
        <mat-list formArrayName="securityOptions">
          <mat-list-item *ngFor="let securityOption of securityOptionsArray.controls; let optionIndex=index;" [formGroupName]="optionIndex">
            <div class="checkbox-container">
              <mat-checkbox formControlName="enabled" [disabled]="!securityOption.get('togglable').value" color="primary" class="c-detect f-18 f-arial bold">
                {{ getSecurityOptionsText(securityOption.get('code').value, 'name') }}
              </mat-checkbox>
            </div>
            <p class="description c-detect f-16 f-arial">{{ getSecurityOptionsText(securityOption.get('code').value, 'description') }}</p>
            <div class="pricing">
              <span class="c-detect-bg f-36 f-arial">{{ getFormattedPrice(securityOption.get('code').value) }}</span>
              <p class="c-detect f-12 f-arial light-font">{{ 'PRICING_TYPES.ESTIMATED_ON_BID_TARGET' | translate }}</p>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <br/>
      <button mat-raised-button class="width-200 light-blue mt-40" type="submit">
        {{ 'PROJECT_PROFILE.LABELS.CONTINUE_BUTTON' | translate }}
      </button>
    </form>
  </div>
</div>
</app-preloader>
