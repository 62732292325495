<div class="project-generate-wrapper">
  <div class="step-header">
    <li> 5 <span class='spacer'>{{ 'SIDEBAR.LABELS.GENERATE' | translate }}</span> </li>
    <app-project-pricing-dropdown></app-project-pricing-dropdown>
  </div>
  <div class="project-generate-form">
    <form class="form">
      <mat-card-content>
        <mat-tab-group animationDuration="0">
          <mat-tab label="{{ 'GENERATE.HEADINGS.ENTRANCE_LINKS' | translate }}">
            <p class="description">{{ 'GENERATE.DESCRIPTIONS.ENTRANCE_LINKS' | translate }}</p>
            <mat-form-field class="small-field">
              <mat-label>{{'SUPPLIERS.LABELS.LIST' | translate}}</mat-label>
              <mat-select
                *ngIf="suppliersList.length > 1"
                [(value)]="selectedSupplier"
                (selectionChange)="updateEntranceLinks()"
              >
                <mat-option
                  *ngFor="let supplier of suppliersList"
                  [value]="supplier"
                >
                  {{supplier.name}}
                </mat-option>
              </mat-select>
              <input matInput
                     *ngIf="suppliersList.length === 1"
                     value="{{ suppliersList[0].name }}"
                     readonly
              >
            </mat-form-field>
            <div class="link-wrapper"
                 *ngFor="let entranceLink of entranceLinksList;"
            >
              <mat-form-field class="large-field" [ngClass]="{'warning-msg': !entranceLink.active}">
                <mat-label>{{ entranceLink.label }}</mat-label>
                <input *ngIf="entranceLink.active"
                       matInput
                       value="{{entranceLink.url}}"
                       readonly
                       appCopyClipboard
                >
                <textarea *ngIf="!entranceLink.active"
                       matInput
                       [mat-autosize]="'true'"
                       value="{{ 'GENERATE.MESSAGES.GET_QUESTIONS_FAILED' | translate }}"
                       readonly
                ></textarea>
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'GENERATE.HEADINGS.COMPLETE_TERMINATE_LINKS' | translate }}">
            <p class="description">{{ 'GENERATE.DESCRIPTIONS.COMPLETE_TERMINATE_LINKS' | translate }}</p>
            <div class="link-wrapper"
                 *ngFor="let redirectLink of redirectLinksList;"
            >
              <mat-form-field class="large-field">
                <mat-label>{{ 'GENERATE.REDIRECT_LINKS.'+ redirectLink.status | translate }}</mat-label>
                <input matInput
                       value="{{redirectLink.url}}"
                       placeholder="{{ 'GENERATE.REDIRECT_LINKS.'+ redirectLink.status | translate }}"
                       readonly
                       appCopyClipboard
                >
              </mat-form-field>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </form>
  </div>
</div>

