import { of } from 'rxjs';
import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import { pluck, map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientsService = /** @class */ (function () {
    function ClientsService(http) {
        this.http = http;
    }
    ClientsService.prototype.getClients = function () {
        return this.http.get(environment.api_url + "/v1/web/user/clients", HttpUtils.getJsonContentTypeHeader()).pipe(pluck('payload', 'clients'), map(function (clients) { return clients.map(function (client) { return client.fullName; }); }), catchError(function () { return of([]); }));
    };
    ClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientsService_Factory() { return new ClientsService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientsService, providedIn: "root" });
    return ClientsService;
}());
export { ClientsService };
