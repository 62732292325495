<div class="main-container">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex class="f-18 c-detect-bg f-arial bold pl-20">{{ index + 1 | number: '2.0-0' }}</span>
    <div class="trash-container"
         fxLayoutAlign="center center"
         *ngIf="!isEditModeDisabled"
    >
      <button mat-button type="button" class="height-100" (click)="removeQuestion()">
        <span class="trash-icon font-awesome f-18 c-detect-bg"></span>
      </button>
    </div>
  </div>
  <div class="inputs-container" [formGroup]="formGroup">
    <div fxLayoutAlign="center start" class="question-container">
      <div class="width-100 p-20" formArrayName="text">
        <div *ngFor="let txt of text.controls; index as i"
             [formGroupName]="i"
             [hidden]="txt.value.langCode !== currentLangCode"
        >
          <mat-form-field class="width-100">
            <mat-label>{{ 'QUESTION.LABELS.QUESTION' | translate }}</mat-label>
              <input #textInput
                     matInput
                     required
                     class="question-input f-arial"
                     formControlName="text"
                     autocomplete="question-text"
                     placeholder="{{ 'QUESTION.LABELS.PLACE_HOLDER' | translate }}"
              >
            <mat-error *ngIf="formGroup.get('text').errors?.required">
              {{ 'QUESTION.MESSAGES.TEXT_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('text').errors?.maxlength">
              {{ 'QUESTION.MESSAGES.TEXT_MAX_LENGTH' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="answers-container" fxLayoutAlign="center center" fxLayout="column">
      <div *ngIf="!isAnswerFormValid()" class="answer-errors">
        <p class="f-arial bold" *ngIf="projectLanguages.length <= 1">{{ 'QUESTION.MESSAGES.ANSWERS_ERROR_LANGUAGE' | translate }}</p>
        <p class="f-arial bold" *ngIf="projectLanguages.length > 1">{{ 'QUESTION.MESSAGES.ANSWERS_ERROR_LANGUAGES' | translate }}</p>
      </div>
      <app-answer *ngFor="let answer of answersFormArray.controls; let answerIndex=index;"
                  [formGroup]="getAnswerForm(answerIndex)" [index]="answerIndex"
                  (answerRemove)="onAnswerRemove($event)"
                  [currentLangCode]="currentLangCode"
                  [isEditModeDisabled]="isEditModeDisabled"
      >
      </app-answer>
    </div>
  </div>

  <div class="button-container"
       *ngIf="!isEditModeDisabled"
  >
    <button mat-raised-button type="button" (click)="addAnswer()" class="f-arial bold inverse add-button">
      {{ 'QUESTION.LABELS.ADD_ANSWER' | translate }}
    </button>
  </div>
</div>

