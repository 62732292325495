import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ForgotPasswordService } from '../../services/forgot-password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCode, ErrorUtils } from '../../util/error-util';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements AfterViewInit, OnDestroy {

  private email: string;
  private token: string;
  public resetPasswordForm: FormGroup;
  private queryParamSubscription: Subscription;

  constructor(private forgotPasswordService: ForgotPasswordService,
              private snackBarService: SnackBarService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService) {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  ngAfterViewInit(): void {
    this.queryParamSubscription = this.route.queryParamMap.subscribe((queryParamsMap) => {
      this.token = queryParamsMap.get('token');
      this.email = queryParamsMap.get('email');
      if (!this.token || !this.email) {
        this.router.navigate(['/login']);
      }
      this.forgotPasswordService.checkPasswordResetToken(this.email, this.token).subscribe(
        (isValid) => {
          if (!isValid) {
            this.snackBarService.errorWithClose('RESET_PASSWORD.MESSAGES.RESET_PASSWORD_TOKEN_NOT_VALID');
            return this.router.navigate(['/forgot-password']);
          }
        });

    });
  }

  ngOnDestroy(): void {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  public onSubmit() {
    if (this.resetPasswordForm.valid) {
      this.forgotPasswordService.updatePassword(this.resetPasswordForm.get('password').value, this.email, this.token).subscribe(
        (result) => {
          this.router.navigate(['/password-updated']);
        },
        (errorResponse) => {
          if (errorResponse) {
            if (ErrorUtils.containsErrorCode(errorResponse.error, ErrorCode.NO_ASK_RESET_PASSWORD)) {
              this.snackBarService.errorWithClose('RESET_PASSWORD.MESSAGES.NO_RESET_PASSWORD_TOKEN');
              return this.router.navigate(['/login']);
            }
            if (ErrorUtils.containsErrorCode(errorResponse.error, ErrorCode.RESET_PASSWORD_TOKEN_EXPIRED)) {
              this.snackBarService.errorWithClose('RESET_PASSWORD.MESSAGES.RESET_PASSWORD_TOKEN_EXPIRED');
              return this.router.navigate(['/forgot-password']);
            }
          }
          this.snackBarService.errorWithClose('GENERIC.MESSAGES.ERROR');
        });
    }
  }
}
